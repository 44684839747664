import React, { FC, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { EditOutlined, LoadingOutlined } from '@ant-design/icons'
import { Avatar, Dropdown, SecondaryLink, Spin, UploadFile } from '~/core-components'
import { ImageAuth, UploadPhotoModal } from '~/components'
import { dispatch } from '~/stores/store'
import { getBaseUrl } from '~/utils'
import { CompanyState } from '../../../types'
import { deleteCompanyLogo, uploadCompanyLogo } from '../../../actions'
import './CompanyLogo.less'

interface CompanyLogoProps {
  company?: CompanyState
}

interface CompanyParams {
  id: string
}

const baseUrl = getBaseUrl('/filestore')

export const CompanyLogo: FC<CompanyLogoProps> = ({ company }) => {
  const { id } = useParams<CompanyParams>()
  const [uploading, setUploading] = useState(false)
  const [uploadModal, setUploadModal] = useState<boolean>(false)

  const handleShowUploadModal = useCallback(() => {
    setUploadModal(true)
  }, [])

  const handleCloseUploadModal = useCallback(() => {
    setUploadModal(false)
  }, [])

  const handleRemovePhoto = useCallback(async () => {
    try {
      setUploading(true)
      if (id) {
        await dispatch(deleteCompanyLogo(id))
      }
    } finally {
      setUploading(false)
    }
  }, [id])

  const handleUploadChange = useCallback(
    async (file?: UploadFile) => {
      try {
        setUploading(true)
        if (file) {
          await dispatch(uploadCompanyLogo(id, file))
        }
      } finally {
        setUploading(false)
        setUploadModal(false)
      }
    },
    [id]
  )

  return (
    <div className="company-logo">
      {!!company?.logoId ? (
        <Avatar
          size={128}
          shape="square"
          src={
            <ImageAuth src={`${baseUrl}/publicfile/${company.logoId}/thumbnailphoto/128`} useNative preview={false} />
          }
        />
      ) : (
        <Avatar size={128} shape="square" />
      )}
      <div className="company-logo__pencil-icon">
        <Dropdown
          menu={{
            items: [
              { key: 'change', label: 'Change logo', onClick: handleShowUploadModal },
              { key: 'remove', label: 'Remove', onClick: handleRemovePhoto, disabled: !company?.logoId }
            ]
          }}
        >
          {uploading ? (
            <Spin size="small" indicator={<LoadingOutlined spin />} />
          ) : (
            <SecondaryLink>
              <EditOutlined />
            </SecondaryLink>
          )}
        </Dropdown>
      </div>
      {company && (
        <UploadPhotoModal
          title="Change logo"
          src={company.logoId ? `${baseUrl}/file/${company.logoId}` : undefined}
          shape="square"
          showRatio
          open={uploadModal}
          uploading={uploading}
          onSave={handleUploadChange}
          onClose={handleCloseUploadModal}
        />
      )}
    </div>
  )
}

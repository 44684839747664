import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'
import { Button, Card, ColumnsType, Form, SecondaryText, Space, Table, Tooltip } from '~/core-components'
import { Person, InfoTooltip, SearchInput, Row, Col } from '~/components'
import { useSysOptions } from '~/features/employee'
import { Screen, ViewCriteriaSimple, ViewCriteria, updateViewCriteria, useFirstView } from '~/features/selection'
import { usePermissionGate } from '~/features/iam/hooks'
import { EMP_ROUTES } from '~/routes/routes'
import { LveProrationType, LveSysLeaveTypeType, Permission, SsRole } from '~/constants'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import {
  showError,
  getFileTimestamp,
  formatDateRange,
  formatNumberUnit,
  UnitType,
  downloadWithDom,
  formatDate,
  getBaseUrl
} from '~/utils'
import { ROLeaveEntitlementRowState } from '~/features/my/types'
import { LeaveTypeName, useLeaveTypesDict, useSysLeaveTypes } from '~/features/leave'
import { selectROLeaveEntitlementsView } from '~/features/my/selectors'
import { fetchROLeaveEntitlementView } from '~/features/my/actions'
import { refetchROLeaveEntitlementsView } from '~/features/my/reducers'
import { apiGetROLeaveEntitlementExcel } from '~/features/my/api/roleave-entitlement.api'
import { ROLeavePeriodYears } from './components/ROLeavePeriodYears'
import { ROLeEarnedInfo } from './components/ROLeEarnedInfo'
import './ROLeaveEntitlement.less'

interface ROLeaveEntitlementProps {
  role: SsRole
  isActive: boolean
}

const nameColWidth = 250
const defaultColWidth = 100
const paginationStyle: CSSProperties = { marginRight: 20 }

const SCREEN_CODE: Screen = 'ro_leave_entitlement'
const PAGE_SIZE_OPTIONS = ['20', '50', '100']

const baseUrl = getBaseUrl('/filestore')

export const ROLeaveEntitlement: FC<ROLeaveEntitlementProps> = ({ role, isActive }) => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [search, setSearch] = useState<string>('')
  const [periodYear, setPeriodYear] = useState<number>()

  const [leaveTypes] = useLeaveTypesDict()
  const [sysLeaveTypes] = useSysLeaveTypes()
  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const dataLoading = useSelector((state: StoreState) => state.my.roLeaveEntitlementsViewLoading)
  const data = useSelector(selectROLeaveEntitlementsView)(viewId)
  const isMountedRef = useIsMountedRef()
  const [downloading, setDownloading] = useState(false)
  const [leaveUnits] = useSysOptions('lve_entitlement_unit')
  const canViewEmployee = usePermissionGate(Permission.employee)
  const refetch = useSelector((state: StoreState) => state.my.roLeaveEntitlementsViewRefetch)

  useEffect(() => {
    if (viewId && isActive) {
      dispatch(
        fetchROLeaveEntitlementView(
          viewId,
          role,
          periodYear,
          { offset: pageSize * (page - 1), limit: pageSize },
          search
        )
      )
    }
  }, [viewId, role, periodYear, page, pageSize, search, refetch, isActive])

  const isGrantType = useCallback(
    (leaveTypeId: string) => {
      return sysLeaveTypes[leaveTypes[leaveTypeId]?.sysLeaveTypeCode || '']?.type === LveSysLeaveTypeType.grant
    },
    [sysLeaveTypes, leaveTypes]
  )

  const tableWidth = nameColWidth + 8 * defaultColWidth

  let columns: ColumnsType<ROLeaveEntitlementRowState> = [
    {
      title: 'Name',
      key: 'employeeId',
      dataIndex: 'employeeId',
      fixed: 'left',
      className: 'first-col',
      width: nameColWidth,
      onCell: (record, index) => {
        const prevId = data.data[(index as number) - 1]?.employeeId
        if (prevId === record.employeeId) {
          return { rowSpan: 0 }
        } else {
          const nextId = data.data[(index as number) + 1]?.employeeId
          if (nextId === record.employeeId) {
            return { rowSpan: record.rowSpan }
          }
        }
        return {}
      },
      render: (_, record) => (
        <Person
          name={record.employeeName}
          description={record.description}
          photo={record.photoId && `${baseUrl}/file/${record.photoId}/thumbnailphoto/36`}
          path={canViewEmployee ? EMP_ROUTES.employee.replace(':id', record.employeeId) : undefined}
          size={36}
        />
      )
    },
    {
      title: 'Leave type',
      key: 'leaveTypeId',
      dataIndex: 'leaveTypeId',
      width: 230,
      render: (value: string, record) => (
        <LeaveTypeName id={value} description={formatDateRange(record.periodStartDate, record.periodEndDate)}>
          {!record.isActivePeriod && (
            <Tooltip title="Past period">
              <i className="fal fa-clock-rotate-left lt-past" />
            </Tooltip>
          )}
        </LeaveTypeName>
      )
    },
    {
      title: 'Carry forward',
      key: 'cf',
      dataIndex: 'cf',
      align: 'right',
      width: defaultColWidth,
      render: (value: number, record) => {
        const hasExpiry = !!record.cfExpiryDate
        let hasExpired = false
        if (hasExpiry) {
          hasExpired = moment(record.cfExpiryDate).isBefore(moment())
        }

        return (
          <span>
            {value}
            {hasExpiry && !hasExpired && (
              <InfoTooltip
                className="cell-icon"
                title={`${
                  record.cf !== record.cfExpiry
                    ? `${formatNumberUnit(record.cfExpiry, record.unit as UnitType)} expire`
                    : 'Expire'
                } on ${moment(record.cfExpiryDate).format('DD MMM YYYY')}`}
              />
            )}
          </span>
        )
      }
    },
    {
      title: 'Earned',
      key: 'earned',
      dataIndex: 'earned',
      align: 'right',
      width: defaultColWidth,
      render: (value: number, record) => {
        let tooltipMessages: string[] = []

        const isGrantLeave = isGrantType(record.leaveTypeId)
        const showFYE = !isGrantLeave && record.fullEntitlement !== value && !!record.fullEntitlement
        const isProrated = leaveTypes[record.leaveTypeId]?.prorationType !== LveProrationType.none
        if (showFYE) {
          tooltipMessages.push(
            `Full year entitlement: ${formatNumberUnit(record.fullEntitlement, record.entitlementUnit as UnitType)}`
          )
        }
        if (record.earnedAdj !== 0 && !!record.earnedAdj) {
          tooltipMessages.push(`Adjustment: ${formatNumberUnit(record.earnedAdj, record.unit as UnitType)}`)
        }

        return (
          <span>
            {value}
            {tooltipMessages.length > 0 && (
              <ROLeEarnedInfo
                tooltip={tooltipMessages.join(' ')}
                periodCode={isProrated && showFYE ? record.periodCode : undefined}
                employeeId={isProrated && showFYE ? record.employeeId : undefined}
                leaveTypeId={isProrated && showFYE ? record.leaveTypeId : undefined}
              />
            )}
            {isGrantLeave && (
              <InfoTooltip
                icon="fa-calendar"
                className="cell-icon"
                title={
                  record.periodStartDate === record.periodEndDate
                    ? `Valid on ${formatDate(record.periodStartDate)}`
                    : `Valid from ${formatDateRange(record.periodStartDate, record.periodEndDate)}`
                }
              />
            )}
          </span>
        )
      }
    },
    {
      title: 'Taken',
      key: 'taken',
      dataIndex: 'taken',
      align: 'right',
      width: defaultColWidth,
      render: (value: number, record) => {
        const totalTaken = record.cfTaken + record.taken + record.takenAdj

        let tooltipText: { label: string; value: string }[] = []
        if (!!record.planned) {
          tooltipText.push({
            label: 'Used:',
            value: formatNumberUnit(totalTaken - record.planned, record.unit as UnitType)
          })
          tooltipText.push({ label: 'Planned:', value: formatNumberUnit(record.planned, record.unit as UnitType) })
        }

        return (
          <span>
            {totalTaken}
            {tooltipText.length > 0 && (
              <InfoTooltip
                className="cell-icon"
                title={
                  <>
                    {tooltipText.map(t => (
                      <Row>
                        <Col flex="1" className="mr8">
                          {t.label}
                        </Col>
                        <Col>{t.value}</Col>
                      </Row>
                    ))}
                  </>
                }
              />
            )}
          </span>
        )
      }
    },
    {
      title: 'Pending',
      key: 'pending',
      dataIndex: 'pending',
      align: 'right',
      width: defaultColWidth,
      render: (value: number, record) => record.pending
    },
    {
      title: 'Expired',
      key: 'cfForfeited',
      dataIndex: 'cfForfeited',
      align: 'right',
      width: defaultColWidth,
      render: (value: number) => value
    },
    {
      title: 'Balance',
      key: 'balance',
      dataIndex: 'balance',
      align: 'right',
      width: defaultColWidth,
      render: (value: number, record) => {
        let toolTipMessages: string[] = []

        if (!!record.poolTaken) {
          toolTipMessages.push(
            `${record.poolTaken} ${leaveUnits[record.unit]?.value.toLowerCase()} taken from other leave type(s)`
          )
        }
        if (record.encashed > 0) {
          toolTipMessages.push(
            `Encashed (upon resignation) ${record.encashed} ${leaveUnits[record.unit]?.value.toLowerCase()}`
          )
        }
        if (record.encashedEnd > 0) {
          toolTipMessages.push(
            `Encashed (period end) ${record.encashedEnd} ${leaveUnits[record.unit]?.value.toLowerCase()}`
          )
        }

        return (
          <span>
            {value}
            {toolTipMessages.length > 0 && <InfoTooltip className="cell-icon" title={toolTipMessages.join('. ')} />}
          </span>
        )
      }
    },
    {
      title: (
        <>
          Planned
          <SecondaryText block size="small">
            future period
          </SecondaryText>
        </>
      ),
      key: 'futurePlanned',
      dataIndex: 'futurePlanned',
      align: 'right',
      width: defaultColWidth
    },
    {
      title: 'Unit',
      key: 'unit',
      dataIndex: 'unit',
      width: defaultColWidth,
      render: (value: string) => leaveUnits[value]?.value?.toLowerCase() || ''
    }
  ]

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        setPage(1)
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchROLeaveEntitlementsView())
      }
    },
    [viewId]
  )

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const handleSearch = useCallback((value: string) => {
    setPage(1)
    setSearch(value)
  }, [])

  const handlePeriodChange = useCallback(periodYear => {
    setPage(1)
    setPeriodYear(periodYear)
  }, [])

  const handleDownloadClick = useCallback(async () => {
    if (viewId && periodYear) {
      try {
        setDownloading(true)
        const { status, result, errors, message, errorData } = await apiGetROLeaveEntitlementExcel(
          viewId,
          role,
          periodYear,
          {
            search
          }
        )

        if (status) {
          const fileName = `leave_entitlement_${getFileTimestamp()}.xlsx`
          downloadWithDom(result, fileName)
        } else {
          console.error('Error while downloading', errors)
          showError(message, errorData)
        }
      } finally {
        if (isMountedRef.current) setDownloading(false)
      }
    }
  }, [viewId, role, periodYear, search, isMountedRef])

  return (
    <div className="roleave-entitlement">
      <div className="roleave-entitlement__body">
        <div className="roleave-entitlement__action-bar">
          <Space>
            <Form.Item label="">
              <ROLeavePeriodYears onChange={handlePeriodChange} />
            </Form.Item>
            <Form.Item label="">
              <SearchInput onSearch={handleSearch} />
            </Form.Item>
          </Space>
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
          <Space align="start">
            <Tooltip title="Download excel" placement="topRight">
              <Button
                icon={<i className="fal fa-arrow-down-to-bracket" />}
                onClick={handleDownloadClick}
                loading={downloading}
              />
            </Tooltip>
          </Space>
        </div>
        <Card fitParent table>
          <Table
            rowKey="id"
            dataSource={data?.data}
            columns={columns}
            fitParent
            loading={dataLoading || viewLoading}
            scroll={{ x: tableWidth, y: 1000 }}
            pagination={{
              total: data?.count,
              current: page,
              pageSize,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showSizeChanger: true,
              onChange: handlePaginationChange,
              style: paginationStyle
            }}
          />
        </Card>
      </div>
    </div>
  )
}

import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { selectEmCertRequests } from '../selectors'
import { fetchEmCertRequests } from '../actions'
import { EmCertRequestState } from '../types'

export const useEmCertRequests = (employeeId: string, inView: boolean): [EmCertRequestState[], boolean] => {
  const data = useSelector(selectEmCertRequests)(employeeId)
  const loading = useSelector((state: StoreState) => state.employee.emCertRequestsLoading[employeeId])

  useEffect(() => {
    if (inView && employeeId) dispatch(fetchEmCertRequests(employeeId))
  }, [employeeId, inView])

  return [data, loading]
}

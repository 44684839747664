import React, { FC, useEffect, useRef, useState, useCallback } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { UploadFileSelectorAuth } from '~/components'
import { Avatar, Button, Form, Input, Space, Spin, UploadFile } from '~/core-components'
import { Slider } from 'antd'
import { FileImageOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import { Errors } from '~/types/store'
import './PhotoEditor.less'

export interface PhotoEditorProps {
  src?: string
  shape?: 'circle' | 'square'
  showRatio?: boolean
  defaultPhoto: any
  loading?: boolean
  errors?: Errors
  onChange: (editorRef: any, photo?: UploadFile, viewPhoto?: any, width?: number, height?: number) => void
}

const ACCEPT = '.jpg,.jpeg,.png,.bmp,.gif'
const SIZE = 250

export const PhotoEditor: FC<PhotoEditorProps> = ({
  shape = 'circle',
  showRatio = false,
  defaultPhoto,
  loading = false,
  errors,
  onChange
}: PhotoEditorProps) => {
  const [photo, setPhoto] = useState<UploadFile>()
  const [viewPhoto, setViewPhoto] = useState<any>()
  const [ratio, setRatio] = useState([1, 1])
  const [scaleValue, setScaleValue] = useState<number>(1)
  const editorRef = useRef<any>(null)

  const min = 0.1
  const max = 3
  const mid = parseFloat(((max - min) / 2).toFixed(5))
  const preColorCls = scaleValue >= mid ? '' : 'icon-wrapper-active'
  const nextColorCls = scaleValue >= mid ? 'icon-wrapper-active' : ''

  useEffect(() => {
    if (defaultPhoto) setViewPhoto(defaultPhoto)
    else setViewPhoto('')

    setScaleValue(1)
  }, [defaultPhoto])

  const handleFileChange = useCallback(
    (files?: UploadFile[]) => {
      if (files && files.length > 0) {
        setPhoto(files[0])
        setViewPhoto(files[0].url)
        setScaleValue(1)
        typeof onChange === 'function' && onChange(editorRef, files[0], files[0].url, SIZE * ratio[0], SIZE * ratio[1])
      }
    },
    [editorRef, onChange, ratio]
  )

  const handleScaleChange = useCallback(
    (value: number) => {
      setScaleValue(value)
      typeof onChange === 'function' && onChange(editorRef, photo, viewPhoto, SIZE * ratio[0], SIZE * ratio[1])
    },
    [editorRef, photo, viewPhoto, onChange, ratio]
  )

  const handleChange = useCallback(() => {
    typeof onChange === 'function' && onChange(editorRef, photo, viewPhoto, SIZE * ratio[0], SIZE * ratio[1])
  }, [editorRef, photo, viewPhoto, onChange, ratio])

  return (
    <div className="photo-editor">
      {viewPhoto ? (
        <AvatarEditor
          image={viewPhoto}
          width={SIZE * ratio[0]}
          height={SIZE * ratio[1]}
          border={50}
          color={[0, 0, 0, 0.5]}
          scale={scaleValue}
          rotate={0}
          ref={editorRef}
          onImageChange={handleChange}
        />
      ) : loading ? (
        <Spin size="small" indicator={<LoadingOutlined spin />} />
      ) : (
        <Avatar size={250} shape={shape} icon={<i className="fal fa-user" />} />
      )}
      <Form.Item className="upload-select" validateStatus={errors?.upload ? 'error' : ''} help={errors?.upload}>
        <UploadFileSelectorAuth
          multiple={false}
          accept={ACCEPT}
          fileList={photo ? [photo] : []}
          onChange={handleFileChange}
          showUploadList={false}
        >
          <Button icon={<UploadOutlined />} loading={loading}>
            Choose file
          </Button>
        </UploadFileSelectorAuth>
      </Form.Item>
      {showRatio && (
        <Space>
          <label>Ratio</label>
          <Input.Number
            value={ratio[0]}
            onChange={width => setRatio(ratio => [width || 1, ratio[1]])}
            min={1}
            max={2}
          />
          <> x </>
          <Input.Number
            value={ratio[1]}
            onChange={height => setRatio(ratio => [ratio[0], height || 1])}
            min={1}
            max={2}
          />
        </Space>
      )}
      <div className="icon-wrapper">
        <FileImageOutlined className={preColorCls} />
        <Slider
          onChange={handleScaleChange}
          value={scaleValue}
          min={min}
          max={max}
          step={0.01}
          tooltip={{ open: false }}
        />
        <FileImageOutlined className={nextColorCls} />
      </div>
    </div>
  )
}

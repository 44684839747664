import omit from 'lodash/omit'
import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiSaveWfScenario } from '../api/workflow.api'
import { WfCategory, ISaveWfEvent } from '../types'
import { Screen, fetchViewSchema } from '~/features/selection'
import { fetchWfActions } from './fetch-wf-actions'
import { fetchWfEvents } from './fetch-wf-events'

export const saveWfScenario = (
  screenCode: Screen,
  category: WfCategory,
  workflowId: string,
  request: ISaveWfEvent
): ThunkResult<void> => {
  return async dispatch => {
    if (!workflowId) return

    const { status, result, errors, message, errorData } = await apiSaveWfScenario(
      category,
      workflowId,
      omit(request, 'viewId')
    )

    if (status) {
      const wfEventId = result?.id || request.id
      if (wfEventId) {
        dispatch(fetchWfEvents(workflowId))
        dispatch(fetchWfActions(wfEventId))
      }

      if (request.viewId) {
        dispatch(fetchViewSchema(screenCode, request.viewId))
      }

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}

import React, { CSSProperties, FC, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import confirm from 'antd/lib/modal/confirm'
import { Button, PageHeader, SecondaryLink, Space, Tab, Tabs, Tooltip } from '~/core-components'
import { DocumentTitle } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { TAX_ROUTES } from '~/routes/routes'
import { Permission, PermissionAction, YtdSubmissionStatus } from '~/constants'
import { dispatch } from '~/stores/store'
import { ActionResult, StoreState } from '~/types/store'
import { deleteYtdSubmission } from '../../actions'
import { refetchYtdSubmissionViews, setYtdSubmissionExpanded } from '../../reducers'
import { useYtdSubmission } from '../../hooks'
import { selectHasIrasError } from '../../selectors'
import { Ir8aRecords } from './Ir8aRecords/Ir8aRecords'
// import { Ir8aWarnings } from './Ir8aRecords/Ir8aWarnings'
import { Ir8sRecords } from './Ir8sRecords/Ir8sRecords'
import { A8aRecords } from './A8aRecords/A8aRecords'
import { A8bRecords } from './A8bRecords/A8bRecords'
import { YtdSummary } from './YtdSummary/YtdSummary'
import { YtdTags } from './YtdTags'
import { IrasErrors } from '../Iras/IrasErrors'
import './YtdSubmission.less'

interface YtdSubmissionProps {}

const routes = [
  {
    path: TAX_ROUTES.tab.replace(':tab?', 'submission'),
    breadcrumbName: 'Overview'
  },
  {
    path: '',
    breadcrumbName: 'IRAS submission'
  }
]

export const YtdSubmission: FC<YtdSubmissionProps> = () => {
  const match = useRouteMatch<{ id: string; tab: string }>()
  const id = match.params.id
  const [ytdSubmission] = useYtdSubmission(id, 'always')
  const title = ytdSubmission
    ? `IRAS ${ytdSubmission?.ytdYear} - ${ytdSubmission?.companyName} (${ytdSubmission?.employerTaxNo})`
    : 'IRAS'

  const history = useHistory()
  const expanded = useSelector((state: StoreState) => state.tax.ytdSubmissionExpanded)
  const canDelete = usePermissionGate(Permission.ytd, PermissionAction.Delete)
  const hasErrors = useSelector(selectHasIrasError)(id)

  const handleTabChange = useCallback(
    (activeKey: string) => {
      history.push(`${TAX_ROUTES.submission.replace(':id', id).replace(':tab?', activeKey)}`)
    },
    [id, history]
  )

  const handleDeleteYtdSubmission = useCallback(() => {
    if (!id) return

    confirm({
      title: 'Delete',
      content: (
        <>
          <div>Do you want to delete this submission?</div>
          <br />
          <div>This will also delete (if any) the following:</div>
          <div>&#9;- IR8A</div>
          <div>&#9;- IR8S</div>
          <div>&#9;- Appendix 8A</div>
          <div>&#9;- Appendix 8B</div>
        </>
      ),
      onOk: async () => {
        const result: ActionResult | undefined = await dispatch(deleteYtdSubmission(id))

        if (!result?.errors) {
          dispatch(refetchYtdSubmissionViews())
          history.push(TAX_ROUTES.tab.replace(':tab?', 'submission'))
        }
      },
      width: 550,
      okText: 'Delete',
      okType: 'danger'
    })
  }, [id, history])

  const tabItems = useMemo(() => {
    const result: Tab[] = [
      { key: 'ir8a', label: 'IR8A', children: <Ir8aRecords ytdSubmission={ytdSubmission} /> },
      { key: 'ir8s', label: 'IR8S', children: <Ir8sRecords ytdSubmission={ytdSubmission} /> },
      { key: 'a8a', label: 'Appendix 8A', children: <A8aRecords ytdSubmission={ytdSubmission} /> },
      { key: 'a8b', label: 'Appendix 8B', children: <A8bRecords ytdSubmission={ytdSubmission} /> },
      { key: 'submission', label: 'Submission', children: <YtdSummary ytdSubmission={ytdSubmission} /> }
    ]

    if (ytdSubmission?.status === YtdSubmissionStatus.draft && hasErrors) {
      result.push({
        key: 'error',
        label: (
          <Space className="ant-tabs-tab--failed">
            <i className="fal fa-triangle-exclamation" />
            Warning
          </Space>
        ),
        children: <IrasErrors ytdSubmissionId={ytdSubmission.id} />
      })
    }
    return result
  }, [ytdSubmission, hasErrors])

  return (
    <div className="ytd-submission">
      <DocumentTitle title={title} />
      {!expanded ? (
        <div className="ytd-submission__header">
          <PageHeader
            title={
              <Space align="center">
                <span>{title}</span>
                <YtdTags ytdSubmission={ytdSubmission} />
              </Space>
            }
            breadcrumb={{ routes }}
          />
        </div>
      ) : (
        <div className="ytd-submission__header ytd-submission__header--collapsed">{title}</div>
      )}
      <div className="ytd-submission__body">
        <Tabs
          defaultActiveKey={match.params.tab || 'ir8a'}
          onChange={handleTabChange}
          tabBarExtraContent={{
            left: <YtdSubmissionExpandButton isExpand={expanded} />,
            right: (
              <Space>
                {ytdSubmission?.status === YtdSubmissionStatus.draft && canDelete && (
                  <Button size="small" onClick={handleDeleteYtdSubmission}>
                    Delete submission
                  </Button>
                )}
              </Space>
            )
          }}
          items={tabItems}
        />
      </div>
    </div>
  )
}

const expandButtonStyle: CSSProperties = { marginRight: 16 }

const YtdSubmissionExpandButton: FC<{ isExpand: boolean }> = ({ isExpand }) => {
  const handleExpand = useCallback(() => {
    dispatch(setYtdSubmissionExpanded(!isExpand))
  }, [isExpand])

  return (
    <Tooltip title={isExpand ? 'Normal view' : 'Maximise view'}>
      <SecondaryLink style={expandButtonStyle} onClick={handleExpand}>
        {isExpand ? (
          <i className="fal fa-arrow-down-left-and-arrow-up-right-to-center" />
        ) : (
          <i className="fal fa-arrow-up-right-and-arrow-down-left-from-center" />
        )}
      </SecondaryLink>
    </Tooltip>
  )
}

import { request, getBaseUrl } from '~/utils/request'
import { EncashImportState, EncashImportValidateState, EncashImportExecuteState } from '../types'

const baseUrl = getBaseUrl('/payroll-sg/encashimport')

export const apiPrepareEncashImport = (payRunId: string, encashType: string, upToDate: string) => {
  return request<EncashImportState>('post', `${baseUrl}/prepare`, { payRunId, encashType, upToDate })
}

export const apiValidateEncashImport = (payRunId: string, encashType: string, leaveEntitlementIds: string[]) =>
  request<EncashImportValidateState>('post', `${baseUrl}/validate`, { payRunId, encashType, leaveEntitlementIds })

export const apiExecuteEncashImport = (payRunId: string, encashType: string) =>
  request<EncashImportExecuteState>('post', `${baseUrl}/execute`, { payRunId, encashType })

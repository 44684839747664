import moment from 'moment-timezone'
import { removeDailyRecord, setDailyRecords, setDailyRecordsLoading } from '../reducers'
import { apiGetDailyRecordByEmployeeDate } from '../api/daily-record.api'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'

export const fetchDailyRecordsByEmployeeDate = (
  clockDate: string,
  employeeId: string,
  options: FetchOptions = DEFAULT_FETCH_OPTIONS
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!clockDate || !employeeId) return

    const loading = getState().attendance.dailyRecordsLoading
    if (loading) return

    if (options.strategy === 'when-empty') {
      const data = Object.values(getState().attendance.dailyRecords?.entities || {})
      const hasData = data.find(d => moment(d?.clockDate).isSame(clockDate) && d?.employeeId === employeeId)
      if (hasData) return
    }

    try {
      dispatch(setDailyRecordsLoading(true))

      const data = Object.values(getState().attendance.dailyRecords?.entities || {})
      const dailyRecords = data.filter(d => moment(d?.clockDate).isSame(clockDate) && d?.employeeId === employeeId)
      for (const dailyRecord of dailyRecords) {
        if (dailyRecord?.id) dispatch(removeDailyRecord(dailyRecord.id))
      }

      const { result, status } = await apiGetDailyRecordByEmployeeDate(clockDate, employeeId)
      if (status) {
        dispatch(setDailyRecords(result))
      }
    } finally {
      dispatch(setDailyRecordsLoading(false))
    }
  }
}

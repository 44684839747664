import React, { FC, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Tooltip } from '~/core-components'
import { StoreState } from '~/types/store'
import { RptScreen } from '../types'
import { GroupingDrawer } from './GroupingDrawer'

interface GroupingButtonProps {
  screenCode: RptScreen
  groupingCodes: string[]
  pageBreaks: string[]
  onSelect: (groupingCodes: string[], pageBreaks: string[]) => void
}

interface DrawerState {
  visible: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

export const GroupingButton: FC<GroupingButtonProps> = ({ screenCode, groupingCodes, pageBreaks, onSelect }) => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const sysGroupings = useSelector((state: StoreState) => state.grouping.sysGroupings[screenCode]?.entities)
  const tooltipText = useMemo(
    () =>
      groupingCodes.length === 0
        ? 'Grouping'
        : `Grouped by ${
            sysGroupings && groupingCodes.map((g, i) => `${i === 0 ? '' : ' '}${sysGroupings[g]?.name?.toLowerCase()}`)
          }`,
    [sysGroupings, groupingCodes]
  )

  const handleOpenDrawer = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  return (
    <>
      <Tooltip title={tooltipText}>
        <Button icon={<i className="fal fa-layer-plus" />} badge={groupingCodes.length} onClick={handleOpenDrawer} />
      </Tooltip>
      <GroupingDrawer
        {...drawerState}
        screenCode={screenCode}
        groupingCodes={groupingCodes}
        pageBreaks={pageBreaks}
        onSelect={onSelect}
        onClose={handleCloseDrawer}
      />
    </>
  )
}

import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { apiSaveDailyPolicyRow } from '../api/daily-policy.api'
import { ISaveDailyPolicyRow } from '../types'
import { fetchDailyPolicyRows } from './fetch-daily-policy-rows'

export const saveDailyPolicyRow = (dailyPolicyId: string, request: ISaveDailyPolicyRow): ThunkResult<void> => {
  return async dispatch => {
    if (!dailyPolicyId) return

    const { status, result, errors, message, errorData } = await apiSaveDailyPolicyRow(dailyPolicyId, request)

    if (status) {
      const rowId = result?.id || request.id
      if (rowId) {
        dispatch(fetchDailyPolicyRows(dailyPolicyId))
      }

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}

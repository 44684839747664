import React, { FC, useCallback, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Button, Dropdown, MenuItemType, Space } from '~/core-components'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'

interface RptClaimDownloadBtnProps {
  onDownloadExcel: (includeReceipt: boolean) => Promise<void>
  onDownloadPdf: (includeReceipt: boolean) => Promise<void>
}

export const RptClaimDownloadBtn: FC<RptClaimDownloadBtnProps> = ({ onDownloadExcel, onDownloadPdf, children }) => {
  const [downloading, setDownloading] = useState(false)
  const isMountedRef = useIsMountedRef()

  const handleDownloadPdf = useCallback(
    async (includeReceipt: boolean) => {
      try {
        setDownloading(true)
        typeof onDownloadPdf === 'function' && (await onDownloadPdf(includeReceipt))
      } finally {
        if (isMountedRef.current) setDownloading(false)
      }
    },
    [onDownloadPdf, isMountedRef]
  )

  const handleDownloadExcel = useCallback(
    async (includeReceipt: boolean) => {
      try {
        setDownloading(true)
        typeof onDownloadExcel === 'function' && (await onDownloadExcel(includeReceipt))
      } finally {
        if (isMountedRef.current) setDownloading(false)
      }
    },
    [onDownloadExcel, isMountedRef]
  )

  const items: MenuItemType[] = [
    { key: 'excel', label: 'Excel', onClick: () => handleDownloadExcel(false) },
    { key: 'excel_receipt', label: 'Excel (incl. receipts)', onClick: () => handleDownloadExcel(true) },
    { key: 'pdf', label: 'PDF', onClick: () => handleDownloadPdf(false) },
    { key: 'pdf_receipt', label: 'PDF (incl. receipts)', onClick: () => handleDownloadPdf(true) }
  ]

  if (!onDownloadExcel && !onDownloadPdf) return null

  return (
    <Dropdown disabled={downloading} menu={{ items }}>
      <Button>
        {downloading ? (
          <LoadingOutlined />
        ) : (
          <Space size={4}>
            {children ? <>{children}</> : <i className="fal fa-arrow-down-to-bracket" />}
            <i className="fa-light fa-angle-down" />
          </Space>
        )}
      </Button>
    </Dropdown>
  )
}

import React, { CSSProperties, FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { ColumnsType, Drawer, Link, Space, Table, Tag } from '~/core-components'
import { EmCertRequestState } from '../../../../types'
import { useIsMountedRef } from '~/hooks'
import { EmCertRequestApprovalDrawer } from './EmCertRequestApprovalDrawer'
import { selectEmCertRequests } from '~/features/employee/selectors'

export interface EmCertRequestsProps {
  employeeId?: string
  visible: boolean
  onClose: () => void
}

interface ApprovalDrawerState {
  data?: EmCertRequestState
  visible: boolean
}

const DEFAULT_APPROVAL_DRAWER_STATE: ApprovalDrawerState = { visible: false }
const labelNameStyle: CSSProperties = { lineHeight: 1.1 }

export const EmCertRequestsDrawer: FC<EmCertRequestsProps> = ({ employeeId, visible, onClose }) => {
  const data = useSelector(selectEmCertRequests)(employeeId)

  const [approvalDrawerState, setApprovalDrawerState] = useState<ApprovalDrawerState>(DEFAULT_APPROVAL_DRAWER_STATE)
  const isMountedRef = useIsMountedRef()

  const handleView = useCallback(async (request: EmCertRequestState) => {
    setApprovalDrawerState({ data: request, visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose()
  }, [onClose])

  const handleCloseApprovalDrawer = useCallback(() => {
    if (isMountedRef.current) setApprovalDrawerState(DEFAULT_APPROVAL_DRAWER_STATE)
  }, [isMountedRef])

  const columns: ColumnsType<EmCertRequestState> = [
    {
      title: 'Name',
      key: 'nameNew',
      dataIndex: 'nameNew',
      render: (value: string, record) => (
        <>
          <div style={labelNameStyle}>
            <Space>
              {value}
              {record.recordStatus === 'new_request' && <Tag type="secondary">new (pending)</Tag>}
              {record.recordStatus === 'has_request' && <Tag type="secondary">pending</Tag>}
            </Space>
          </div>
        </>
      )
    },
    {
      title: 'Issuing organisation',
      key: 'issuedByNew',
      dataIndex: 'issuedByNew',
      width: 150
    },
    {
      title: 'Issue date',
      key: 'issueDateNew',
      dataIndex: 'issueDateNew',
      render: (value: string) => value && moment(value).format('DD MMM YYYY'),
      width: 110
    },
    {
      title: 'Expiry date',
      key: 'expiryDateNew',
      dataIndex: 'expiryDateNew',
      render: (value: string) => value && moment(value).format('DD MMM YYYY'),
      width: 110
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) => (
        <Link size="small" onClick={() => handleView(record)} noWrap>
          view
        </Link>
      )
    }
  ]

  return (
    <>
      <Drawer
        open={visible}
        title="License & certificate request"
        onClose={() => handleCloseDrawer()}
        width={750}
        className="em-cert-requests-drawer"
      >
        <Table rowKey="id" dataSource={data} columns={columns} pagination={false}></Table>
      </Drawer>
      <EmCertRequestApprovalDrawer {...approvalDrawerState} onClose={handleCloseApprovalDrawer} />
    </>
  )
}

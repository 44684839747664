import React, { CSSProperties, ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { Col, InfoTooltip, Row, SysOptions } from '~/components'
import { Form, Input, Space, Tooltip } from '~/core-components'
import { Errors } from '~/types/store'
import { KeyValue } from '~/types/common'
import { BankUobServiceType } from '~/constants'

interface FormData {
  company: string
  serviceType: string
  batchDescription: string
  reference: string
  referenceCustomText: string
}

interface BankFileUobFastGiroJsonbProps {
  bankCompanyId?: string
  valueDate: string
  batchNo: string
  bankFileJsonb: string
  onChange: (key: string, value: string) => void
  canModify: boolean
  errors?: Errors
  dropdownStyle?: CSSProperties
}

const EMPTY_FORM_DATA: FormData = {
  company: '',
  serviceType: '',
  batchDescription: '',
  reference: '',
  referenceCustomText: ''
}

export const BankFileUobFastGiroJsonb: FC<BankFileUobFastGiroJsonbProps> = ({
  bankCompanyId,
  valueDate,
  batchNo,
  bankFileJsonb,
  onChange,
  canModify,
  errors,
  dropdownStyle
}) => {
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)

  useEffect(() => {
    const data = JSON.parse(bankFileJsonb || '{}') as FormData
    const newData: FormData = {
      company: data.company || bankCompanyId || '',
      serviceType: data.serviceType,
      batchDescription: data.batchDescription,
      reference: data.reference,
      referenceCustomText: data.referenceCustomText
    }
    setFormData(newData)

    if (bankCompanyId && !data.company) {
      // raise change event to update bankCompanyId
      const value = JSON.stringify(newData)
      typeof onChange === 'function' && onChange('bankFileJsonb', value)
    }
  }, [bankFileJsonb, bankCompanyId])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      if ('batchNo' in updates) typeof onChange === 'function' && onChange('batchNo', updates.batchNo)
      else if ('valueDate' in updates) typeof onChange === 'function' && onChange('valueDate', updates.valueDate)
      else {
        setFormData(formData => {
          const updated = { ...formData, ...updates }
          const value = JSON.stringify({
            company: updated.company,
            serviceType: updated.serviceType,
            batchDescription: updated.batchDescription,
            reference: updated.reference,
            referenceCustomText: updated.referenceCustomText
          })

          typeof onChange === 'function' && onChange('bankFileJsonb', value)
          return updated
        })
      }
    },
    [onChange]
  )

  return (
    <>
      <Row>
        <Col span={24}>
          <Form.Item
            label="Service type"
            validateStatus={errors?.serviceType ? 'error' : ''}
            help={errors?.serviceType}
          >
            <SysOptions
              type="uob_fast_giro_service_type"
              value={formData.serviceType}
              readOnly={!canModify}
              onFilter={(value: KeyValue | undefined) =>
                [BankUobServiceType.GiroNormal, BankUobServiceType.GiroExpress, BankUobServiceType.Fast].includes(
                  (value?.key || '') as BankUobServiceType
                )
              }
              onChange={(value: string) => handleFormDataChange({ serviceType: value })}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Tooltip title={canModify ? 'Mandatory for FTS/FileAct. This is to be provided by the bank' : undefined}>
            <Form.Item label="Organisation ID" validateStatus={errors?.company ? 'error' : ''} help={errors?.company}>
              <Input
                maxLength={12}
                value={formData.company}
                readOnly={!canModify}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ company: event.target.value })
                }
              />
            </Form.Item>
          </Tooltip>
        </Col>
        <Col span={24}>
          <Form.Item label="Value date" validateStatus={errors?.valueDate ? 'error' : ''} help={errors?.valueDate}>
            <Input.Date
              value={valueDate ? moment(valueDate) : undefined}
              inputReadOnly={!canModify}
              onChange={(value: moment.Moment | null) =>
                handleFormDataChange({ valueDate: value?.format('YYYY-MM-DD') })
              }
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Batch no." validateStatus={errors?.batchNo ? 'error' : ''} help={errors?.batchNo}>
            <Input
              maxLength={2}
              value={batchNo}
              readOnly={!canModify}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ batchNo: event.target.value })}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Batch description"
            validateStatus={errors?.batchDescription ? 'error' : ''}
            help={errors?.batchDescription}
          >
            <Space direction="horizontal" className="fill-first">
              <Input
                maxLength={16}
                value={formData.batchDescription}
                readOnly={!canModify}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ batchDescription: event.target.value })
                }
              />
              <InfoTooltip title="Description to identify the batch. It will be shown on company’s bank account statement." />
            </Space>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Reference" validateStatus={errors?.reference ? 'error' : ''} help={errors?.reference}>
            <Space direction="horizontal" className="fill-first">
              <SysOptions
                style={dropdownStyle}
                allowClear={false}
                showSearch={false}
                type="bank_file_reference"
                value={formData.reference}
                readOnly={!canModify}
                dropdownMatchSelectWidth={false}
                onChange={(value: string) => handleFormDataChange({ reference: value })}
              />
              <InfoTooltip
                title="Description to identify the transaction to the employee and will be shown on the employee’s bank account
              statement."
              />
            </Space>
          </Form.Item>
        </Col>
        <Col span={24} hidden={formData.reference !== 'custom_text'}>
          <Form.Item
            label=" "
            colon={false}
            validateStatus={errors?.referenceCustomText ? 'error' : ''}
            help={errors?.referenceCustomText}
          >
            <Input
              maxLength={35}
              value={formData.referenceCustomText}
              readOnly={!canModify}
              placeholder="Enter your own text"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleFormDataChange({ referenceCustomText: event.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

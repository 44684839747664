import React, { FC, useCallback, useState } from 'react'
import { Button, ButtonProps } from '~/core-components'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'
import { downloadWithDom, showError } from '~/utils'
import { getFileTimestamp } from '~/utils/dateUtil'
import { apiGetYtdSubmissionIr8aExcel } from '../../api/ytd-submission.api'

interface Ir8aReportButtonProps extends ButtonProps {
  ytdSubmissionId: string
}

export const Ir8aReportButton: FC<Ir8aReportButtonProps> = ({ ytdSubmissionId, ...buttonProps }) => {
  const [downloading, setDownloading] = useState(false)
  const isMountedRef = useIsMountedRef()

  const handleDownloadClick = useCallback(async () => {
    try {
      setDownloading(true)
      const { status, result, errors, message, errorData } = await apiGetYtdSubmissionIr8aExcel(ytdSubmissionId)

      if (status) {
        const fileName = `ir8a_report_${getFileTimestamp()}.xlsx`
        downloadWithDom(result, fileName)
      } else {
        console.error('Error while downloading', errors)
        showError(message, errorData)
      }
    } finally {
      if (isMountedRef.current) setDownloading(false)
    }
  }, [ytdSubmissionId, isMountedRef])

  return (
    <Button onClick={handleDownloadClick} loading={downloading} {...buttonProps}>
      IR8A report
    </Button>
  )
}

import React, { FC, useState, useCallback, useEffect, memo, CSSProperties, ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Button, Form, Input, Space, Tag, UploadFile } from '~/core-components'
import { Col, DrawerForm, EmpKeyValues, Row, SysOptions, UploadFileSelectorAuth } from '~/components'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { IEmFamilyRequest, SSEmFamilyPublicSelfState } from '~/features/my/types'
import { emptyGuid, FamilyRelation } from '~/constants'
import { applyEmFamilyRequest, deleteEmFamilyRequest } from '~/features/my/actions'
import { selectKeyvaluesById } from '~/features/master'
import { EmFamilyRequestJsonbState, isDependant, useSysOptions } from '~/features/employee'
import { getBaseUrl } from '~/utils'

export interface MutateMyFamilyDrawerProps {
  visible: boolean
  employeeId?: string
  data?: SSEmFamilyPublicSelfState
  onClose: (action: 'saved' | 'cancelled') => void
}

const PendingTag = memo(() => <Tag type="secondary">pending</Tag>)

const EMPTY_FORM_DATA: IEmFamilyRequest = {
  id: emptyGuid,
  employeeId: '',
  emFamilyId: emptyGuid,
  name: '',
  relation: '',
  gender: '',
  birthDate: '',
  nationalityId: '',
  identityType: '',
  identityNo: '',
  phone: '',
  dependant: false,
  dataJsonb: ''
}

const currenTextStyle: CSSProperties = { fontSize: 13, color: '#797979' }
const baseUrl = getBaseUrl('/employee')

export const MutateMyFamilyDrawer: FC<MutateMyFamilyDrawerProps> = ({
  visible,
  employeeId,
  data,
  onClose
}: MutateMyFamilyDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IEmFamilyRequest>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()
  const [relationships] = useSysOptions('relationship')
  const [genders] = useSysOptions('gender')
  const [identityTypes] = useSysOptions('identity_type')
  const [yesNo] = useSysOptions('yes_no')
  const nationalities = useSelector(selectKeyvaluesById)('nationality')

  const isNew = data?.recordStatus === 'new_request'
  const isDiffName = !isNew && data?.name !== data?.nameNew
  const isDiffRelation = !isNew && data?.relation !== data?.relationNew
  const isDiffGender = !isNew && data?.gender !== data?.genderNew
  const isDiffBirthDate = !isNew && data?.birthDate !== data?.birthDateNew
  const isDiffNationalityId = !isNew && data?.nationalityId !== data?.nationalityIdNew
  const isDiffIdentityType = !isNew && data?.identityType !== data?.identityTypeNew
  const isDiffIdentityNo = !isNew && data?.identityNo !== data?.identityNoNew
  const isDiffPhone = !isNew && data?.phone !== data?.phoneNew
  const isDiffDependant = !isNew && data?.dependant !== data?.dependantNew
  const isDiffDataJsonb = !isNew && data?.dataJsonb !== data?.dataJsonbNew

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      let attachment: UploadFile | undefined
      if (data.attachmentId) {
        attachment = {
          uid: data.attachmentId,
          name: data.attachmentName || '',
          size: data.attachmentSize || 0,
          type: data.attachmentFormat || '',
          url: `${baseUrl}/ssemployeerequest/family/${data.requestId}/downloadfile`
        }
      }

      setFormData({
        id: data.requestId || emptyGuid,
        employeeId: employeeId || emptyGuid,
        emFamilyId: data.id,
        name: data.nameNew,
        relation: data.relationNew,
        gender: data.genderNew,
        birthDate: data.birthDateNew,
        nationalityId: data.nationalityIdNew,
        identityType: data.identityTypeNew,
        identityNo: data.identityNoNew,
        phone: data.phoneNew,
        dependant: data.dependantNew,
        dataJsonb: data.dataJsonbNew,
        attachment
      })
    } else {
      setFormData({ ...EMPTY_FORM_DATA, employeeId: employeeId || emptyGuid })
    }
  }, [data, employeeId])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(applyEmFamilyRequest(formData))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose('saved')
      setFormData(EMPTY_FORM_DATA)
    }
  }, [formData, onClose])

  const handleDelete = useCallback(
    (myFamily: SSEmFamilyPublicSelfState | undefined) => {
      if (employeeId && myFamily) {
        const { requestId, nameNew } = myFamily
        confirm({
          title: 'Delete family',
          content: `Do you want to delete family member record of "${nameNew}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteEmFamilyRequest(requestId))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose('saved')
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [employeeId, onClose]
  )

  const handleFileChange = useCallback(
    (attachments?: UploadFile[]) => {
      if (attachments && attachments.length > 0) {
        setFormData({ ...formData, attachment: attachments[0] })
      }
    },
    [formData]
  )

  const handleFileRemove = useCallback(
    (file?: UploadFile) => {
      if (file) {
        setFormData({ ...formData, attachment: undefined, deletedAttachmentId: file.uid })
      }
    },
    [formData]
  )

  const parseJsonb = useCallback((dataJsonb: string): string => {
    try {
      const familyRequest = JSON.parse(dataJsonb) as EmFamilyRequestJsonbState
      return familyRequest.firstCclDate
    } catch (error) {
      return ''
    }
  }, [])

  return (
    <DrawerForm
      open={visible}
      title={data ? <Space>Edit family {isNew && <Tag type="secondary">new (pending)</Tag>}</Space> : 'Add family'}
      okText={data?.recordStatus === 'has_request' ? 'Resubmit' : 'Submit'}
      onClose={() => onClose('cancelled')}
      confirmLoading={loading}
      width={600}
      showDelete={data ? (data?.recordStatus !== 'no_request' ? true : false) : false}
      onDelete={() => handleDelete(data)}
      formId="form-em-family-request"
    >
      <Form id="form-em-family-request" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item
              label={<Space>Name (as per IC) {isDiffName && <PendingTag />}</Space>}
              validateStatus={errors?.name ? 'error' : ''}
              help={errors?.name}
            >
              <Input
                value={formData.name}
                ref={focusRef}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
              />
              {isDiffName && <Space style={currenTextStyle}>Current: {data?.name || '-'}</Space>}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label={<Space>Relationship {isDiffRelation && <PendingTag />}</Space>}>
              <SysOptions
                type="relationship"
                value={formData.relation}
                onChange={(relation: string) => {
                  handleFormDataChange({
                    relation,
                    dataJsonb: relation === FamilyRelation.child ? formData.dataJsonb : '',
                    ...{ dependant: isDependant(relation) }
                  })
                }}
              />
              {isDiffRelation && (
                <Space style={currenTextStyle}>Current: {relationships[data?.relation || '']?.value || '-'}</Space>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<Space>Gender {isDiffGender && <PendingTag />}</Space>}>
              <SysOptions
                type="gender"
                value={formData.gender}
                onChange={(gender: string) => handleFormDataChange({ gender })}
              />
              {isDiffGender && (
                <Space style={currenTextStyle}>Current: {genders[data?.gender || '']?.value || '-'}</Space>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label={<Space>Date of birth {isDiffBirthDate && <PendingTag />}</Space>}>
              <Input.Date
                value={formData.birthDate ? moment(formData.birthDate) : undefined}
                onChange={(value: moment.Moment | null) =>
                  handleFormDataChange({ birthDate: value?.format('YYYY-MM-DD') })
                }
              />
              {isDiffBirthDate && (
                <div style={currenTextStyle}>
                  Current: {data?.birthDate ? moment(data?.birthDate).format('DD MMM YYYY') : '-'}
                </div>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<Space>Nationality {isDiffNationalityId && <PendingTag />}</Space>}>
              <EmpKeyValues
                id="nationality"
                controller="nationality"
                value={formData.nationalityId}
                onChange={(nationalityId: string) => handleFormDataChange({ nationalityId })}
              />
              {isDiffNationalityId && (
                <Space style={currenTextStyle}>Current: {nationalities[data?.nationalityId || '']?.value || '-'}</Space>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label={<Space>Identity type {isDiffIdentityType && <PendingTag />}</Space>}>
              <SysOptions
                type="identity_type"
                value={formData.identityType}
                dropdownMatchSelectWidth={false}
                onChange={(identityType: string) => handleFormDataChange({ identityType })}
              />
              {isDiffIdentityType && (
                <Space style={currenTextStyle}>Current: {identityTypes[data?.identityType || '']?.value || '-'}</Space>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<Space>Identity no. {isDiffIdentityNo && <PendingTag />}</Space>}
              validateStatus={errors?.identityNo ? 'error' : ''}
              help={errors?.identityNo}
            >
              <Input
                value={formData.identityNo}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ identityNo: event.target.value.toUpperCase() })
                }
              />
              {isDiffIdentityNo && <Space style={currenTextStyle}>Current: {data?.identityNo || '-'}</Space>}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item label={<Space>Phone {isDiffPhone && <PendingTag />}</Space>}>
              <Input
                value={formData.phone}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ phone: event.target.value })}
              />
              {isDiffPhone && <Space style={currenTextStyle}>Current: {data?.phone || '-'}</Space>}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<Space>Dependant? {isDiffDependant && <PendingTag />}</Space>}>
              <SysOptions
                type="yes_no"
                allowClear={false}
                value={formData.dependant?.toString()}
                onChange={(dependant: string) => handleFormDataChange({ dependant })}
              />
              {isDiffDependant && (
                <Space style={currenTextStyle}>Current: {yesNo[data?.dependant.toString() || '']?.value}</Space>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={formData.relation !== FamilyRelation.child}>
          <Col span={12}>
            <Form.Item label={<Space>First child care leave {isDiffDataJsonb && <PendingTag />}</Space>}>
              <Input.Date
                value={parseJsonb(formData.dataJsonb) ? moment(parseJsonb(formData.dataJsonb)) : undefined}
                onChange={(value: moment.Moment | null) => {
                  const dataJsonb = JSON.stringify({
                    firstCclDate: value?.format('YYYY-MM-DD')
                  })
                  handleFormDataChange({ dataJsonb })
                }}
              />
              {isDiffDataJsonb && (
                <div style={currenTextStyle}>
                  Current:{' '}
                  {data?.dataJsonb && parseJsonb(data.dataJsonb)
                    ? moment(parseJsonb(data.dataJsonb)).format('DD MMM YYYY')
                    : '-'}
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Attachment" validateStatus={errors?.attachment ? 'error' : ''} help={errors?.attachment}>
              <UploadFileSelectorAuth
                multiple={false}
                accept=".gif,.png,.jpeg,.jpg"
                fileList={formData.attachment ? [formData.attachment] : []}
                listType="picture-card"
                onChange={handleFileChange}
                onRemove={handleFileRemove}
                withCache={false}
              >
                <Button size="small">choose file</Button>
              </UploadFileSelectorAuth>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}

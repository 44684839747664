import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { RangeValue } from 'rc-picker/lib/interface.d'
import { Col, DocumentTitle, EmpKeyValues, PdfPasswordModal, PdfViewer, Row, SysOptions } from '~/components'
import {
  Button,
  Checkbox,
  CheckboxChangeEvent,
  Collapse,
  Form,
  Input,
  PageHeader,
  Select,
  Space
} from '~/core-components'
import { emptyGuid, RptEmStatus } from '~/constants'
import { fetchMasters, selectPayGroups } from '~/features/master'
import { GroupingButton } from '~/features/grouping/containers/GroupingButton'
import { RptScreen } from '~/features/grouping'
import { REPORTS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { Screen, useGetViewIdByName, ViewCriteriaButton } from '~/features/selection'
import { Errors, StoreState } from '~/types/store'
import { downloadWithDom, getFileTimestamp, showError } from '~/utils'
import { IRptPayYtd, ReportCode } from '../../types'
import { apiDownloadPayYtdExcel, apiDownloadPayYtdPdf } from '../../api/rpt-pay-ytd.api'
import { RptDownloadBtn } from '../components/RptDownloadBtn'
import { useFirstReportTemplate, useReportTemplate } from '../../hooks'
import { refetchReportTemplates } from '../../reducers'
import { ReportTemplate } from '../ReportTemplate/ReportTemplate'
import { RptSortingBtn } from '../components/RptSortingBtn'
import './RptPayYtd.less'

const routes = [
  {
    path: REPORTS_ROUTES.main,
    breadcrumbName: 'Reports'
  },
  {
    path: '',
    breadcrumbName: 'YTD payroll report'
  }
]

interface RptPayYtdForm extends IRptPayYtd {
  templateId: string
}

const currentYear = moment().year()

const EMPTY_FORM_DATA: RptPayYtdForm = {
  startMonth: `${currentYear}-01`,
  endMonth: `${currentYear}-12`,
  companyId: '',
  payGroupIds: [],
  emStatus: RptEmStatus.all,
  sorting: 'employee_name',
  groupingCodes: [],
  pageBreaks: [],
  showCpfDetails: false,
  emPageBreak: false,
  viewId: '',
  reportTitle: '',
  notes: '',
  templateId: emptyGuid
}

const SCREEN_CODE: Screen = 'rpt_pay_ytd'
const RPT_SCREEN_CODE: RptScreen = 'rpt_payroll'
const REPORT_CODE: ReportCode = 'rpt_pay_ytd'

export const RptPayYtd: FC = () => {
  const [formData, setFormData] = useState<RptPayYtdForm>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const payGroups = useSelector(selectPayGroups)
  const companyIds = useSelector((state: StoreState) => state.master.keyvalues['company']?.ids)
  const [previewUrl, setPreviewUrl] = useState<string>('')
  const [previewing, setPreviewing] = useState(false)
  const [activeKey, setActiveKey] = useState(0)
  const [pdfModal, setPdfModal] = useState(false)

  const [firstTemplate] = useFirstReportTemplate('payroll-sg', REPORT_CODE)
  const firstTemplateId = firstTemplate?.id || ''
  const [templateId, setTemplateId] = useState<string>()
  const [template] = useReportTemplate('payroll-sg', REPORT_CODE, templateId)

  const [viewId] = useGetViewIdByName(SCREEN_CODE, templateId ? `${SCREEN_CODE}_${templateId}` : undefined)

  useEffect(() => {
    dispatch(fetchMasters('payGroup', { strategy: 'when-empty' }))
  }, [])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    if ('companyId' in updates) {
      updates.payGroupIds = []
    }

    setFormData(data => ({ ...data, ...updates }))
  }, [])

  useEffect(() => {
    if (!templateId && firstTemplateId) {
      setTemplateId(firstTemplateId)
    }

    if (template) {
      const saved = JSON.parse(template.dataJsonb) as RptPayYtdForm
      saved.templateId = templateId || ''
      saved.emStatus = saved.emStatus || RptEmStatus.all
      saved.viewId = viewId || ''
      setFormData(formData => ({ ...formData, ...saved }))
    }
  }, [firstTemplateId, templateId, template, viewId])

  useEffect(() => {
    setFormData(formData => {
      if (!formData.companyId) {
        if (companyIds && companyIds.length > 0) {
          return { ...formData, companyId: companyIds[0].toString(), payGroupIds: [] }
        }
      }
      return formData
    })
  }, [companyIds])

  const handlePreview = useCallback(async () => {
    setPreviewing(true)
    setErrors(undefined)

    try {
      const { status, result, errors, message, errorData } = await apiDownloadPayYtdPdf(formData)

      if (status) {
        const url = URL.createObjectURL(result)
        setPreviewUrl(url)
        dispatch(refetchReportTemplates())
      } else {
        console.error('Error while downloading', errors)
        setErrors(errors)
        showError(message, errorData)
      }
    } finally {
      setPreviewing(false)
    }
  }, [formData])

  const handleDownloadExcel = useCallback(async () => {
    const { status, result, errors, message, errorData } = await apiDownloadPayYtdExcel(formData)

    if (status) {
      const fileName = `payroll_ytd_report_${getFileTimestamp()}.xlsx`
      downloadWithDom(result, fileName)
      dispatch(refetchReportTemplates())
    } else {
      console.error('Error while downloading', errors)
      setErrors(errors)
      showError(message, errorData)
    }
  }, [formData])

  const handleDownloadPdf = useCallback(
    async (password?: string) => {
      const { status, result, errors, message, errorData } = await apiDownloadPayYtdPdf({ ...formData, password })

      if (status) {
        const fileName = `payroll_ytd_report_${getFileTimestamp()}.pdf`
        downloadWithDom(result, fileName)
        dispatch(refetchReportTemplates())
      } else {
        console.error('Error while downloading', errors)
        setErrors(errors)
        showError(message, errorData)
      }
    },
    [formData]
  )

  const handleOpenPdfModal = useCallback(async () => {
    setPdfModal(true)
  }, [])

  const handleClosePdfModal = useCallback(() => {
    setPdfModal(false)
  }, [])

  const handleCollapse = useCallback(async () => {
    if (!activeKey) {
      setActiveKey(1)
    } else {
      setActiveKey(0)
    }
  }, [activeKey])

  const handleTemplateChange = useCallback((templateId?: string) => {
    setTemplateId(templateId)
  }, [])

  return (
    <div id="rpt-pay-ytd" className="rpt-pay-ytd">
      <DocumentTitle title="Year-to-Date Payroll Report" />
      <PageHeader
        title="Year-to-Date payroll report"
        containerId="rpt-pay-ytd"
        breadcrumb={{ routes }}
        extra={
          <ReportTemplate<IRptPayYtd>
            basePath="payroll-sg"
            reportCode={REPORT_CODE}
            templateId={templateId}
            templateData={formData}
            size="small"
            onChange={handleTemplateChange}
          />
        }
      />
      <Form className="rpt-pay-ytd__form" layout="horizontal" labelCol={{ flex: '130px' }}>
        <Row gutter={30}>
          <Col flex="350px">
            <Form.Item
              label="Month range"
              validateStatus={errors?.startMonth || errors?.endMonth ? 'error' : ''}
              help={errors?.startMonth || errors?.endMonth}
            >
              <Input.MonthRange
                value={[
                  formData.startMonth ? moment(formData.startMonth) : null,
                  formData.endMonth ? moment(formData.endMonth) : null
                ]}
                onCalendarChange={(months: RangeValue<moment.Moment>) => {
                  const startMonth = months && months[0] ? months[0].format('YYYY-MM') : undefined
                  const endMonth = months && months[1] ? months[1].format('YYYY-MM') : undefined

                  handleFormDataChange({ startMonth, endMonth })
                }}
              />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item
              label="Company"
              labelCol={{ flex: '80px' }}
              validateStatus={errors?.companyId ? 'error' : ''}
              help={errors?.companyId}
            >
              <EmpKeyValues
                id="company"
                allowClear={false}
                value={formData.companyId}
                onChange={(companyId?: string) => handleFormDataChange({ companyId })}
              />
            </Form.Item>
          </Col>
          <Col flex="1"></Col>
          <Col flex="none">
            <Space>
              <RptSortingBtn
                sorting={formData.sorting}
                onSelect={(sorting: string) => handleFormDataChange({ sorting })}
              />
              <ViewCriteriaButton screenCode={SCREEN_CODE} viewId={formData.viewId} />
              <GroupingButton
                screenCode={RPT_SCREEN_CODE}
                groupingCodes={formData.groupingCodes || []}
                pageBreaks={formData.pageBreaks || []}
                onSelect={(groupingCodes: string[], pageBreaks: string[]) =>
                  handleFormDataChange({ groupingCodes, pageBreaks })
                }
              />
              <Button
                icon={<i className="fal fa-gear" />}
                type={activeKey ? 'primary' : 'default'}
                onClick={handleCollapse}
              />
              <Button type="primary" onClick={handlePreview} loading={previewing}>
                Preview
              </Button>
              <RptDownloadBtn
                onDownloadExcel={handleDownloadExcel}
                onDownloadPdf={handleDownloadPdf}
                onDownloadSecurePdf={handleOpenPdfModal}
              />
            </Space>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col flex="1">
            <Form.Item label="Payroll groups">
              <Select
                mode="multiple"
                value={formData.payGroupIds || []}
                placeholder="Select payroll groups"
                showSearch
                filterOption={(input, option) =>
                  option?.display?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value: string[]) => handleFormDataChange({ payGroupIds: value })}
              >
                {payGroups
                  .filter(pg => pg?.companyId === formData.companyId)
                  .map(pg => (
                    <Select.Option key={pg?.id} value={pg?.id || ''} display={pg?.name}>
                      {pg?.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="1">
            <Collapse className="rpt-pay-ytd__more-settings" activeKey={activeKey} onChange={handleCollapse} noStyle>
              <Collapse.Panel key="1" header={null} showArrow={false}>
                <Row gutter={30}>
                  <Col flex="490px">
                    <Form.Item
                      label="Filter by employee"
                      validateStatus={errors?.emStatus ? 'error' : ''}
                      help={errors?.emStatus}
                    >
                      <SysOptions
                        allowClear={false}
                        type="rpt_em_status"
                        placeholder="All employees"
                        value={formData.emStatus}
                        onChange={(emStatus?: string) => handleFormDataChange({ emStatus })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="1">
                    <Form.Item label="Options">
                      <Checkbox
                        checked={formData.showCpfDetails}
                        onChange={(event: CheckboxChangeEvent) =>
                          handleFormDataChange({ showCpfDetails: event.target.checked })
                        }
                      >
                        Show CPF details
                      </Checkbox>
                      <Checkbox
                        checked={formData.emPageBreak}
                        onChange={(event: CheckboxChangeEvent) =>
                          handleFormDataChange({ emPageBreak: event.target.checked })
                        }
                      >
                        Page break per employee
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="490px">
                    <Form.Item label="Report title">
                      <Input
                        value={formData.reportTitle}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          handleFormDataChange({ reportTitle: event.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="1">
                    <Form.Item label="Custom notes">
                      <Input.TextArea
                        rows={3}
                        value={formData.notes}
                        onChange={(value?: ChangeEvent<HTMLTextAreaElement>) =>
                          handleFormDataChange({ notes: value?.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
      </Form>
      {previewUrl && (
        <div className="rpt-pay-ytd__pdf-viewer">
          <Row>
            <Col>
              <PdfViewer file={previewUrl} layout="landscape" scale={1.3} />
            </Col>
          </Row>
        </div>
      )}
      <PdfPasswordModal open={pdfModal} onApply={handleDownloadPdf} onClose={handleClosePdfModal} />
    </div>
  )
}

import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { Screen, SysRuleCriteriaState } from '../types'

export const selectSysRuleCriterias = createSelector(
  (state: StoreState) => state.rule.sysRuleCriteria,
  sysRuleCriteriaState =>
    memoize((screenCode: Screen): SysRuleCriteriaState[] => {
      const sysRuleCriterias = Object.values(sysRuleCriteriaState?.entities || {}) as SysRuleCriteriaState[]
      return sysRuleCriterias
        .filter(r => (r.screenCode as Screen) === screenCode)
        .sort((a, b) => a.name?.localeCompare(b.name))
    })
)

import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import moment from 'moment-timezone'
import { Button, Card, ColumnsType, Form, Space, Spin, Table, Tooltip } from '~/core-components'
import { AmountDisplay, EmpKeyValues, Person, SearchInput, YearKeyValues } from '~/components'
import {
  Screen,
  updateViewCriteria,
  useFirstView,
  useViewSchema,
  ViewCriteria,
  ViewCriteriaSimple
} from '~/features/selection'
import { usePermissionGate } from '~/features/iam'
import { StoreState } from '~/types/store'
import { EMP_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { Permission, PermissionAction } from '~/constants'
import {
  downloadWithDom,
  formatMoney,
  formatMonth,
  formatYearMonth,
  getBaseUrl,
  getFileTimestamp,
  showError
} from '~/utils'
import { useIsMountedRef } from '~/hooks'
import { apiGetYtdRecordsExcel, apiProcessYtdRecords } from '../../api/ytd-record.api'
import { YtdRecordState } from '../../types'
import { refetchYtdRecordView } from '../../reducers'
import { useYtdRecordView } from '../../hooks'
import { ProcessYtdRecordsDrawer } from './ProcessYtdRecordsDrawer'
import './YtdRecords.less'

interface YtdRecordsProps {}

interface DrawerState {
  visible: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }
const SCREEN_CODE: Screen = 'ytd_record'
const PAGE_SIZE_OPTIONS = ['20', '50', '100']
const THIS_YEAR = moment().year()

const nameColWidth = 250
const monthColWidth = 110
const defaultColWidth = 100
const defaultWideColWidth = 160
const paginationStyle: CSSProperties = { marginRight: 20 }
const companyStyle: CSSProperties = { width: 180 }
const baseUrl = getBaseUrl('/filestore')

export const YtdRecords: FC<YtdRecordsProps> = () => {
  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const [schema] = useViewSchema(SCREEN_CODE, viewId)
  const selection = useSelector((state: StoreState) => state.selection.sysSelectionFields[SCREEN_CODE])

  const [ytdYear, setYtdYear] = useState<string>(THIS_YEAR.toString())
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [search, setSearch] = useState<string>('')

  const [downloading, setDownloading] = useState(false)
  const isMountedRef = useIsMountedRef()

  const [companyId, setCompanyId] = useState<string>('')
  const companyIds = useSelector((state: StoreState) => state.master.keyvalues['company']?.ids)

  const canModify = usePermissionGate(Permission.ytd, PermissionAction.Modify)
  const canViewEmployee = usePermissionGate(Permission.employee)
  const ytdRecordExpanded = useSelector((state: StoreState) => state.tax.ytdRecordExpanded)

  const [data, dataLoading] = useYtdRecordView(viewId, ytdYear, companyId, page, pageSize, search, 'always')

  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)

  useEffect(() => {
    setCompanyId(companyId => {
      if (!companyId) {
        if (companyIds && companyIds.length > 0) {
          return companyIds[0].toString()
        }
      }
      return companyId
    })
  }, [companyIds])

  const tableWidth =
    (schema?.selection.reduce(
      (sum, f) => (sum += selection?.entities[f.selectionFieldId]?.width || defaultColWidth),
      0
    ) || 0) +
    nameColWidth +
    monthColWidth +
    20 * defaultColWidth + // fixed columns
    7 * defaultWideColWidth // fixed wider columns

  const columns = useMemo(() => {
    const columns: ColumnsType<YtdRecordState> = [
      {
        title: 'Name',
        key: 'employeeId',
        dataIndex: 'employeeId',
        fixed: 'left',
        className: 'first-col',
        width: nameColWidth,
        onCell: (record, index) => {
          const prevId = data?.data[(index as number) - 1]?.employeeId
          if (prevId === record.employeeId) {
            return { rowSpan: 0 }
          } else {
            const nextId = data?.data[(index as number) + 1]?.employeeId
            if (nextId === record.employeeId) {
              return { rowSpan: record.rowSpan }
            }
          }
          return {}
        },
        render: (_, record) => (
          <Person
            name={record.employeeName}
            description={record.description}
            photo={record.photoId && `${baseUrl}/file/${record.photoId}/thumbnailphoto/${ytdRecordExpanded ? 18 : 36}`}
            // photo={
            //   record.employeeId &&
            //   `/employee/employee/${record.employeeId}/avatar/${ytdRecordExpanded ? 18 : 36}?photo_id=${record.photoId}`
            // }
            path={canViewEmployee ? EMP_ROUTES.employee.replace(':id', record.employeeId) : undefined}
            size={ytdRecordExpanded ? 18 : 36}
          />
        )
      }
    ]

    if (schema) {
      // Configurable employee columns
      columns.push(
        ...schema?.selection.map(f => {
          var field = selection?.entities[f.selectionFieldId]
          return {
            title: field?.description,
            key: field?.fieldName,
            dataIndex: field?.fieldName,
            width: field?.width || defaultColWidth,
            onCell: (record: YtdRecordState, index?: number) => {
              const prevId = data?.data[(index as number) - 1]?.employeeId
              if (prevId === record.employeeId) {
                return { rowSpan: 0 }
              } else {
                const nextId = data?.data[(index as number) + 1]?.employeeId
                if (nextId === record.employeeId) {
                  return { rowSpan: record.rowSpan }
                }
              }
              return {}
            },
            render: (value: string | number) => {
              var display = value
              if (field?.format === 'date' && value) {
                display = moment(value).format('DD MMM YYYY')
              }
              if (field?.format === 'yearmonth' && value) {
                display = formatYearMonth(value as string)
              }
              if (field?.format === 'month' && value) {
                display = formatMonth(value as number)
              }
              if (field?.format === 'money' && value) {
                display = formatMoney(value as number)
              }
              return display
            }
          }
        })
      )
    }

    // Fixed ytd records columns
    columns.push(
      {
        title: 'Month',
        key: 'paymentMonth',
        dataIndex: 'paymentMonth',
        fixed: 'left',
        width: monthColWidth,
        render: (paymentMonth: number, record) => (
          <YtdRecordMonth ytdYear={ytdYear} paymentMonth={paymentMonth} employeeId={record.employeeId} />
        )
      },
      {
        title: 'Gross',
        key: 'gross',
        dataIndex: 'gross',
        width: defaultColWidth,
        align: 'right',
        render: (gross: number, record) => <AmountDisplay value={gross + record.grossAdj} />
      },
      {
        title: 'Bonus contractual',
        key: 'bonusC',
        dataIndex: 'bonusC',
        width: defaultWideColWidth,
        align: 'right',
        render: (bonusC: number, record) => <AmountDisplay value={bonusC + record.bonusCAdj} />
      },
      {
        title: 'Bonus non-contractual',
        key: 'bonusNc',
        dataIndex: 'bonusNc',
        width: defaultWideColWidth,
        align: 'right',
        render: (bonusNc: number, record) => <AmountDisplay value={bonusNc + record.bonusNcAdj} />
      },
      {
        title: 'Commission',
        key: 'commission',
        dataIndex: 'commission',
        width: defaultColWidth,
        align: 'right',
        render: (commission: number, record) => <AmountDisplay value={commission + record.commissionAdj} />
      },
      {
        title: 'Compensation',
        key: 'compensation',
        dataIndex: 'compensation',
        width: defaultColWidth,
        align: 'right',
        render: (compensation: number, record) => <AmountDisplay value={compensation + record.compensationAdj} />
      },
      {
        title: 'Director fee',
        key: 'directorFee',
        dataIndex: 'directorFee',
        width: defaultColWidth,
        align: 'right',
        render: (directorFee: number, record) => <AmountDisplay value={directorFee + record.directorFeeAdj} />
      },
      {
        title: 'Entertainment',
        key: 'entertainment',
        dataIndex: 'entertainment',
        width: defaultColWidth,
        align: 'right',
        render: (entertainment: number, record) => <AmountDisplay value={entertainment + record.entertainmentAdj} />
      },
      {
        title: 'Ex-gratia',
        key: 'exgratia',
        dataIndex: 'exgratia',
        width: defaultColWidth,
        align: 'right',
        render: (exgratia: number, record) => <AmountDisplay value={exgratia + record.exgratiaAdj} />
      },
      {
        title: 'Gratuity',
        key: 'gratuity',
        dataIndex: 'gratuity',
        width: defaultColWidth,
        align: 'right',
        render: (gratuity: number, record) => <AmountDisplay value={gratuity + record.gratuityAdj} />
      },
      {
        title: 'Insurance',
        key: 'insurance',
        dataIndex: 'insurance',
        width: defaultColWidth,
        align: 'right',
        render: (insurance: number, record) => <AmountDisplay value={insurance + record.insuranceAdj} />
      },
      {
        title: 'Notice',
        key: 'notice',
        dataIndex: 'notice',
        width: defaultColWidth,
        align: 'right',
        render: (notice: number, record) => <AmountDisplay value={notice + record.noticeAdj} />
      },
      {
        title: 'Others',
        key: 'other',
        dataIndex: 'other',
        width: defaultColWidth,
        align: 'right',
        render: (other: number, record) => <AmountDisplay value={other + record.otherAdj} />
      },
      {
        title: 'Other lumpsum',
        key: 'otherLumpsum',
        dataIndex: 'otherLumpsum',
        width: defaultWideColWidth,
        align: 'right',
        render: (otherLumpsum: number, record) => <AmountDisplay value={otherLumpsum + record.otherLumpsumAdj} />
      },
      {
        title: 'Pension',
        key: 'pension',
        dataIndex: 'pension',
        width: defaultColWidth,
        align: 'right',
        render: (pension: number, record) => <AmountDisplay value={pension + record.pensionAdj} />
      },
      {
        title: 'Retirement (up to 1992)',
        key: 'retirement',
        dataIndex: 'retirement',
        width: defaultWideColWidth,
        align: 'right',
        render: (retirement: number, record) => <AmountDisplay value={retirement + record.retirementAdj} />
      },
      {
        title: 'Retirement (from 1993)',
        key: 'retirement2',
        dataIndex: 'retirement2',
        width: defaultWideColWidth,
        align: 'right',
        render: (retirement2: number, record) => <AmountDisplay value={retirement2 + record.retirement2Adj} />
      },
      {
        title: 'Transport',
        key: 'transport',
        dataIndex: 'transport',
        width: defaultColWidth,
        align: 'right',
        render: (transport: number, record) => <AmountDisplay value={transport + record.transportAdj} />
      },
      {
        title: 'CPF employee',
        key: 'cpfEm',
        dataIndex: 'cpfEm',
        width: defaultColWidth,
        align: 'right',
        render: (cpfEm: number) => <AmountDisplay value={cpfEm} />
      },
      {
        title: 'A8A leave passage',
        key: 'a8aLeavePassage',
        dataIndex: 'a8aLeavePassage',
        width: defaultWideColWidth,
        align: 'right',
        render: (a8aLeavePassage: number) => <AmountDisplay value={a8aLeavePassage} />
      },
      {
        title: 'A8A interest',
        key: 'a8aInterest',
        dataIndex: 'a8aInterest',
        width: defaultColWidth,
        align: 'right',
        render: (a8aInterest: number) => <AmountDisplay value={a8aInterest} />
      },
      {
        title: 'A8A insurance',
        key: 'a8aInsurance',
        dataIndex: 'a8aInsurance',
        width: defaultColWidth,
        align: 'right',
        render: (a8aInsurance: number) => <AmountDisplay value={a8aInsurance} />
      },
      {
        title: 'A8A holiday',
        key: 'a8aHoliday',
        dataIndex: 'a8aHoliday',
        width: defaultColWidth,
        align: 'right',
        render: (a8aHoliday: number) => <AmountDisplay value={a8aHoliday} />
      },
      {
        title: 'A8A education',
        key: 'a8aEducation',
        dataIndex: 'a8aEducation',
        width: defaultWideColWidth,
        align: 'right',
        render: (a8aEducation: number) => <AmountDisplay value={a8aEducation} />
      },
      {
        title: 'A8A other',
        key: 'a8aOther',
        dataIndex: 'a8aOther',
        width: defaultColWidth,
        align: 'right',
        render: (a8aOther: number) => <AmountDisplay value={a8aOther} />
      },
      {
        title: 'A8A club',
        key: 'a8aClub',
        dataIndex: 'a8aClub',
        width: defaultColWidth,
        align: 'right',
        render: (a8aClub: number) => <AmountDisplay value={a8aClub} />
      },
      {
        title: 'A8A gain',
        key: 'a8aGain',
        dataIndex: 'a8aGain',
        width: defaultColWidth,
        align: 'right',
        render: (a8aGain: number) => <AmountDisplay value={a8aGain} />
      },
      {
        title: 'A8A vehicle',
        key: 'a8aVehicle',
        dataIndex: 'a8aVehicle',
        width: defaultColWidth,
        align: 'right',
        render: (a8aVehicle: number) => <AmountDisplay value={a8aVehicle} />
      },
      {
        title: 'A8A car',
        key: 'a8aCar',
        dataIndex: 'a8aCar',
        width: defaultColWidth,
        align: 'right',
        render: (a8aCar: number) => <AmountDisplay value={a8aCar} />
      }
    )

    return columns
  }, [schema, selection, data?.data, ytdYear, canViewEmployee, ytdRecordExpanded])

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const handleSearch = useCallback((value: string) => {
    setPage(1)
    setSearch(value)
  }, [])

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        setPage(1)
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchYtdRecordView())
      }
    },
    [viewId]
  )

  const handleProcessClick = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const handleDownloadClick = useCallback(async () => {
    if (viewId) {
      try {
        setDownloading(true)
        const { status, result, errors, message, errorData } = await apiGetYtdRecordsExcel(viewId, ytdYear, companyId, {
          search: ''
        })

        if (status) {
          const fileName = `ytd_records_${getFileTimestamp()}.xlsx`
          downloadWithDom(result, fileName)
        } else {
          console.error('Error while downloading', errors)
          showError(message, errorData)
        }
      } finally {
        if (isMountedRef.current) setDownloading(false)
      }
    }
  }, [viewId, ytdYear, companyId, isMountedRef])

  return (
    <div className="ytd-records">
      <div className="ytd-records__body">
        <div className="ytd-records__action-bar">
          <Space>
            <Form.Item label="">
              <YearKeyValues
                value={ytdYear}
                startYear={THIS_YEAR}
                noOfYearBefore={-10}
                noOfYearAfter={1}
                allowClear={false}
                onChange={setYtdYear}
              />
            </Form.Item>
            <Form.Item label="">
              <EmpKeyValues
                id="company"
                value={companyId}
                allowClear={false}
                style={companyStyle}
                onChange={setCompanyId}
              />
            </Form.Item>
            <Form.Item label="">
              <SearchInput onSearch={handleSearch} />
            </Form.Item>
          </Space>
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
          <Space align="start">
            {canModify && (
              <>
                <ProcessYtdRecordsDrawer {...drawerState} onClose={handleCloseDrawer} />
                <Button onClick={handleProcessClick}>Recalculate</Button>
                <Tooltip title="Download excel">
                  <Button
                    icon={<i className="fal fa-arrow-down-to-bracket" />}
                    onClick={handleDownloadClick}
                    loading={downloading}
                  />
                </Tooltip>
              </>
            )}
          </Space>
        </div>
        <Card fitParent table>
          <Table
            rowKey="id"
            dataSource={data?.data}
            columns={columns}
            fitParent
            loading={dataLoading || viewLoading}
            scroll={{ x: tableWidth, y: 1000 }}
            pagination={{
              total: data?.count,
              current: page,
              pageSize,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showSizeChanger: true,
              onChange: handlePaginationChange,
              style: paginationStyle
            }}
          />
        </Card>
      </div>
    </div>
  )
}

interface YtdRecordMonthProps {
  ytdYear: string
  paymentMonth: number
  employeeId: string
}

const YtdRecordMonth: FC<YtdRecordMonthProps> = ({ ytdYear, paymentMonth, employeeId }) => {
  const [processing, setProcessing] = useState(false)

  const handleProcessing = useCallback(async () => {
    try {
      setProcessing(true)
      await apiProcessYtdRecords(ytdYear, paymentMonth, [employeeId])
      dispatch(refetchYtdRecordView())
    } finally {
      setProcessing(false)
    }
  }, [ytdYear, paymentMonth, employeeId])

  return (
    <Space>
      {`${ytdYear}-${paymentMonth.toString().padStart(2, '0')}`}
      {processing ? (
        <Spin indicator={<LoadingOutlined spin />} />
      ) : (
        <Tooltip title="Recalculate">
          <i className="fal fa-refresh" onClick={handleProcessing} />
        </Tooltip>
      )}
    </Space>
  )
}

import { request, getBaseUrl } from '~/utils/request'
import { IEmployeeEmergency, IEmployeePersonal } from '~/features/employee'
import { IEmAddressRequest, IEmCertRequest, IEmEducationRequest, IEmFamilyRequest, IEmIdentityRequest } from '../types'

const baseUrl = getBaseUrl('/employee/ssemployeerequest')

export const apiApplyPersonalRequest = (personal: IEmployeePersonal) =>
  request<void>('post', `${baseUrl}/personal`, personal)

export const apiApplyEmergencyRequest = (emergency: IEmployeeEmergency) =>
  request<void>('post', `${baseUrl}/emergency`, emergency)

export const apiApplyEmIdentityRequest = (emIdentity: IEmIdentityRequest) => {
  let formData = new FormData()

  Object.entries(emIdentity).forEach(([key, value]) => {
    if (key === 'attachment') {
      if (value instanceof File) formData.append(key, value, value.name)
    } else {
      formData.append(key, value === null ? '' : value)
    }
  })

  return request<void>('post', `${baseUrl}/identity`, formData)
}

export const apiDeleteEmIdentityRequest = (id: string) => request<void>('delete', `${baseUrl}/identity/${id}`)

export const apiApplyEmAddressRequest = (emAddress: IEmAddressRequest) => {
  let formData = new FormData()

  Object.entries(emAddress).forEach(([key, value]) => {
    if (key === 'attachment') {
      if (value instanceof File) formData.append(key, value, value.name)
    } else {
      formData.append(key, value === null ? '' : value)
    }
  })

  return request<void>('post', `${baseUrl}/address`, formData)
}

export const apiDeleteEmAddressRequest = (id: string) => request<void>('delete', `${baseUrl}/address/${id}`)

export const apiApplyEmEducationRequest = (emEducation: IEmEducationRequest) => {
  let formData = new FormData()

  Object.entries(emEducation).forEach(([key, value]) => {
    if (key === 'attachment') {
      if (value instanceof File) formData.append(key, value, value.name)
    } else {
      formData.append(key, value === null ? '' : value)
    }
  })

  return request<void>('post', `${baseUrl}/education`, formData)
}

export const apiDeleteEmEducationRequest = (id: string) => request<void>('delete', `${baseUrl}/education/${id}`)

export const apiApplyEmFamilyRequest = (emFamily: IEmFamilyRequest) => {
  let formData = new FormData()

  Object.entries(emFamily).forEach(([key, value]) => {
    if (key === 'attachment') {
      if (value instanceof File) formData.append(key, value, value.name)
    } else {
      formData.append(key, value === null ? '' : value)
    }
  })

  return request<void>('post', `${baseUrl}/family`, formData)
}

export const apiDeleteEmFamilyRequest = (id: string) => request<void>('delete', `${baseUrl}/family/${id}`)

export const apiApplyEmCertRequest = (emCert: IEmCertRequest) => {
  let formData = new FormData()

  Object.entries(emCert).forEach(([key, value]) => {
    if (key === 'attachment') {
      if (value instanceof File) formData.append(key, value, value.name)
    } else {
      formData.append(key, value === null ? '' : value)
    }
  })

  return request<void>('post', `${baseUrl}/cert`, formData)
}

export const apiDeleteEmCertRequest = (id: string) => request<void>('delete', `${baseUrl}/cert/${id}`)

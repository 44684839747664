import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { EmCertRequestState } from '../types'

export const selectEmCertRequests = createSelector(
  (state: StoreState) => state.employee.emCertRequests,
  emCertRequestsState =>
    memoize((employeeId?: string) => {
      if (!employeeId) return []

      return Object.values(emCertRequestsState[employeeId]?.entities || {}) as EmCertRequestState[]
    })
)

import { showError, showSuccess, toSentence } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { IYtdIndexPublish } from '../types'
import { refetchYtdIndexView } from '../reducers'
import { apiPublishYtdIndex } from '../api/ytd-index.api'

export const publishYtdIndex = (type: string, ytdYear: string, ids: IYtdIndexPublish): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult | undefined> => {
    const { status, errors, message, errorData } = await apiPublishYtdIndex(type, ytdYear, ids)
    if (status) {
      dispatch(refetchYtdIndexView())
      showSuccess(`${toSentence(type)}ed`)
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}

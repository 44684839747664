import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button, Space, Steps } from '~/core-components'
import { DrawerForm } from '~/components'
import { EmploymentConfig, HiringType, emptyGuid } from '~/constants'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { EMP_ROUTES } from '~/routes/routes'
import { copyEmployee, copyEmployeeValidate, ICopyEmployee } from '~/features/employee'
import { fetchEmpKeyvalues } from '~/features/master'
import {
  fetchEmployee,
  fetchEmCompany,
  fetchEmDepartment,
  fetchEmJob,
  fetchEmManager,
  fetchEmTeam,
  fetchEmOffice,
  fetchEmCostCentre,
  fetchEmEmploymentType,
  fetchEmIdentity,
  fetchEmAddressMain,
  fetchEmCalendar,
  fetchEmFamilies,
  fetchEmEducations,
  fetchEmLocation,
  fetchEmAttendanceGroup,
  fetchEmShiftRole,
  fetchEmCategory,
  fetchEmDivision,
  fetchEmGrp,
  fetchEmManagerSecondary,
  fetchEmOtClass,
  fetchEmSection,
  fetchEmCerts
} from '../../../../actions'
import { RehireEntry } from './components/RehireEntry'
import { CopyEm } from './components/CopyEm'
import { CopyEmComplete } from './components/CopyEmComplete'
import { RehireDisplay } from './components/RehireDisplay'
import './RehireEmployeeDrawer.less'
import { useHiddenEmploymentConfigs } from '~/features/master/hooks'

interface RehireEmployeeDrawerProps {
  visible: boolean
  onClose: (success?: boolean) => void
}

const EMPTY_FORM_DATA: ICopyEmployee = {
  employeeId: '',
  employeeNo: '',
  hireDate: '',
  hiringType: HiringType.rehire,
  endCurrent: false,
  companyId: '',
  payGroupId: '',
  leaveGroupId: '',
  copyInfo: true,
  copyPersonal: true,
  copyIdentity: true,
  copyAddress: true,
  copyCareer: true,
  copyCalendar: true,
  copyLocation: true,
  copyAttendanceGroup: true,
  copyShiftRole: true,
  copyPayment: true,
  copyFund: true,
  copyFamily: true,
  copyEmergency: true,
  copyEducation: true,
  copyCert: true
}

const { Step } = Steps

type StepKey = 'select' | 'copy' | 'complete'

interface IStep {
  key: StepKey
  title: string
  nextButton?: string
  backButton?: string
}

const STEPS: IStep[] = [
  {
    key: 'select',
    title: 'Select employee'
  },
  {
    key: 'copy',
    title: 'Copy information',
    nextButton: 'Execute'
  },
  {
    key: 'complete',
    title: 'Done',
    nextButton: 'Close'
  }
]

export const RehireEmployeeDrawer: FC<RehireEmployeeDrawerProps> = ({ visible, onClose }) => {
  const history = useHistory()
  const [formData, setFormData] = useState<ICopyEmployee>(EMPTY_FORM_DATA)
  const [step, setStep] = useState(0)
  const [nextLoading, setNextLoading] = useState(false)
  const [errors, setErrors] = useState<Errors>()
  const employee = useSelector((state: StoreState) => state.employee.employees.entities[formData.employeeId])
  const [newEmId, setNewEmId] = useState<string>()

  const [employmentConfigs] = useHiddenEmploymentConfigs()
  const hiddenEmployments = employmentConfigs.map(ec => ec.code)
  const hideDepartment = hiddenEmployments.includes(EmploymentConfig.department)
  const hideDivision = hiddenEmployments.includes(EmploymentConfig.division)
  const hideSection = hiddenEmployments.includes(EmploymentConfig.section)
  const hideGrp = hiddenEmployments.includes(EmploymentConfig.grp)
  const hideCategory = hiddenEmployments.includes(EmploymentConfig.category)
  const hideTeam = hiddenEmployments.includes(EmploymentConfig.team)
  const hideJob = hiddenEmployments.includes(EmploymentConfig.job)
  const hideManager = hiddenEmployments.includes(EmploymentConfig.manager)
  const hideManagerSecondary = hiddenEmployments.includes(EmploymentConfig.managerSecondary)
  const hideOffice = hiddenEmployments.includes(EmploymentConfig.office)
  const hideCostCentre = hiddenEmployments.includes(EmploymentConfig.costCentre)
  const hideEmploymentType = hiddenEmployments.includes(EmploymentConfig.employmentType)
  const hideOtClass = hiddenEmployments.includes(EmploymentConfig.otClass)

  useEffect(() => {
    setStep(0)
    setErrors(undefined)
    setFormData(EMPTY_FORM_DATA)
  }, [visible])

  useEffect(() => {
    dispatch(fetchEmpKeyvalues('country', undefined, undefined, { strategy: 'when-empty' }))
  }, [])

  useEffect(() => {
    dispatch(fetchEmployee(formData.employeeId))
  }, [formData.employeeId])

  useEffect(() => {
    if (employee && employee.id !== emptyGuid) {
      dispatch(fetchEmCompany(employee.id, employee.emCompanyId))
      if (!hideDepartment) dispatch(fetchEmDepartment(employee.id, employee.emDepartmentId))
      if (!hideDivision) dispatch(fetchEmDivision(employee.id, employee.emDivisionId))
      if (!hideSection) dispatch(fetchEmSection(employee.id, employee.emSectionId))
      if (!hideGrp) dispatch(fetchEmGrp(employee.id, employee.emGrpId))
      if (!hideCategory) dispatch(fetchEmCategory(employee.id, employee.emCategoryId))
      if (!hideTeam) dispatch(fetchEmTeam(employee.id, employee.emTeamId))
      if (!hideJob) dispatch(fetchEmJob(employee.id, employee.emJobId))
      if (!hideManager) dispatch(fetchEmManager(employee.id, employee.emManagerId))
      if (!hideManagerSecondary) dispatch(fetchEmManagerSecondary(employee.id, employee.emManagerSecondaryId))
      if (!hideOffice) dispatch(fetchEmOffice(employee.id, employee.emOfficeId))
      if (!hideCostCentre) dispatch(fetchEmCostCentre(employee.id, employee.emCostCentreId))
      if (!hideEmploymentType) dispatch(fetchEmEmploymentType(employee.id, employee.emEmploymentTypeId))
      if (!hideOtClass) dispatch(fetchEmOtClass(employee.id, employee.emOtClassId))
      dispatch(fetchEmIdentity(employee.id, employee.emIdentityId))
      dispatch(fetchEmCalendar(employee.id, employee.emCalendarId))
      dispatch(fetchEmLocation(employee.id, employee.emLocationId))
      dispatch(fetchEmAttendanceGroup(employee.id, employee.emAttendanceGroupId))
      dispatch(fetchEmShiftRole(employee.id, employee.emShiftRoleId))
    }
  }, [
    employee,
    hideDepartment,
    hideDivision,
    hideSection,
    hideGrp,
    hideCategory,
    hideTeam,
    hideJob,
    hideManager,
    hideManagerSecondary,
    hideOffice,
    hideCostCentre,
    hideEmploymentType,
    hideOtClass
  ])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    if (updates.companyId) {
      updates.payGroupId = ''
    }

    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const validateEntry = useCallback(async () => {
    const errors: Errors = {}
    if (!formData.employeeId) {
      errors['employeeId'] = ['Employee is required']
    }
    if (!formData.employeeNo) {
      errors['employeeNo'] = ['New employee no. is required']
    }
    if (!formData.hireDate) {
      errors['hireDate'] = ['Hire date is required']
    }
    if (!formData.companyId) {
      errors['companyId'] = ['Company is required']
    }

    setErrors(errors)
    if (Object.keys(errors).length > 0) {
      return false
    }

    try {
      setNextLoading(true)
      var result = await dispatch(copyEmployeeValidate(formData))

      if (result?.errors) {
        setErrors(result.errors)
      }
      return Object.keys(result?.errors || {}).length === 0
    } finally {
      setNextLoading(false)
    }
  }, [formData])

  const handlePrev = useCallback(() => {
    setStep(step => step - 1)
  }, [])

  const handleCloseDrawer = useCallback(
    (success?: boolean) => {
      typeof onClose === 'function' && onClose(success)
    },
    [onClose]
  )

  const getEmployee = useCallback(async () => {
    try {
      setNextLoading(true)
      await Promise.all([
        dispatch(fetchEmployee(formData.employeeId)),
        dispatch(fetchEmAddressMain(formData.employeeId)),
        dispatch(fetchEmFamilies(formData.employeeId)),
        dispatch(fetchEmEducations(formData.employeeId)),
        dispatch(fetchEmCerts(formData.employeeId))
      ])
      setStep(step => step + 1)
    } finally {
      setNextLoading(false)
    }
  }, [formData.employeeId])

  const executeCopy = useCallback(async () => {
    try {
      setNextLoading(true)
      const result: ActionResult<{ id: string }> | undefined = await dispatch(
        copyEmployee({ ...formData, hiringType: HiringType.rehire })
      )

      if (result?.result) {
        setNewEmId(result.result.id)
      }

      if (!result?.errors) {
        setStep(step => step + 1)
      }
    } finally {
      setNextLoading(false)
    }
  }, [formData])

  const handleNext = useCallback(async () => {
    setErrors(undefined)
    if (STEPS[step].key === 'select') {
      if (await validateEntry()) {
        await getEmployee()
      }
    } else if (STEPS[step].key === 'copy') {
      await executeCopy()
    } else if (STEPS[step].key === 'complete') {
      handleCloseDrawer(true)
    }
  }, [step, validateEntry, getEmployee, executeCopy, handleCloseDrawer])

  const handleRedirect = useCallback(() => {
    if (newEmId) history.push(EMP_ROUTES.employee.replace(':id', newEmId))
  }, [history, newEmId])

  return (
    <DrawerForm
      open={visible}
      title="Rehire employee"
      width="60%"
      onClose={() => handleCloseDrawer()}
      className="rehire-employee-drawer"
      footer={
        <Space className="rehire-employee-drawer__action-bar-buttons">
          {STEPS[step - 1] && step !== STEPS.length - 1 && (
            <Button onClick={handlePrev}>{STEPS[step].backButton || 'Back'}</Button>
          )}
          {STEPS[step].key === 'complete' && (
            <Button onClick={handleRedirect}>
              Open employee {formData?.employeeNo} - {employee?.fullName}
            </Button>
          )}
          {STEPS[step] && (
            <Button type="primary" onClick={handleNext} loading={nextLoading}>
              {STEPS[step].nextButton || 'Next'}
            </Button>
          )}
        </Space>
      }
    >
      <Steps progressDot size="small" current={step}>
        {STEPS.map(s => (
          <Step key={s.key} title={s.title} />
        ))}
      </Steps>
      <RehireEntry
        visible={STEPS[step].key === 'select'}
        data={formData}
        onChange={handleFormDataChange}
        errors={errors}
      />
      <CopyEm
        visible={STEPS[step].key === 'copy'}
        data={formData}
        onChange={handleFormDataChange}
        Display={<RehireDisplay data={formData} />}
      />
      <CopyEmComplete visible={STEPS[step].key === 'complete'} data={formData} message="Rehire completed" />
    </DrawerForm>
  )
}

import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Card, Table, Link, ColumnsType, Tooltip, Space, Button } from '~/core-components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { useFirstInView } from '~/hooks/use-first-in-view'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { EmCertState } from '../../../types'
import { fetchEmCerts } from '../../../actions'
import { selectEmCerts } from '../../../selectors'
import { useEmCertRequests } from '~/features/employee/hooks'
import { MutateEmCertDrawer } from './MutateEmCertDrawer'
import { EmCertRequestsDrawer } from './EmCertRequest/EmCertRequestsDrawer'

interface EmCertsProps {
  employeeId?: string
}

type EmCertTable = EmCertState

interface DrawerState {
  visible: boolean
  employeeId?: string
  data?: EmCertState
}

interface RequestDrawerState {
  employeeId?: string
  visible: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }
const DEFAULT_REQUEST_DRAWER_STATE: RequestDrawerState = { visible: false }

const cardStyle: CSSProperties = { margin: 24 }
const cardBodyStyle: CSSProperties = { padding: 0 }
const btnPendingStyle: CSSProperties = {
  backgroundColor: '#ff8500',
  borderColor: '#ff8500',
  color: '#ffffff',
  marginRight: 30
}

export const EmCerts: FC<EmCertsProps> = ({ employeeId }: EmCertsProps) => {
  const { ref, inView } = useFirstInView<HTMLDivElement>({ threshold: 0.25 })
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const loading = useSelector((state: StoreState) => state.employee.emCertsLoading[employeeId || ''])
  const emCerts = useSelector(selectEmCerts)(employeeId)
  const canView = usePermissionGate(Permission.employeeCert)
  const canModify = usePermissionGate(Permission.employeeCert, PermissionAction.Modify)
  const [emCertRequests, emCertRequestLoading] = useEmCertRequests(employeeId || '', inView)
  const [requestDrawerState, setRequestDrawerState] = useState<RequestDrawerState>(DEFAULT_REQUEST_DRAWER_STATE)

  useEffect(() => {
    if (inView && employeeId) {
      dispatch(fetchEmCerts(employeeId))
    }
  }, [inView, employeeId])

  const handleAddCert = useCallback(() => {
    setDrawerState({ visible: true, employeeId, data: undefined })
  }, [employeeId, setDrawerState])

  const handleEditCert = useCallback(
    (emCert: EmCertState) => {
      setDrawerState({ visible: true, employeeId, data: emCert })
    },
    [employeeId, setDrawerState]
  )

  const handleCloseDrawer = useCallback(() => {
    setDrawerState(DEFAULT_DRAWER_STATE)
  }, [])

  const columns = useMemo(() => {
    let columns: ColumnsType<EmCertTable> = []

    columns.push(
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        width: 200
      },
      {
        title: 'Issuing organisation',
        key: 'issuedBy',
        dataIndex: 'issuedBy',
        width: 150
      },
      {
        title: 'Issue date',
        key: 'issueDate',
        dataIndex: 'issueDate',
        render: (value: string) => value && moment(value).format('DD MMM YYYY')
      },
      {
        title: 'Expiry date',
        key: 'expiryDate',
        dataIndex: 'expiryDate',
        render: (value: string) => value && moment(value).format('DD MMM YYYY')
      },
      {
        title: 'Notes',
        key: 'notes',
        dataIndex: 'notes',
        render: (value: string) =>
          value && (
            <Tooltip title={value}>
              <i className="fal fa-note" />
            </Tooltip>
          )
      },
      {
        key: 'action',
        align: 'right',
        width: 80,
        render: (value: string, record) =>
          canModify && (
            <Link size="small" onClick={() => handleEditCert(record)}>
              edit
            </Link>
          )
      }
    )

    return columns
  }, [canModify, handleEditCert])

  const handleOpenRequestDrawer = useCallback(() => {
    setRequestDrawerState({ employeeId, visible: true })
  }, [employeeId])

  const handleCloseRequestDrawer = useCallback(() => {
    setRequestDrawerState(DEFAULT_REQUEST_DRAWER_STATE)
  }, [])

  if (!canView) return null

  return (
    <div ref={ref}>
      <Card
        extra={
          employeeId &&
          canModify && (
            <Space>
              {emCertRequests.length > 0 && (
                <Button
                  style={btnPendingStyle}
                  size="small"
                  onClick={handleOpenRequestDrawer}
                  loading={emCertRequestLoading}
                >
                  {emCertRequests.length > 1 ? `${emCertRequests.length} pending requests` : 'pending request'}
                </Button>
              )}
              <Link onClick={handleAddCert}>add</Link>
            </Space>
          )
        }
        style={cardStyle}
        bodyStyle={cardBodyStyle}
        loading={loading}
      >
        <Table
          rowKey="id"
          dataSource={emCerts}
          pagination={false}
          columns={columns}
          loading={loading}
          className="em-cert__table"
        />
        {canModify && <MutateEmCertDrawer {...drawerState} onClose={handleCloseDrawer} />}
        {canModify && emCertRequests.length > 0 && (
          <EmCertRequestsDrawer {...requestDrawerState} onClose={handleCloseRequestDrawer} />
        )}
      </Card>
    </div>
  )
}

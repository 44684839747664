import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'

export const selectEmCerts = createSelector(
  (state: StoreState) => state.employee.emCerts,
  emCertsState =>
    memoize((employeeId?: string) => {
      if (!employeeId) return []

      return Object.values(emCertsState[employeeId]?.entities || {}).sort((a, b) => {
        const aIssueDate = new Date(a!.issueDate).getTime()
        const bIssueDate = new Date(b!.issueDate).getTime()
        const aExpiryDate = new Date(a!.expiryDate).getTime()
        const bExpiryDate = new Date(b!.expiryDate).getTime()

        if (bExpiryDate === 0 && aExpiryDate === 0) {
          return bIssueDate - aIssueDate
        } else {
          if (bExpiryDate === 0) {
            return 1
          } else if (aExpiryDate === 0) {
            return -1
          } else {
            return bExpiryDate - aExpiryDate
          }
        }
      })
    })
)

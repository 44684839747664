import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { useIsMountedRef } from '~/hooks/use-is-mounted-ref'
import { Button, Card, ColumnsType, Dropdown, Form, SecondaryText, Space, Table, Tooltip } from '~/core-components'
import { Person, InfoTooltip, SearchInput, Row, Col } from '~/components'
import { useSysOptions } from '~/features/employee'
import {
  Screen,
  ViewCriteriaSimple,
  ViewCriteria,
  updateViewCriteria,
  useFirstView,
  useViewSchema,
  ViewSelectionButton
} from '~/features/selection'
import { usePermissionGate } from '~/features/iam/hooks'
import { EMP_ROUTES } from '~/routes/routes'
import { LveProrationType, LveSysLeaveTypeType, Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { AddGrantLeaveDrawer } from './components/AddGrantLeaveDrawer'
import { EditLeaveEntitlementDrawer } from './components/EditLeaveEntitlementDrawer'
import { fetchLeaveEntitlementView } from '../../actions'
import { selectLeaveEntitlementsView } from '../../selectors'
import { apiGetLeaveEntitlementExcel } from '../../api/leave-entitlement.api'
import {
  showError,
  formatYearMonth,
  formatMonth,
  formatMoney,
  getFileTimestamp,
  formatDateRange,
  formatNumberUnit,
  UnitType,
  downloadWithDom,
  formatDate,
  getBaseUrl
} from '~/utils'
import { useLeaveTypesDict, useSysLeaveTypes } from '../../hooks'
import { LeaveEntitlementRowState } from '../../types'
import { refetchLeaveEntitlementsView } from '../../reducers'
import { MutateLeaveRecordDrawer } from '../LeaveRecord/components/MutateLeaveRecordDrawer'
import { LeaveClosingButton } from '../LeaveClosing/LeaveClosingButton'
import { LeEarnedInfo } from '../LeEarned/LeEarnedInfo'
import { SelectLeavePeriodYears } from './components/SelectLeavePeriodYears'
import { LeaveRefreshButton } from './components/LeaveRefreshButton'
import { LeaveTypeName } from './components/LeaveTypeName'
import { LeaveGrantBulkDrawer } from '../LeaveGrantBulk/LeaveGrantBulkDrawer'
import './LeaveEntitlement.less'

interface LeaveEntitlementProps {}

interface AddGrantLeaveDrawerState {
  visible: boolean
}

interface LeaveGrantBulkDrawerState {
  visible: boolean
}

interface EntitlementDrawerState {
  visible: boolean
  entitlement?: LeaveEntitlementRowState
}

interface RecordDrawerState {
  visible: boolean
  id?: string
  editing?: boolean
}

const nameColWidth = 250
const defaultColWidth = 150
const paginationStyle: CSSProperties = { marginRight: 20 }

const SCREEN_CODE: Screen = 'leave_entitlement'
const DEFAULT_ADD_GRANT_LEAVE_DRAWER_STATE: AddGrantLeaveDrawerState = { visible: false }
const DEFAULT_LEAVE_GRANT_BULK_DRAWER_STATE: LeaveGrantBulkDrawerState = { visible: false }
const DEFAULT_DRAWER_STATE: EntitlementDrawerState = { visible: false }
const DEFAULT_RECORD_DRAWER_STATE: RecordDrawerState = { visible: false, editing: false }
const PAGE_SIZE_OPTIONS = ['20', '50', '100']
const baseUrl = getBaseUrl('/filestore')

export const LeaveEntitlement: FC<LeaveEntitlementProps> = () => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [search, setSearch] = useState<string>('')
  const [periodYear, setPeriodYear] = useState<number>()

  const [leaveTypes] = useLeaveTypesDict()
  const [sysLeaveTypes] = useSysLeaveTypes()
  const selection = useSelector((state: StoreState) => state.selection.sysSelectionFields[SCREEN_CODE])
  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const [schema] = useViewSchema(SCREEN_CODE, viewId)
  const dataLoading = useSelector((state: StoreState) => state.leave.leaveEntitlementsViewLoading)
  const data = useSelector(selectLeaveEntitlementsView)(viewId)
  const isMountedRef = useIsMountedRef()
  const [downloading, setDownloading] = useState(false)
  const [leaveUnits] = useSysOptions('lve_unit')
  const canModify = usePermissionGate(Permission.lveEntitlement, PermissionAction.Modify)
  const canModifyLveRecord = usePermissionGate(Permission.lveRecord, PermissionAction.Modify)
  const canViewEmployee = usePermissionGate(Permission.employee)
  const [addGrantDrawerState, setAddGrantDrawerState] = useState<AddGrantLeaveDrawerState>(
    DEFAULT_ADD_GRANT_LEAVE_DRAWER_STATE
  )
  const [leaveGrantBulkDrawerState, setLeaveGrantBulkDrawerState] = useState<LeaveGrantBulkDrawerState>(
    DEFAULT_LEAVE_GRANT_BULK_DRAWER_STATE
  )
  const leaveExpanded = useSelector((state: StoreState) => state.leave.leaveExpanded)
  const [editEntDrawerState, setEntDrawerState] = useState<EntitlementDrawerState>(DEFAULT_DRAWER_STATE)
  const [recordDrawerState, setRecordDrawerState] = useState<RecordDrawerState>(DEFAULT_RECORD_DRAWER_STATE)
  const refetch = useSelector((state: StoreState) => state.leave.leaveEntitlementsViewRefetch)

  useEffect(() => {
    if (viewId) {
      dispatch(
        fetchLeaveEntitlementView(viewId, periodYear, { offset: pageSize * (page - 1), limit: pageSize }, search)
      )
    }
  }, [viewId, periodYear, page, pageSize, search, refetch])

  const handleEditEntitlement = useCallback(
    (entitlement: LeaveEntitlementRowState) => {
      setEntDrawerState({ visible: true, entitlement })
    },
    [setEntDrawerState]
  )

  const handleCloseEntitlementDrawer = useCallback((success: boolean) => {
    setEntDrawerState(DEFAULT_DRAWER_STATE)
    if (success) dispatch(refetchLeaveEntitlementsView())
  }, [])

  const isGrantType = useCallback(
    (leaveTypeId: string) => {
      return sysLeaveTypes[leaveTypes[leaveTypeId]?.sysLeaveTypeCode || '']?.type === LveSysLeaveTypeType.grant
    },
    [sysLeaveTypes, leaveTypes]
  )

  const tableWidth =
    (schema?.selection.reduce(
      (sum, f) => (sum += selection?.entities[f.selectionFieldId]?.width || defaultColWidth),
      0
    ) || 0) + nameColWidth

  let columns: ColumnsType<LeaveEntitlementRowState> = [
    {
      title: 'Name',
      key: 'employeeId',
      dataIndex: 'employeeId',
      fixed: 'left',
      className: 'first-col',
      width: nameColWidth,
      onCell: (record, index) => {
        const prevId = data.data[(index as number) - 1]?.employeeId
        if (prevId === record.employeeId) {
          return { rowSpan: 0 }
        } else {
          const nextId = data.data[(index as number) + 1]?.employeeId
          if (nextId === record.employeeId) {
            return { rowSpan: record.rowSpan }
          }
        }
        return {}
      },
      render: (_, record) => (
        <Person
          name={record.employeeName}
          description={!leaveExpanded && record.description}
          photo={record.photoId && `${baseUrl}/file/${record.photoId}/thumbnailphoto/${leaveExpanded ? 18 : 36}`}
          // photo={
          //   record.employeeId &&
          //   `/employee/employee/${record.employeeId}/avatar/${leaveExpanded ? 18 : 36}?photo_id=${record.photoId}`
          // }
          path={canViewEmployee ? EMP_ROUTES.employee.replace(':id', record.employeeId) : undefined}
          size={leaveExpanded ? 18 : 36}
        />
      )
    }
  ]

  if (schema) {
    // Configurable employee columns
    columns.push(
      ...schema?.selection.map(f => {
        var field = selection?.entities[f.selectionFieldId]
        return {
          title: field?.description,
          key: field?.fieldName,
          dataIndex: field?.fieldName,
          width: field?.width || defaultColWidth,
          onCell: (record: LeaveEntitlementRowState, index?: number) => {
            const prevId = data.data[(index as number) - 1]?.employeeId
            if (prevId === record.employeeId) {
              return { rowSpan: 0 }
            } else {
              const nextId = data.data[(index as number) + 1]?.employeeId
              if (nextId === record.employeeId) {
                return { rowSpan: record.rowSpan }
              }
            }
            return {}
          },
          render: (value: string | number) => {
            var display = value
            if (field?.format === 'date' && value) {
              display = moment(value).format('DD MMM YYYY')
            }
            if (field?.format === 'yearmonth' && value) {
              display = formatYearMonth(value as string)
            }
            if (field?.format === 'month' && value) {
              display = formatMonth(value as number)
            }
            if (field?.format === 'money' && value) {
              display = formatMoney(value as number)
            }
            return display
          }
        }
      })
    )
  }

  // Fixed leave entitlement columns
  columns.push(
    ...([
      {
        title: 'Leave type',
        key: 'leaveTypeId',
        dataIndex: 'leaveTypeId',
        width: 230,
        render: (value: string, record: LeaveEntitlementRowState) => (
          <LeaveTypeName
            id={value}
            description={!leaveExpanded ? formatDateRange(record.periodStartDate, record.periodEndDate) : undefined}
            path={canModify ? () => handleEditEntitlement(record) : undefined}
          >
            {!record.isActivePeriod && (
              <Tooltip title="Past period">
                <i className="fal fa-clock-rotate-left lt-past" />
              </Tooltip>
            )}
          </LeaveTypeName>
        )
      },
      {
        title: 'Carry forward',
        key: 'cf',
        dataIndex: 'cf',
        align: 'right',
        width: 100,
        render: (value: number, record) => {
          const hasExpiry = !!record.cfExpiryDate
          let hasExpired = false
          if (hasExpiry) {
            hasExpired = moment(record.cfExpiryDate).isBefore(moment())
          }

          return (
            <span>
              {value}
              {hasExpiry && !hasExpired && (
                <InfoTooltip
                  className="cell-icon"
                  title={`${
                    record.cf !== record.cfExpiry
                      ? `${formatNumberUnit(record.cfExpiry, record.unit as UnitType)} expire`
                      : 'Expire'
                  } on ${moment(record.cfExpiryDate).format('DD MMM YYYY')}`}
                />
              )}
            </span>
          )
        }
      },
      {
        title: 'Earned',
        key: 'earned',
        dataIndex: 'earned',
        align: 'right',
        width: 100,
        render: (value: number, record) => {
          let tooltipMessages: string[] = []

          const isGrantLeave = isGrantType(record.leaveTypeId)
          const showFYE = !isGrantLeave && record.fullEntitlement !== value && !!record.fullEntitlement
          const isProrated = leaveTypes[record.leaveTypeId]?.prorationType !== LveProrationType.none
          if (showFYE) {
            tooltipMessages.push(
              `Full year entitlement: ${formatNumberUnit(record.fullEntitlement, record.entitlementUnit as UnitType)}`
            )
          }
          if (record.earnedAdj !== 0 && !!record.earnedAdj) {
            tooltipMessages.push(`Adjustment: ${formatNumberUnit(record.earnedAdj, record.unit as UnitType)}`)
          }

          return (
            <span>
              {value}
              {tooltipMessages.length > 0 && (
                <LeEarnedInfo
                  tooltip={tooltipMessages.join(' ')}
                  periodCode={isProrated && showFYE ? record.periodCode : undefined}
                  employeeId={isProrated && showFYE ? record.employeeId : undefined}
                  leaveTypeId={isProrated && showFYE ? record.leaveTypeId : undefined}
                />
              )}
              {isGrantLeave && (
                <InfoTooltip
                  icon="fa-calendar"
                  className="cell-icon"
                  title={
                    record.periodStartDate === record.periodEndDate
                      ? `Valid on ${formatDate(record.periodStartDate)}`
                      : `Valid from ${formatDateRange(record.periodStartDate, record.periodEndDate)}`
                  }
                />
              )}
            </span>
          )
        }
      },
      {
        title: 'Taken',
        key: 'taken',
        dataIndex: 'taken',
        align: 'right',
        width: 100,
        render: (value: number, record) => {
          const totalTaken = record.cfTaken + record.taken + record.takenAdj

          let tooltipText: { label: string; value: string }[] = []
          if (!!record.planned) {
            tooltipText.push({
              label: 'Used:',
              value: formatNumberUnit(totalTaken - record.planned, record.unit as UnitType)
            })
            tooltipText.push({ label: 'Planned:', value: formatNumberUnit(record.planned, record.unit as UnitType) })
          }

          return (
            <span>
              {totalTaken}
              {tooltipText.length > 0 && (
                <InfoTooltip
                  className="cell-icon"
                  title={
                    <>
                      {tooltipText.map(t => (
                        <Row key={t.label}>
                          <Col flex="1" className="mr8">
                            {t.label}
                          </Col>
                          <Col>{t.value}</Col>
                        </Row>
                      ))}
                    </>
                  }
                />
              )}
            </span>
          )
        }
      },
      {
        title: 'Pending',
        key: 'pending',
        dataIndex: 'pending',
        align: 'right',
        width: 100,
        render: (value: number, record) => record.pending
      },
      {
        title: 'Expired',
        key: 'cfForfeited',
        dataIndex: 'cfForfeited',
        align: 'right',
        width: 100,
        render: (value: number) => value
      },
      {
        title: 'Balance',
        key: 'balance',
        dataIndex: 'balance',
        align: 'right',
        width: 100,
        render: (value: number, record) => {
          let toolTipMessages: string[] = []

          if (!!record.poolTaken) {
            toolTipMessages.push(
              `${record.poolTaken} ${leaveUnits[record.unit]?.value.toLowerCase()} taken from other leave type(s)`
            )
          }
          if (record.encashed !== 0) {
            toolTipMessages.push(
              `Encashed (upon resignation) ${record.encashed} ${leaveUnits[record.unit]?.value.toLowerCase()}`
            )
          }
          if (record.encashedEnd !== 0) {
            toolTipMessages.push(
              `Encashed (period end) ${record.encashedEnd} ${leaveUnits[record.unit]?.value.toLowerCase()}`
            )
          }

          return (
            <span>
              {value}
              {toolTipMessages.length > 0 && <InfoTooltip className="cell-icon" title={toolTipMessages.join('. ')} />}
            </span>
          )
        }
      },
      {
        title: (
          <>
            Planned
            <SecondaryText block size="small">
              future period
            </SecondaryText>
          </>
        ),
        key: 'futurePlanned',
        dataIndex: 'futurePlanned',
        align: 'right',
        width: 100
      },
      {
        title: 'Unit',
        key: 'unit',
        dataIndex: 'unit',
        width: 100,
        render: (value: string) => leaveUnits[value]?.value?.toLowerCase() || ''
      }
    ] as ColumnsType<LeaveEntitlementRowState>)
  )

  const handleCloseSelectionDrawer = useCallback((changed?: boolean) => {
    if (changed) dispatch(refetchLeaveEntitlementsView())
  }, [])

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        setPage(1)
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchLeaveEntitlementsView())
      }
    },
    [viewId]
  )

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const handleSearch = useCallback((value: string) => {
    setPage(1)
    setSearch(value)
  }, [])

  const handlePeriodChange = useCallback(periodYear => {
    setPage(1)
    setPeriodYear(periodYear)
  }, [])

  const handleDownloadClick = useCallback(async () => {
    if (viewId && periodYear) {
      try {
        setDownloading(true)
        const { status, result, errors, message, errorData } = await apiGetLeaveEntitlementExcel(viewId, periodYear, {
          search
        })

        if (status) {
          const fileName = `leave_entitlement_${periodYear}_${getFileTimestamp()}.xlsx`
          downloadWithDom(result, fileName)
        } else {
          console.error('Error while downloading', errors)
          showError(message, errorData)
        }
      } finally {
        if (isMountedRef.current) setDownloading(false)
      }
    }
  }, [viewId, periodYear, search, isMountedRef])

  const handleGrantLeaveClick = useCallback(async () => {
    setAddGrantDrawerState({ visible: true })
  }, [])

  const handleAddGrantLeaveDrawerClose = useCallback((success: boolean) => {
    setAddGrantDrawerState(DEFAULT_ADD_GRANT_LEAVE_DRAWER_STATE)
    if (success) dispatch(refetchLeaveEntitlementsView())
  }, [])

  const handleAddLeaveRecord = useCallback(() => {
    setRecordDrawerState({ visible: true, editing: true })
  }, [setRecordDrawerState])

  const handleCloseRecordDrawer = useCallback((action: 'saved' | 'cancelled') => {
    setRecordDrawerState(DEFAULT_RECORD_DRAWER_STATE)
    if (action === 'saved') dispatch(refetchLeaveEntitlementsView())
  }, [])

  const handleLeaveGrantBulkClick = useCallback(async () => {
    setLeaveGrantBulkDrawerState({ visible: true })
  }, [])

  const handleLeaveGrantBulkDrawerClose = useCallback((success: boolean) => {
    setLeaveGrantBulkDrawerState(DEFAULT_LEAVE_GRANT_BULK_DRAWER_STATE)
    if (success) dispatch(refetchLeaveEntitlementsView())
  }, [])

  return (
    <div className="leave-entitlement">
      <div className="leave-entitlement__body">
        <div className="leave-entitlement__action-bar">
          <Space>
            <Form.Item label="">
              <SelectLeavePeriodYears onChange={handlePeriodChange} />
            </Form.Item>
            <Form.Item label="">
              <SearchInput onSearch={handleSearch} />
            </Form.Item>
          </Space>
          <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
          <Space align="start">
            {canModifyLveRecord && <Button onClick={handleAddLeaveRecord}>Add leave record</Button>}
            {canModify && (
              <Dropdown.Button
                menu={{
                  items: [{ key: 'bulk', label: 'Bulk grant leave', onClick: handleLeaveGrantBulkClick }]
                }}
                icon={<i className="fa-light fa-angle-down" />}
                onClick={handleGrantLeaveClick}
                buttonsRender={([leftButton, rightButton]) => [
                  React.cloneElement(leftButton as React.ReactElement),
                  rightButton
                ]}
              >
                Grant leave
              </Dropdown.Button>
            )}
            <LeaveRefreshButton />
            <Tooltip title="Download excel">
              <Button
                icon={<i className="fal fa-arrow-down-to-bracket" />}
                onClick={handleDownloadClick}
                loading={downloading}
              />
            </Tooltip>
            <LeaveClosingButton visible={canModify} />
            <ViewSelectionButton
              screenCode={SCREEN_CODE}
              viewId={viewId}
              title="Configure leave entitlement columns"
              onClose={handleCloseSelectionDrawer}
            />
          </Space>
        </div>
        <Card fitParent table>
          <Table
            rowKey="id"
            dataSource={data?.data}
            columns={columns}
            fitParent
            loading={dataLoading || viewLoading}
            scroll={{ x: tableWidth, y: 1000 }}
            pagination={{
              total: data?.count,
              current: page,
              pageSize,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
              showSizeChanger: true,
              onChange: handlePaginationChange,
              style: paginationStyle
            }}
          />
        </Card>
      </div>
      {canModify && (
        <>
          <AddGrantLeaveDrawer {...addGrantDrawerState} onClose={handleAddGrantLeaveDrawerClose} />
          <LeaveGrantBulkDrawer {...leaveGrantBulkDrawerState} onClose={handleLeaveGrantBulkDrawerClose} />
          <EditLeaveEntitlementDrawer {...editEntDrawerState} onClose={handleCloseEntitlementDrawer} />
        </>
      )}
      {canModifyLveRecord && <MutateLeaveRecordDrawer {...recordDrawerState} onClose={handleCloseRecordDrawer} />}
    </div>
  )
}

import React, { CSSProperties, FC, memo, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { Drawer, Form, SecondaryText, Space, Tag, UploadFile } from '~/core-components'
import { Col, Row, UploadFileSelectorAuth } from '~/components'
import { EmCertRequestState } from '../../../../types'
import { dispatch } from '~/stores/store'
import { ActionResult } from '~/types/store'
import { approveEmCertRequest, rejectEmCertRequest } from '~/features/employee/actions'
import { getBaseUrl } from '~/utils'
import { EmRequestApprovalBtn } from '../EmRequestApprovalBtn'

export interface EmCertRequestApprovalProps {
  data?: EmCertRequestState
  visible: boolean
  onClose: () => void
}

const PendingTag = memo(() => <Tag type="secondary">pending</Tag>)

const currenTextStyle: CSSProperties = { fontSize: 12 }

const EMPTY_ATTACHMENT: UploadFile = {
  uid: '',
  name: '',
  size: 0,
  type: ''
}

const baseUrl = getBaseUrl('/employee')

export const EmCertRequestApprovalDrawer: FC<EmCertRequestApprovalProps> = ({ data, visible, onClose }) => {
  const [attachment, setAttachment] = useState<UploadFile>()

  const isNew = data?.recordStatus === 'new_request'
  const isDiffName = !isNew && data?.name !== data?.nameNew
  const isDiffIssuedBy = !isNew && data?.issuedBy !== data?.issuedByNew
  const isDiffIssueDate = !isNew && data?.issueDate !== data?.issueDateNew
  const isDiffExpiryDate = !isNew && data?.expiryDate !== data?.expiryDateNew
  const isDiffCredentialUrl = !isNew && data?.credentialUrl !== data?.credentialUrlNew
  const isDiffCredentialId = !isNew && data?.credentialId !== data?.credentialIdNew

  useEffect(() => {
    if (data && data.attachmentId) {
      setAttachment({
        uid: data.attachmentId,
        name: data.attachmentName || '',
        size: data.attachmentSize || 0,
        type: data.attachmentFormat || '',
        url: `${baseUrl}/employee/request/cert/${data.id}/downloadfile`
      })
    }
  }, [data])

  const handleApproval = useCallback(
    async (action: 'approve' | 'reject') => {
      let result: ActionResult | undefined

      if (data) {
        if (action === 'approve') {
          result = await dispatch(approveEmCertRequest(data.employeeId, data.id))
        } else if (action === 'reject') {
          result = await dispatch(rejectEmCertRequest(data.employeeId, data.id))
        }
      }

      if (!result?.errors) {
        typeof onClose === 'function' && onClose()
      }
    },
    [onClose, data]
  )

  const handleCloseDrawer = useCallback(() => {
    setAttachment(EMPTY_ATTACHMENT)
    typeof onClose === 'function' && onClose()
  }, [onClose])

  const handleFileChange = useCallback(() => {}, [])

  if (!data) return null

  return (
    <Drawer
      open={visible}
      title={<Space>License & certificate request approval {isNew && <Tag type="secondary">new (pending)</Tag>}</Space>}
      onClose={() => handleCloseDrawer()}
      width={600}
      className="em-cert-request-approval-drawer"
      footer={<EmRequestApprovalBtn onApproval={handleApproval} />}
    >
      <Form id="form-em-cert-request-approval">
        <Row>
          <Col span={24}>
            <Form.Item label={<Space>Name {isDiffName && <PendingTag />}</Space>}>
              <div>{data.nameNew || '-'}</div>
              {isDiffName && <SecondaryText style={currenTextStyle}>Current: {data.name || '-'}</SecondaryText>}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label={<Space>Issuing organisation {isDiffIssuedBy && <PendingTag />}</Space>}>
              <div>{data.issuedByNew || '-'}</div>
              {isDiffIssuedBy && <SecondaryText style={currenTextStyle}>Current: {data.issuedBy || '-'}</SecondaryText>}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label={<Space>Issue date {isDiffIssueDate && <PendingTag />}</Space>}>
              <div>{data.issueDateNew ? moment(data.issueDateNew).format('DD MMM YYYY') : '-'}</div>
              {isDiffIssueDate && (
                <SecondaryText style={currenTextStyle}>
                  Current: {data.issueDate ? moment(data.issueDate).format('DD MMM YYYY') : '-'}
                </SecondaryText>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<Space>Expiry date {isDiffExpiryDate && <PendingTag />}</Space>}>
              <div>{data.expiryDateNew ? moment(data.expiryDateNew).format('DD MMM YYYY') : '-'}</div>
              {isDiffExpiryDate && (
                <SecondaryText style={currenTextStyle}>
                  Current: {data.expiryDate ? moment(data.expiryDate).format('DD MMM YYYY') : '-'}
                </SecondaryText>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label={<Space>Credential ID {isDiffCredentialId && <PendingTag />}</Space>}>
              <div>{data.credentialIdNew || '-'}</div>
              {isDiffCredentialId && (
                <SecondaryText style={currenTextStyle}>Current: {data.credentialId || '-'}</SecondaryText>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label={<Space>Credential URL {isDiffCredentialUrl && <PendingTag />}</Space>}>
              <div>{data.credentialUrlNew || '-'}</div>
              {isDiffCredentialUrl && (
                <SecondaryText style={currenTextStyle}>Current: {data.credentialUrl || '-'}</SecondaryText>
              )}
            </Form.Item>
          </Col>
        </Row>
        {attachment?.uid && (
          <Row>
            <Col span={24}>
              <Form.Item label="Attachment">
                <UploadFileSelectorAuth
                  multiple={false}
                  accept=".gif,.png,.jpeg,.jpg"
                  fileList={attachment ? [attachment] : []}
                  listType="picture-card"
                  disabled={true}
                  onChange={handleFileChange}
                  withCache={false}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Drawer>
  )
}

import React, { FC, HTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { upperCase } from 'lodash'
import { Skeleton, Link, SecondaryText } from '~/core-components'
import { EditableTable, EditableColumnsType, InfoTooltip, ErrorDisplay } from '~/components'
import { dispatch } from '~/stores/store'
import { newGuid } from '~/utils'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { emptyGuid } from '~/constants'
import { A8bFormState, A8bFormDtlState } from '../../../types'
import { addA8bDraft, deleteA8bDraft, fetchA8bForm, updateA8bDraft } from '../../../actions'
import { selectA8bForm } from '../../../selectors'
import { setA8bFormRefetch, removeA8bForm, setIr8aFormRefetch } from '../../../reducers'
import { useYtdSubmission } from '../../../hooks'
import { YtdEmptyForm } from '../YtdEmptyForm/YtdEmptyForm'
import './A8bForm.less'

interface A8bFormProps extends HTMLAttributes<HTMLDivElement> {
  ytdYear: string
  employerTaxNo: string
  employeeTaxNo: string
  ytdSubmissionId: string
  readOnly?: boolean
  onFormChange: (hasChange: boolean) => void

  // Trigger action from parent component
  triggerUpdate: number
  triggerDelete: number
  triggerDiscard: number
}

export type A8bSectionTable = A8bFormDtlState

const DATE_DISPLAY = 'DD MMM YYYY'

const EMPTY_FORM_DATA: A8bFormState = {
  id: emptyGuid,
  ytdSubmissionId: '',
  employeeTaxNo: '',
  companyName: '',
  companyIdNo: '',
  companyIdType: '',
  detailList: []
}

const planTypeOptions = [
  {
    value: 'esop',
    displayValue: 'ESOP'
  },
  {
    value: 'esow',
    displayValue: 'ESOW'
  }
]

export const A8bForm: FC<A8bFormProps> = ({
  ytdYear,
  employerTaxNo,
  employeeTaxNo,
  ytdSubmissionId,
  readOnly,
  onFormChange,
  triggerUpdate,
  triggerDelete,
  triggerDiscard,
  ...divProps
}) => {
  const [ytdSubmission] = useYtdSubmission(ytdSubmissionId, 'when-empty')
  const companyName = ytdSubmission?.companyName || ''

  const emptyFormData: A8bFormState = useMemo(
    () => ({
      ...EMPTY_FORM_DATA,
      ytdSubmissionId,
      employeeTaxNo,
      companyIdNo: employerTaxNo,
      companyName
    }),
    [ytdSubmissionId, employeeTaxNo, employerTaxNo, companyName]
  )

  const [formData, setFormData] = useState<A8bFormState>(emptyFormData)
  const [sectionA, setSectionA] = useState<A8bSectionTable[]>([])
  const [sectionB, setSectionB] = useState<A8bSectionTable[]>([])
  const [sectionC, setSectionC] = useState<A8bSectionTable[]>([])
  const [sectionD, setSectionD] = useState<A8bSectionTable[]>([])

  const a8bForm = useSelector(selectA8bForm)(ytdSubmissionId, employeeTaxNo)
  const [errors, setErrors] = useState<Errors>()
  const hasData = !!a8bForm
  const loading = useSelector((state: StoreState) => state.tax.a8bFormLoading || false)
  const refetch = useSelector((state: StoreState) => state.tax.a8bFormRefetch)
  const updating = useSelector((state: StoreState) => state.tax.a8bFormUpdating)

  useEffect(() => {
    dispatch(fetchA8bForm(ytdYear, employerTaxNo, employeeTaxNo, ytdSubmissionId))
  }, [refetch, ytdYear, employerTaxNo, employeeTaxNo, ytdSubmissionId])

  useEffect(() => {
    if (a8bForm) {
      setFormData({
        ...a8bForm,
        detailList: [...sectionA, ...sectionB, ...sectionC, ...sectionD]
      })
    } else {
      setFormData(emptyFormData)
    }
  }, [a8bForm, sectionA, sectionB, sectionC, sectionD, emptyFormData])

  useEffect(() => {
    if (a8bForm) {
      setSectionA(a8bForm.detailList.filter(detail => detail?.section === 'a'))
      setSectionB(a8bForm.detailList.filter(detail => detail?.section === 'b'))
      setSectionC(a8bForm.detailList.filter(detail => detail?.section === 'c'))
      setSectionD(a8bForm.detailList.filter(detail => detail?.section === 'd'))
    }
  }, [a8bForm])

  const handleA8bSectionAChange = useCallback(
    (data: A8bSectionTable[]) => {
      setSectionA(data)
      onFormChange(true)
    },
    [onFormChange]
  )
  const handleA8bSectionBChange = useCallback(
    (data: A8bSectionTable[]) => {
      setSectionB(data)
      onFormChange(true)
    },
    [onFormChange]
  )
  const handleA8bSectionCChange = useCallback(
    (data: A8bSectionTable[]) => {
      setSectionC(data)
      onFormChange(true)
    },
    [onFormChange]
  )
  const handleA8bSectionDChange = useCallback(
    (data: A8bSectionTable[]) => {
      setSectionD(data)
      onFormChange(true)
    },
    [onFormChange]
  )

  const handleCreateForm = useCallback(async () => {
    const result: ActionResult | undefined = await dispatch(
      addA8bDraft({ ytdSubmissionId, employeeTaxNos: [formData.employeeTaxNo] })
    )

    if (!result?.errors) {
      dispatch(setIr8aFormRefetch())
      dispatch(setA8bFormRefetch())
    }
  }, [ytdSubmissionId, formData.employeeTaxNo])

  const handleUpdateForm = useCallback(async () => {
    setErrors(undefined)

    const result = await dispatch(updateA8bDraft(formData))

    if (result?.errors) {
      setErrors(result.errors)
    } else {
      onFormChange(false)
    }
  }, [formData, onFormChange])

  const handleDeleteForm = useCallback(async () => {
    confirm({
      title: 'Delete',
      content: 'Do you want to delete this Appendix 8B?',
      onOk: async () => {
        const result: ActionResult | undefined = await dispatch(deleteA8bDraft(formData.id))

        if (!result?.errors) {
          dispatch(removeA8bForm(employeeTaxNo))
        }
      },
      width: 550,
      okText: 'Delete',
      okType: 'danger'
    })
  }, [formData, employeeTaxNo])

  const handleDiscardForm = useCallback(() => {
    if (a8bForm) {
      setSectionA(a8bForm.detailList.filter(detail => detail?.section === 'a'))
      setSectionB(a8bForm.detailList.filter(detail => detail?.section === 'b'))
      setSectionC(a8bForm.detailList.filter(detail => detail?.section === 'c'))
      setSectionD(a8bForm.detailList.filter(detail => detail?.section === 'd'))
    }
    setErrors(undefined)
  }, [a8bForm])

  useEffect(() => {
    if (triggerUpdate > 0) handleUpdateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerUpdate])

  useEffect(() => {
    if (triggerDelete > 0) handleDeleteForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerDelete])

  useEffect(() => {
    if (triggerDiscard > 0) handleDiscardForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerDiscard])

  // ### SECTION A
  const sectionAColumns: EditableColumnsType<A8bSectionTable> = [
    {
      title: 'No.',
      key: 'recordNo',
      dataIndex: 'recordNo',
      width: 20
    },
    {
      title: 'Company registration no./UEN',
      key: 'companyIdNo',
      dataIndex: 'companyIdNo',
      width: 45
    },
    {
      title: 'Name of company',
      key: 'companyName',
      dataIndex: 'companyName',
      width: 150
    },
    {
      title: 'Type of plan granted',
      key: 'planType',
      dataIndex: 'planType',
      editable: true,
      inputType: 'select',
      selectOptions: planTypeOptions,
      width: 70,
      render: val => upperCase(val)
    },
    {
      title: 'Date of grant',
      className: 'dateHeader',
      key: 'grantDate',
      dataIndex: 'grantDate',
      editable: true,
      inputType: 'date',
      width: 130,
      render: (value: string) => value && moment(value).format(DATE_DISPLAY)
    },
    {
      title: () => (
        <>
          Date of exercise
          <InfoTooltip
            title={`Date of exercise of ESOP or date of vesting of ESOW Plan (if applicable). If moratorium (i.e. selling restriction) is imposed, state the date the moratorium is lifted for the ESOP/ESOW Plans`}
          />
        </>
      ),
      className: 'dateHeader',
      key: 'exerciseDate',
      dataIndex: 'exerciseDate',
      editable: true,
      inputType: 'date',
      width: 130,
      render: (value: string) => value && moment(value).format(DATE_DISPLAY)
    },
    {
      title: () => (
        <>
          Exercise price
          <InfoTooltip title={`Exercise price of ESOP/or price paid/ payable per share under ESOW plan`} />
        </>
      ),
      key: 'exercisePrice',
      dataIndex: 'exercisePrice',
      editable: true,
      width: 90,
      align: 'right',
      inputType: 'numeric',
      render: (value: number) => value?.toLocaleString()
    },
    {
      title: () => (
        <>
          Value per share as at the date of exercise
          <InfoTooltip
            title={`Open market value per share as at the date of exercise of ESOP or date of vesting of ESOW plan`}
          />
        </>
      ),
      key: 'exericseValue',
      dataIndex: 'exerciseValue',
      editable: true,
      width: 90,
      align: 'right',
      inputType: 'numeric',
      render: (value: number) => value?.toLocaleString()
    },
    {
      title: 'Number of shares acquired',
      key: 'quantity',
      dataIndex: 'quantity',
      editable: true,
      width: 90,
      align: 'right',
      inputType: 'numeric',
      render: (value: number) => value?.toLocaleString()
    },
    {
      title: 'Gains from ESOP/ESOW plans',
      align: 'center',
      children: [
        {
          title: 'Gross amount not qualifying for tax exemption',
          key: 'gainNonExempt',
          dataIndex: 'gainNonExempt',
          width: 100,
          align: 'right',
          render: (value: number) => value?.toLocaleString()
        },
        {
          title: 'Gross amount of gains from ESOP/ESOW Plans',
          key: 'gainTotal',
          dataIndex: 'gainTotal',
          width: 100,
          align: 'right',
          render: (value: number) => value?.toLocaleString()
        }
      ]
    }
  ]

  if (!readOnly) {
    sectionAColumns.push({
      title: '',
      dataIndex: 'operation',
      width: 60,
      align: 'center',
      className: 'pr10',
      render: (_, record: { id: string }) =>
        sectionA ? <Link onClick={() => handleRowDeleteSectionA(record.id)}>remove</Link> : null
    })
  }

  const handleAddRowSectionA = useCallback(() => {
    const lastRow = sectionA[sectionA.length - 1]
    const id = newGuid()
    const newRow: A8bSectionTable = {
      ...lastRow,
      id,
      section: 'a',
      ytdSubmissionId: ytdSubmissionId,
      employeeTaxNo: employeeTaxNo,
      recordNo: lastRow ? lastRow.recordNo + 1 : 1,
      companyIdType: formData.companyIdType,
      companyIdNo: formData.companyIdNo,
      companyName: formData.companyName,
      planType: 'esop',
      grantDate: '',
      exerciseDate: '',
      exercisePrice: 0.0,
      grantValue: 0.0,
      exerciseValue: 0.0,
      quantity: 0.0,
      gainExemptB: 0.0,
      gainExemptC: 0.0,
      gainExemptD: 0.0,
      gainNonExempt: 0.0,
      gainTotal: 0.0
    }
    handleA8bSectionAChange([...sectionA, newRow])
  }, [formData, sectionA, handleA8bSectionAChange, ytdSubmissionId, employeeTaxNo])

  const handleCellUpdateSectionA = useCallback(
    (row: A8bSectionTable) => {
      row.gainNonExempt = parseFloat(((row.exerciseValue - row.exercisePrice) * row.quantity).toFixed(2)) // round to 2 decimals place
      row.gainTotal = row.gainNonExempt
      const index = sectionA.findIndex(item => item.id === row.id)
      handleA8bSectionAChange([...sectionA.slice(0, index), row, ...sectionA.slice(index + 1, sectionA.length)])
    },
    [sectionA, handleA8bSectionAChange]
  )

  const handleRowDeleteSectionA = useCallback(
    (id: string) => {
      handleA8bSectionAChange([...sectionA.filter(item => item.id !== id)])
    },
    [sectionA, handleA8bSectionAChange]
  )
  // ### End of Section A

  // ### Section B
  const sectionBColumns: EditableColumnsType<A8bSectionTable> = [
    {
      title: 'No.',
      key: 'recordNo',
      dataIndex: 'recordNo',
      width: 25
    },
    {
      title: 'Company registration no./UEN',
      key: 'companyIdNo',
      dataIndex: 'companyIdNo',
      width: 45
    },
    {
      title: 'Name of company',
      key: 'companyName',
      dataIndex: 'companyName',
      width: 140
    },
    {
      title: 'Type of plan granted',
      key: 'planType',
      dataIndex: 'planType',
      editable: true,
      inputType: 'select',
      width: 60,
      selectOptions: planTypeOptions,
      render: val => upperCase(val)
    },
    {
      title: 'Date of grant',
      key: 'grantDate',
      dataIndex: 'grantDate',
      className: 'dateHeader',
      editable: true,
      inputType: 'date',
      width: 100,
      render: (value: string) => value && moment(value).format(DATE_DISPLAY)
    },
    {
      title: () => (
        <>
          Date of exercise
          <InfoTooltip
            title={`Date of exercise of ESOP or date of vesting of ESOW Plan (if applicable). If moratorium (i.e. selling restriction) is imposed, state the date the moratorium is lifted for the ESOP/ESOW Plans`}
          />
        </>
      ),
      key: 'exerciseDate',
      dataIndex: 'exerciseDate',
      className: 'dateHeader',
      editable: true,
      inputType: 'date',
      width: 100,
      render: (value: string) => value && moment(value).format(DATE_DISPLAY)
    },
    {
      title: () => (
        <>
          Exercise price
          <InfoTooltip title={`Exercise price of ESOP/or price paid/ payable per share under ESOW plan`} />
        </>
      ),
      key: 'exercisePrice',
      dataIndex: 'exercisePrice',
      editable: true,
      width: 70,
      align: 'right',
      inputType: 'numeric',
      render: (value: number) => value?.toLocaleString()
    },
    {
      title: () => (
        <>
          Value per share as at the date of grant
          <InfoTooltip title={`Open market value per share as at the date of grant of ESOP or ESOW plan`} />
        </>
      ),
      key: 'grantValue',
      dataIndex: 'grantValue',
      editable: true,
      width: 72,
      align: 'right',
      inputType: 'numeric',
      render: (value: number) => value?.toLocaleString()
    },
    {
      title: () => (
        <>
          Value per share as at the date of exercise
          <InfoTooltip
            title={`Open market value per share as at the date of exercise of ESOP or date of vesting of ESOW plan`}
          />
        </>
      ),
      key: 'exericseValue',
      dataIndex: 'exerciseValue',
      editable: true,
      width: 72,
      align: 'right',
      inputType: 'numeric',
      render: (value: number) => value?.toLocaleString()
    },
    {
      title: 'Number of shares acquired',
      key: 'quantity',
      dataIndex: 'quantity',
      editable: true,
      width: 60,
      align: 'right',
      inputType: 'numeric',
      render: (value: number) => value?.toLocaleString()
    },
    {
      title: 'Gains from ESOP/ESOW plans',
      children: [
        {
          title: 'Gross amount qualifying for tax exemption',
          key: 'gainExemptB',
          dataIndex: 'gainExemptB',
          width: 90,
          align: 'right',
          render: (value: number) => value?.toLocaleString()
        },
        {
          title: 'Gross amount not qualifying for tax exemption',
          key: 'gainNonExempt',
          dataIndex: 'gainNonExempt',
          width: 90,
          align: 'right',
          render: (value: number) => value?.toLocaleString()
        },
        {
          title: 'Gross amount of gains from ESOP/ESOW Plans',
          key: 'gainTotal',
          dataIndex: 'gainTotal',
          width: 90,
          align: 'right',
          render: (value: number) => value?.toLocaleString()
        }
      ]
    }
  ]

  if (!readOnly) {
    sectionBColumns.push({
      title: '',
      dataIndex: 'operation',
      width: 60,
      align: 'center',
      className: 'pr10',
      render: (_, record: { id: string }) =>
        sectionB ? <Link onClick={() => handleRowDeleteSectionB(record.id)}>remove</Link> : null
    })
  }

  const handleAddRowSectionB = useCallback(() => {
    const lastRow = sectionB[sectionB.length - 1]
    const id = newGuid()
    const newRow: A8bSectionTable = {
      ...lastRow,
      id,
      section: 'b',
      ytdSubmissionId: ytdSubmissionId,
      employeeTaxNo: employeeTaxNo,
      recordNo: lastRow ? lastRow.recordNo + 1 : 1,
      companyIdType: formData.companyIdType,
      companyIdNo: formData.companyIdNo,
      companyName: formData.companyName,
      planType: 'esop',
      grantDate: undefined,
      exerciseDate: undefined,
      exercisePrice: 0.0,
      grantValue: 0.0,
      exerciseValue: 0.0,
      quantity: 0.0,
      gainExemptB: 0.0,
      gainExemptC: 0.0,
      gainExemptD: 0.0,
      gainNonExempt: 0.0,
      gainTotal: 0.0
    }
    handleA8bSectionBChange([...sectionB, newRow])
  }, [formData, sectionB, handleA8bSectionBChange, ytdSubmissionId, employeeTaxNo])

  const handleCellUpdateSectionB = useCallback(
    (row: A8bSectionTable) => {
      row.gainExemptB = parseFloat(((row.exerciseValue - row.grantValue) * row.quantity).toFixed(2))
      row.gainNonExempt = parseFloat(((row.grantValue - row.exercisePrice) * row.quantity).toFixed(2)) // round to 2 decimals place
      row.gainTotal = row.gainNonExempt + row.gainExemptB
      const index = sectionB.findIndex(item => item.id === row.id)
      handleA8bSectionBChange([...sectionB.slice(0, index), row, ...sectionB.slice(index + 1, sectionB.length)])
    },
    [sectionB, handleA8bSectionBChange]
  )

  const handleRowDeleteSectionB = useCallback(
    (id: string) => {
      handleA8bSectionBChange([...sectionB.filter(item => item.id !== id)])
    },
    [sectionB, handleA8bSectionBChange]
  )
  // ### End of Section B

  // ### Section C
  const sectionCColumns: EditableColumnsType<A8bSectionTable> = [
    {
      title: 'No.',
      key: 'recordNo',
      dataIndex: 'recordNo',
      width: 25
    },
    {
      title: 'Company registration no./UEN',
      key: 'companyIdNo',
      dataIndex: 'companyIdNo',
      width: 45
    },
    {
      title: 'Name of company',
      key: 'companyName',
      dataIndex: 'companyName',
      width: 140
    },
    {
      title: 'Type of plan granted',
      key: 'planType',
      dataIndex: 'planType',
      editable: true,
      inputType: 'select',
      selectOptions: planTypeOptions,
      width: 60,
      render: val => upperCase(val)
    },
    {
      title: 'Date of grant',
      key: 'grantDate',
      dataIndex: 'grantDate',
      className: 'dateHeader',
      editable: true,
      inputType: 'date',
      width: 100,
      render: (value: string) => value && moment(value).format(DATE_DISPLAY)
    },
    {
      title: () => (
        <>
          Date of exercise
          <InfoTooltip
            title={`Date of exercise of ESOP or date of vesting of ESOW Plan (if applicable). If moratorium (i.e. selling restriction) is imposed, state the date the moratorium is lifted for the ESOP/ESOW Plans`}
          />
        </>
      ),
      key: 'exerciseDate',
      dataIndex: 'exerciseDate',
      className: 'dateHeader',
      editable: true,
      inputType: 'date',
      width: 100,
      render: (value: string) => value && moment(value).format(DATE_DISPLAY)
    },
    {
      title: () => (
        <>
          Exercise price
          <InfoTooltip title={`Exercise price of ESOP/or price paid/ payable per share under ESOW plan`} />
        </>
      ),
      key: 'exercisePrice',
      dataIndex: 'exercisePrice',
      editable: true,
      inputType: 'numeric',
      width: 70,
      align: 'right',
      render: (value: number) => value?.toLocaleString()
    },
    {
      title: () => (
        <>
          Value per share as at the date of grant
          <InfoTooltip title={`Open market value per share as at the date of grant of ESOP or ESOW plan`} />
        </>
      ),
      key: 'grantValue',
      dataIndex: 'grantValue',
      editable: true,
      inputType: 'numeric',
      width: 72,
      align: 'right',
      render: (value: number) => value?.toLocaleString()
    },
    {
      title: () => (
        <>
          Value per share as at the date of exercise
          <InfoTooltip
            title={`Open market value per share as at the date of exercise of ESOP or date of vesting of ESOW plan`}
          />
        </>
      ),
      key: 'exericseValue',
      dataIndex: 'exerciseValue',
      editable: true,
      inputType: 'numeric',
      width: 72,
      align: 'right',
      render: (value: number) => value?.toLocaleString()
    },
    {
      title: 'Number of shares acquired',
      key: 'quantity',
      dataIndex: 'quantity',
      editable: true,
      inputType: 'numeric',
      width: 60,
      align: 'right',
      render: (value: number) => value?.toLocaleString()
    },
    {
      title: 'Gains from ESOP/ESOW plans',
      children: [
        {
          title: 'Gross amount qualifying for tax exemption',
          key: 'gainExemptC',
          dataIndex: 'gainExemptC',
          width: 90,
          align: 'right',
          render: (value: number) => value?.toLocaleString()
        },
        {
          title: 'Gross amount not qualifying for tax exemption',
          key: 'gainNonExempt',
          dataIndex: 'gainNonExempt',
          width: 90,
          align: 'right',
          render: (value: number) => value?.toLocaleString()
        },
        {
          title: 'Gross amount of gains from ESOP/ESOW Plans',
          key: 'gainTotal',
          dataIndex: 'gainTotal',
          width: 90,
          align: 'right',
          render: (value: number) => value?.toLocaleString()
        }
      ]
    }
  ]

  if (!readOnly) {
    sectionCColumns.push({
      title: '',
      dataIndex: 'operation',
      width: 60,
      className: 'pr10',
      align: 'center',
      render: (_, record: { id: string }) =>
        sectionC ? <Link onClick={() => handleRowDeleteSectionC(record.id)}>remove</Link> : null
    })
  }

  const handleAddRowSectionC = useCallback(() => {
    const lastRow = sectionC[sectionC.length - 1]
    const id = newGuid()
    const newRow: A8bSectionTable = {
      ...lastRow,
      id,
      section: 'c',
      ytdSubmissionId,
      employeeTaxNo: employeeTaxNo,
      recordNo: lastRow ? lastRow.recordNo + 1 : 1,
      companyIdType: formData.companyIdType,
      companyIdNo: formData.companyIdNo,
      companyName: formData.companyName,
      planType: 'esop',
      grantDate: undefined,
      exerciseDate: undefined,
      exercisePrice: 0.0,
      grantValue: 0.0,
      exerciseValue: 0.0,
      quantity: 0.0,
      gainExemptB: 0.0,
      gainExemptC: 0.0,
      gainExemptD: 0.0,
      gainNonExempt: 0.0,
      gainTotal: 0.0
    }
    handleA8bSectionCChange([...sectionC, newRow])
  }, [formData, sectionC, handleA8bSectionCChange, ytdSubmissionId, employeeTaxNo])

  const handleCellUpdateSectionC = useCallback(
    (row: A8bSectionTable) => {
      row.gainExemptC = parseFloat(((row.exerciseValue - row.grantValue) * row.quantity).toFixed(2))
      row.gainNonExempt = parseFloat(((row.grantValue - row.exercisePrice) * row.quantity).toFixed(2)) // round to 2 decimals place
      row.gainTotal = row.gainNonExempt + row.gainExemptC
      const index = sectionC.findIndex(item => item.id === row.id)
      handleA8bSectionCChange([...sectionC.slice(0, index), row, ...sectionC.slice(index + 1, sectionC.length)])
    },
    [sectionC, handleA8bSectionCChange]
  )

  const handleRowDeleteSectionC = useCallback(
    (id: string) => {
      handleA8bSectionCChange([...sectionC.filter(item => item.id !== id)])
    },
    [sectionC, handleA8bSectionCChange]
  )
  // ### End of Section C

  // ### Section D
  const sectionDColumns: EditableColumnsType<A8bSectionTable> = [
    {
      title: 'No.',
      key: 'recordNo',
      dataIndex: 'recordNo',
      width: 25
    },
    {
      title: 'Company registration no./UEN',
      key: 'companyIdNo',
      dataIndex: 'companyIdNo',
      width: 45
    },
    {
      title: 'Name of company',
      key: 'companyName',
      dataIndex: 'companyName',
      width: 140
    },
    {
      title: 'Type of plan granted',
      key: 'planType',
      dataIndex: 'planType',
      editable: true,
      inputType: 'select',
      width: 60,
      selectOptions: planTypeOptions,
      render: val => upperCase(val)
    },
    {
      title: 'Date of grant',
      key: 'grantDate',
      dataIndex: 'grantDate',
      className: 'dateHeader',
      editable: true,
      inputType: 'date',
      width: 100,
      render: (value: string) => value && moment(value).format(DATE_DISPLAY)
    },
    {
      title: () => (
        <>
          Date of exercise
          <InfoTooltip
            title={`Date of exercise of ESOP or date of vesting of ESOW Plan (if applicable). If moratorium (i.e. selling restriction) is imposed, state the date the moratorium is lifted for the ESOP/ESOW Plans`}
          />
        </>
      ),
      key: 'exerciseDate',
      dataIndex: 'exerciseDate',
      className: 'dateHeader',
      editable: true,
      inputType: 'date',
      width: 100,
      render: (value: string) => value && moment(value).format(DATE_DISPLAY)
    },
    {
      title: () => (
        <>
          Exercise price
          <InfoTooltip title={`Exercise price of ESOP/or price paid/ payable per share under ESOW plan`} />
        </>
      ),
      key: 'exercisePrice',
      dataIndex: 'exercisePrice',
      editable: true,
      inputType: 'numeric',
      width: 70,
      align: 'right',
      render: (value: number) => value?.toLocaleString()
    },
    {
      title: () => (
        <>
          Value per share as at the date of grant
          <InfoTooltip title={`Open market value per share as at the date of grant of ESOP or ESOW plan`} />
        </>
      ),
      key: 'grantValue',
      dataIndex: 'grantValue',
      editable: true,
      inputType: 'numeric',
      width: 72,
      align: 'right',
      render: (value: number) => value?.toLocaleString()
    },
    {
      title: () => (
        <>
          Value per share as at the date of exercise
          <InfoTooltip
            title={`Open market value per share as at the date of exercise of ESOP or date of vesting of ESOW plan`}
          />
        </>
      ),
      key: 'exericseValue',
      dataIndex: 'exerciseValue',
      editable: true,
      inputType: 'numeric',
      width: 72,
      align: 'right',
      render: (value: number) => value?.toLocaleString()
    },
    {
      title: 'Number of shares acquired',
      key: 'quantity',
      dataIndex: 'quantity',
      editable: true,
      inputType: 'numeric',
      width: 60,
      align: 'right',
      render: (value: number) => value?.toLocaleString()
    },
    {
      title: 'Gains from ESOP/ESOW plans',
      children: [
        {
          title: 'Gross amount qualifying for tax exemption',
          key: 'gainExemptD',
          dataIndex: 'gainExemptD',
          width: 90,
          align: 'right',
          render: (value: number) => value?.toLocaleString()
        },
        {
          title: 'Gross amount not qualifying for tax exemption',
          key: 'gainNonExempt',
          dataIndex: 'gainNonExempt',
          width: 90,
          align: 'right',
          render: (value: number) => value?.toLocaleString()
        },
        {
          title: 'Gross amount of gains from ESOP/ESOW Plans',
          key: 'gainTotal',
          dataIndex: 'gainTotal',
          width: 90,
          align: 'right',
          render: (value: number) => value?.toLocaleString()
        }
      ]
    }
  ]

  if (!readOnly) {
    sectionDColumns.push({
      title: '',
      dataIndex: 'operation',
      width: 60,
      className: 'pr10',
      align: 'center',
      render: (_, record: { id: string }) =>
        sectionD ? <Link onClick={() => handleRowDeleteSectionD(record.id)}>remove</Link> : null
    })
  }

  const handleAddRowSectionD = useCallback(() => {
    const lastRow = sectionD[sectionD.length - 1]
    const id = newGuid()
    const newRow: A8bSectionTable = {
      ...lastRow,
      id,
      section: 'd',
      ytdSubmissionId,
      employeeTaxNo,
      recordNo: lastRow ? lastRow.recordNo + 1 : 1,
      companyIdType: formData.companyIdType,
      companyIdNo: formData.companyIdNo,
      companyName: formData.companyName,
      planType: 'esop',
      grantDate: undefined,
      exerciseDate: undefined,
      exercisePrice: 0.0,
      grantValue: 0.0,
      exerciseValue: 0.0,
      quantity: 0.0,
      gainExemptB: 0.0,
      gainExemptC: 0.0,
      gainExemptD: 0.0,
      gainNonExempt: 0.0,
      gainTotal: 0.0
    }
    handleA8bSectionDChange([...sectionD, newRow])
  }, [formData, sectionD, handleA8bSectionDChange, ytdSubmissionId, employeeTaxNo])

  const handleCellUpdateSectionD = useCallback(
    (row: A8bSectionTable) => {
      row.gainExemptD = parseFloat(((row.exerciseValue - row.grantValue) * row.quantity).toFixed(2))
      row.gainNonExempt = parseFloat(((row.grantValue - row.exercisePrice) * row.quantity).toFixed(2)) // round to 2 decimals place
      row.gainTotal = row.gainNonExempt + row.gainExemptD
      const index = sectionD.findIndex(item => item.id === row.id)
      handleA8bSectionDChange([...sectionD.slice(0, index), row, ...sectionD.slice(index + 1, sectionD.length)])
    },
    [sectionD, handleA8bSectionDChange]
  )

  const handleRowDeleteSectionD = useCallback(
    (id: string) => {
      handleA8bSectionDChange([...sectionD.filter(item => item.id !== id)])
    },
    [sectionD, handleA8bSectionDChange]
  )
  // ### End of Section D

  if (loading)
    return (
      <div className="a8b">
        <div className="a8b-form">
          <Skeleton active />
        </div>
      </div>
    )

  if (!hasData)
    return (
      <YtdEmptyForm onCreate={handleCreateForm}>
        <SecondaryText>There is no Appendix 8B for the selected employee.</SecondaryText>
      </YtdEmptyForm>
    )

  return (
    <div {...divProps} className="a8b">
      <div className="a8b-form">
        <ErrorDisplay errors={errors} />
        <div>
          <div className="a8b-form__title">SECTION A: Employee Equity-based Remuneration (EEBR) Scheme</div>
          <div className="ir8s-form__field">
            <EditableTable
              bordered
              readOnly={readOnly}
              dataSource={sectionA}
              pagination={false}
              columns={sectionAColumns}
              loading={updating}
              onAddRow={handleAddRowSectionA}
              onCellUpdate={handleCellUpdateSectionA}
            />
          </div>
        </div>
        <div>
          <div className="a8b-form__title">SECTION B: Equity Remuneration Incentive Scheme (ERIS) SMEs</div>
          <div className="ir8s-form__field">
            <EditableTable
              bordered
              readOnly={readOnly}
              dataSource={sectionB}
              pagination={false}
              columns={sectionBColumns}
              loading={updating}
              onAddRow={handleAddRowSectionB}
              onCellUpdate={handleCellUpdateSectionB}
            />
          </div>
        </div>
        <div>
          <div className="a8b-form__title">SECTION C: Equity Remuneration Incentive Scheme (ERIS) All Corporations</div>
          <div className="ir8s-form__field">
            <EditableTable
              bordered
              readOnly={readOnly}
              dataSource={sectionC}
              pagination={false}
              columns={sectionCColumns}
              loading={updating}
              onAddRow={handleAddRowSectionC}
              onCellUpdate={handleCellUpdateSectionC}
            />
          </div>
        </div>
        <div>
          <div className="a8b-form__title">SECTION D: Equity Remuneration Incentive Scheme (ERIS) Start-ups</div>
          <div className="ir8s-form__field">
            <EditableTable
              bordered
              readOnly={readOnly}
              dataSource={sectionD}
              pagination={false}
              columns={sectionDColumns}
              loading={updating}
              onAddRow={handleAddRowSectionD}
              onCellUpdate={handleCellUpdateSectionD}
            />
          </div>
        </div>
        <ErrorDisplay errors={errors} />
      </div>
    </div>
  )
}

import axios, { AxiosRequestConfig } from 'axios'
import { message as $message } from 'antd'
import memoize from 'memoizee'
import env from '../env'

export const timeout = 120000

axios.defaults.timeout = timeout

axios.interceptors.request.use(
  config => {
    return config
  },
  error => {
    console.error(JSON.stringify(error), error)
    Promise.reject(error)
  }
)

axios.interceptors.response.use(
  config => {
    if (config?.data?.message) {
      $message.success(config.data.message)
    }
    return {
      status: true,
      // message: config?.data?.message,
      result: config?.data
    } as any
  },
  async error => {
    console.error(
      error?.config?.method,
      error?.config?.url,
      error?.response?.status,
      error?.response?.statusText,
      error?.response,
      JSON.stringify(error),
      error
    )

    let errors: { [key: string]: string[] } = {},
      errorMessage = '',
      responseData = error?.response?.data

    if (
      error.request.responseType === 'blob' &&
      error.response?.data instanceof Blob &&
      error.response.data.type?.toLowerCase().indexOf('json') !== -1
    ) {
      try {
        responseData = JSON.parse(await error.response.data.text())
      } catch {}
    }
    if (responseData?.errors) {
      errors = responseData?.errors
    }
    if (responseData?.error?.message) {
      errorMessage = responseData?.error?.message || 'An error has occurred'
    } else if (error?.response?.status === 400) {
      errorMessage = ''
    } else if (error?.response?.status === 401) {
      // No need to display message as it should be redirected to login page
    } else if (error?.response?.status === 403) {
      errorMessage = 'Access denied'
    } else if (error?.response?.status === 413) {
      errorMessage = 'Request too large'
    } else if (error?.response?.status === 500) {
      errorMessage = 'An error has occurred'
    } else if (error?.message) {
      errorMessage = error?.message
    }

    if (responseData?.errors) delete responseData.errors

    return {
      status: false,
      statusCode: error.response?.status,
      errors,
      message: errorMessage,
      errorData: error.response?.status === 500 ? responseData : null
    }
  }
)

export type ErrorData = {
  ServiceCode: string
  ErrorDetails: string
  ErrorMessage: string
  // DateTime: string
  // TenantCode: string
}

export type Response<T = any> = {
  status: boolean
  statusCode: number
  errors: { [key: string]: string[] }
  message: string
  errorData: ErrorData
  result: T
}

type Method = 'get' | 'post' | 'put' | 'patch' | 'delete'

export type MyResponse<T = any> = Promise<Response<T>>

/**
 *00
 * @param method - request methods
 * @param url - request url
 * @param data - request data or params
 */
export const requestNoCache = <T = any>(
  method: Method,
  url: string,
  data?: any,
  config?: AxiosRequestConfig
): MyResponse<T> => {
  config = config || {}

  const token = localStorage.getItem('access_token')
  if (token) {
    config.headers = config.headers || {}
    config.headers['Authorization'] = `Bearer ${token}`
  }

  const tenant = localStorage.getItem('tenant')
  if (tenant) {
    config.headers = config.headers || {}
    config.headers['X-Tenant'] = tenant
  }

  if (method === 'post') {
    return axios.post(url, data, config)
  } else if (method === 'patch') {
    return axios.patch(url, data, config)
  } else if (method === 'put') {
    return axios.put(url, data, config)
  } else if (method === 'delete') {
    return axios.delete(url, {
      params: data,
      ...config
    })
  } else {
    return axios.get(url, {
      params: data,
      ...config
    })
  }
}

export const request = memoize(requestNoCache, {
  maxAge: 1500,
  promise: true,
  normalizer: args => {
    const [method, url, data, config] = args
    return `${method}:${url}:${JSON.stringify(data)}:${JSON.stringify(config)}`
  }
})

export const getBaseUrl = (baseUri: string) => {
  const useMockData = Boolean(Number(process.env.REACT_APP_USE_MOCK_DATA))
  let baseHost = useMockData ? '' : env.REACT_APP_BASE_HOST
  if (baseHost && baseHost.includes('{tenant_code}')) {
    const tenant = localStorage.getItem('tenant')
    baseHost = baseHost.replace('{tenant_code}', tenant)
  }
  return `${baseHost}/api${baseUri}`
}

export const toQueryParams = (obj?: { [key: string]: any }): URLSearchParams => {
  var params = new URLSearchParams()
  if (!obj) return params

  for (const key of Object.keys(obj)) {
    if (Array.isArray(obj[key])) {
      for (const val of obj[key]) {
        if (typeof val === 'string') {
          params.append(key, val)
        } else {
          console.warn(`Recommended: use HTTP POST instead for array of object for query param ${key}`)
          const subParams = toQueryParams(val)
          params.append(key, subParams.toString())
        }
      }
    } else {
      if (obj[key]) {
        params.append(key, obj[key])
      }
    }
  }

  return params
}

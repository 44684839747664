import { ActionResult, ThunkResult } from '~/types/store'
import { emptyGuid } from '~/constants'
import { showSuccess, showError } from '~/utils'
import { apiAddEmCert } from '../api/em-cert.api'
import { setEmCert } from '../reducers'
import { IEmCertInfo, EmCertState } from '../types'

export const addEmCert = (employeeId: string, emCert: IEmCertInfo): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult | undefined> => {
    if (!employeeId) return

    const { status, result, errors, message, errorData } = await apiAddEmCert(employeeId, {
      id: emptyGuid,
      employeeId,
      ...emCert
    })
    if (status) {
      const newEmCert = { id: result.id, employeeId, ...emCert } as EmCertState
      dispatch(setEmCert({ employeeId, data: newEmCert }))

      showSuccess('Added')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}

import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import {
  EmployeeRootState,
  EmployeeState,
  EmPublicPersonState,
  EmPublicProfileState,
  EmPublicState,
  EmHireState,
  EmIdentityState,
  EmAddressState,
  EmFamilyState,
  EmEducationState,
  EmDocumentState,
  EmCalendarState,
  EmSalaryState,
  EmStatutoryExemptState,
  EmPayGroupState,
  EmLeaveGroupState,
  EmLocationState,
  EmShiftRoleState,
  EmCompanyState,
  EmDepartmentState,
  EmJobState,
  EmManagerState,
  EmTeamState,
  EmOfficeState,
  EmCostCentreState,
  EmEmploymentTypeState,
  EmDirectoryViewState,
  EmSelectionViewState,
  employeePersistConfig,
  EmployeeImportState,
  EmPreferenceState,
  ChartId,
  EmCategoryState,
  EmDivisionState,
  EmGrpState,
  EmManagerSecondaryState,
  EmOtClassState,
  EmSectionState,
  EmRequestState,
  EmIdentityRequestState,
  EmAddressRequestState,
  EmEducationRequestState,
  EmFamilyRequestState,
  EmLeaveBuddyState,
  EmAttendanceGroupState,
  EmProjectState,
  EmCertState,
  EmCertRequestState
} from './types'
import { StoreState } from '../../types/store'
import { KeyValue } from '~/types/common'
import { BarChartState, ChartSummaryState, PieChartState } from './types/em-dashboard.state'

const emKeyValuesAdapter = createEntityAdapter<KeyValue>({
  selectId: model => model.key
})
const emPublicPersonsAdapter = createEntityAdapter<EmPublicPersonState>()
const emPublicProfilesAdapter = createEntityAdapter<EmPublicProfileState>()
const emPublicAdapter = createEntityAdapter<EmPublicState>()
const emHiresAdapter = createEntityAdapter<EmHireState>()
const employeesAdapter = createEntityAdapter<EmployeeState>()
const emIdentitiesAdapter = createEntityAdapter<EmIdentityState>()
const emAddressesAdapter = createEntityAdapter<EmAddressState>()
const emFamiliesAdapter = createEntityAdapter<EmFamilyState>()
const emEducationsAdapter = createEntityAdapter<EmEducationState>()
const emCertsAdapter = createEntityAdapter<EmCertState>()
const emDocumentsAdapter = createEntityAdapter<EmDocumentState>()
const emCalendarsAdapter = createEntityAdapter<EmCalendarState>()
const emSalariesAdapter = createEntityAdapter<EmSalaryState>()
const emPayGroupsAdapter = createEntityAdapter<EmPayGroupState>()
const emLeaveGroupsAdapter = createEntityAdapter<EmLeaveGroupState>()
const emLeaveBuddiesAdapter = createEntityAdapter<EmLeaveBuddyState>()
const emProjectsAdapter = createEntityAdapter<EmProjectState>()
const emLocationsAdapter = createEntityAdapter<EmLocationState>()
const emAttendanceGroupsAdapter = createEntityAdapter<EmAttendanceGroupState>()
const emShiftRolesAdapter = createEntityAdapter<EmShiftRoleState>()
const emCompaniesAdapter = createEntityAdapter<EmCompanyState>()
const emDepartmentsAdapter = createEntityAdapter<EmDepartmentState>()
const emDivisionsAdapter = createEntityAdapter<EmDivisionState>()
const emSectionsAdapter = createEntityAdapter<EmSectionState>()
const emGrpsAdapter = createEntityAdapter<EmGrpState>()
const emCategoriesAdapter = createEntityAdapter<EmCategoryState>()
const emOtClassesAdapter = createEntityAdapter<EmOtClassState>()
const emTeamsAdapter = createEntityAdapter<EmTeamState>()
const emJobsAdapter = createEntityAdapter<EmJobState>()
const emManagersAdapter = createEntityAdapter<EmManagerState>()
const emManagerSecondariesAdapter = createEntityAdapter<EmManagerSecondaryState>()
const emOfficesAdapter = createEntityAdapter<EmOfficeState>()
const emCostCentresAdapter = createEntityAdapter<EmCostCentreState>()
const emEmploymentTypesAdapter = createEntityAdapter<EmEmploymentTypeState>()
const emStatutoryExemptsAdapter = createEntityAdapter<EmStatutoryExemptState>()
const emDirectoryViewAdapter = createEntityAdapter<EmDirectoryViewState>({ selectId: model => model.viewId })
const emSelectionAdapter = createEntityAdapter<EmSelectionViewState>({ selectId: model => model.viewId })
const emPreferenceAdapter = createEntityAdapter<EmPreferenceState>({ selectId: model => model.employeeId })
const emRequestsAdapter = createEntityAdapter<EmRequestState>()
const emIdentityRequestsAdapter = createEntityAdapter<EmIdentityRequestState>()
const emAddressRequestsAdapter = createEntityAdapter<EmAddressRequestState>()
const emEducationRequestsAdapter = createEntityAdapter<EmEducationRequestState>()
const emFamilyRequestsAdapter = createEntityAdapter<EmFamilyRequestState>()
const emCertRequestsAdapter = createEntityAdapter<EmCertRequestState>()

const emKeyvalueInitialState = emKeyValuesAdapter.getInitialState()
const emPublicPersonInitialState = emPublicPersonsAdapter.getInitialState()
const emPublicProfileInitialState = emPublicProfilesAdapter.getInitialState()
const emPublicInitialState = emPublicAdapter.getInitialState()
const emHireInitialState = emHiresAdapter.getInitialState()
const employeesInitialState = employeesAdapter.getInitialState()
const emIdentitiesInitialState = emIdentitiesAdapter.getInitialState()
const emAddressesInitialState = emAddressesAdapter.getInitialState()
const emFamiliesInitialState = emFamiliesAdapter.getInitialState()
const emCertsInitialState = emCertsAdapter.getInitialState()
const emEducationsInitialState = emEducationsAdapter.getInitialState()
const emDocumentsInitialState = emDocumentsAdapter.getInitialState()
const emCalendarsInitialState = emCalendarsAdapter.getInitialState()
const emSalariesInitialState = emSalariesAdapter.getInitialState()
const emPayGroupsInitialState = emPayGroupsAdapter.getInitialState()
const emLeaveGroupsInitialState = emLeaveGroupsAdapter.getInitialState()
const emLeaveBuddiesInitialState = emLeaveBuddiesAdapter.getInitialState()
const emProjectsInitialState = emProjectsAdapter.getInitialState()
const emLocationsInitialState = emLocationsAdapter.getInitialState()
const emAttendanceGroupsInitialState = emAttendanceGroupsAdapter.getInitialState()
const emShiftRolesInitialState = emShiftRolesAdapter.getInitialState()
const emCompaniesInitialState = emCompaniesAdapter.getInitialState()
const emDepartmentsInitialState = emDepartmentsAdapter.getInitialState()
const emDivisionsInitialState = emDivisionsAdapter.getInitialState()
const emSectionsInitialState = emSectionsAdapter.getInitialState()
const emGrpsInitialState = emGrpsAdapter.getInitialState()
const emCategoriesInitialState = emCategoriesAdapter.getInitialState()
const emTeamsInitialState = emTeamsAdapter.getInitialState()
const emJobsInitialState = emJobsAdapter.getInitialState()
const emManagersInitialState = emManagersAdapter.getInitialState()
const emManagerSecondariesInitialState = emManagerSecondariesAdapter.getInitialState()
const emOfficesInitialState = emOfficesAdapter.getInitialState()
const emCostCentresInitialState = emCostCentresAdapter.getInitialState()
const emEmploymentTypesInitialState = emEmploymentTypesAdapter.getInitialState()
const emOtClassesInitialState = emOtClassesAdapter.getInitialState()
const emDirectoryViewInitialState = emDirectoryViewAdapter.getInitialState()
const emSelectionInitialState = emSelectionAdapter.getInitialState()
const emStatutoryExemptInitialState = emStatutoryExemptsAdapter.getInitialState()
const emPreferenceInitialState = emPreferenceAdapter.getInitialState()
const emRequestsInitialState = emRequestsAdapter.getInitialState()
const emIdentityRequestsInitialState = emIdentityRequestsAdapter.getInitialState()
const emAddressRequestsInitialState = emAddressRequestsAdapter.getInitialState()
const emEducationRequestsInitialState = emEducationRequestsAdapter.getInitialState()
const emFamilyRequestsInitialState = emFamilyRequestsAdapter.getInitialState()
const emCertRequestsInitialState = emCertRequestsAdapter.getInitialState()

const initialState: EmployeeRootState = {
  emKeyValues: emKeyvalueInitialState,
  emKeyValuesLoading: false,
  emPublicPersons: emPublicPersonInitialState,
  emPublicPersonsLoading: false,
  emPublicProfiles: emPublicProfileInitialState,
  emPublicProfilesLoading: false,
  emPublic: emPublicInitialState,
  emPublicLoading: false,
  employees: employeesInitialState,
  employeesLoading: false,
  employeesRefetch: { refetchNumber: 0 },
  emHires: {},
  emHiresLoading: {},
  emIdentities: {},
  emIdentitiesLoading: {},
  emAddresses: {},
  emAddressesLoading: {},
  emFamilies: {},
  emFamiliesLoading: {},
  emEducations: {},
  emEducationsLoading: {},
  emCerts: {},
  emCertsLoading: {},
  emDocuments: {},
  emDocumentsLoading: {},
  emCalendars: {},
  emCalendarsLoading: {},
  emSalaries: {},
  emSalariesLoading: {},
  emPayGroups: {},
  emPayGroupsLoading: {},
  emLeaveGroups: {},
  emLeaveGroupsLoading: {},
  emLeaveBuddies: {},
  emLeaveBuddiesLoading: {},
  emProjects: {},
  emProjectsLoading: {},
  emLocations: {},
  emLocationsLoading: {},
  emAttendanceGroups: {},
  emAttendanceGroupsLoading: {},
  emShiftRoles: {},
  emShiftRolesLoading: {},
  emCompanies: {},
  emCompaniesLoading: {},
  emDepartments: {},
  emDepartmentsLoading: {},
  emDivisions: {},
  emDivisionsLoading: {},
  emSections: {},
  emSectionsLoading: {},
  emGrps: {},
  emGrpsLoading: {},
  emCategories: {},
  emCategoriesLoading: {},
  emTeams: {},
  emTeamsLoading: {},
  emJobs: {},
  emJobsLoading: {},
  emManagers: {},
  emManagersLoading: {},
  emManagerSecondaries: {},
  emManagerSecondariesLoading: {},
  emOffices: {},
  emOfficesLoading: {},
  emCostCentres: {},
  emCostCentresLoading: {},
  emEmploymentTypes: {},
  emEmploymentTypesLoading: {},
  emOtClasses: {},
  emOtClassesLoading: {},
  emStatutoryExempts: {},
  emStatutoryExemptsLoading: {},
  emDirectoryView: emDirectoryViewInitialState,
  emDirectoryViewLoading: false,
  emDirectoryViewExpanded: false,
  emDirectoryViewRefetch: 0,
  emSelection: emSelectionInitialState,
  emSelectionLoading: false,
  permittedEmployeePay: [],
  permittedEmployeeLve: [],
  permittedEmployeeAtt: [],
  refreshEmployeeLoading: false,
  emFieldValues: {},
  emFieldValuesLoading: {},
  employeeImport: undefined,
  emPreference: emPreferenceInitialState,
  emPreferenceLoading: false,
  emRequests: {},
  emRequestsLoading: {},
  emIdentityRequests: {},
  emIdentityRequestsLoading: {},
  emAddressRequests: {},
  emAddressRequestsLoading: {},
  emEducationRequests: {},
  emEducationRequestsLoading: {},
  emFamilyRequests: {},
  emFamilyRequestsLoading: {},
  emCertRequests: {},
  emCertRequestsLoading: {},

  // EmDashboard
  chartSummaries: {},
  chartSummariesLoading: {},
  headcountGroupBy: {},
  headcountGroupByLoading: {},
  chartServiceLen: undefined,
  chartServiceLenLoading: false,

  // Apex
  oedApexState: '',
  oedApexStateTimestamp: 0,
  oedSubmissionId: ''
}

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    // EmKeyValues
    setEmKeyValuesLoading: (state, action: PayloadAction<boolean>) => {
      state.emKeyValuesLoading = action.payload
    },
    setEmKeyValue: (state, action: PayloadAction<KeyValue>) => {
      emKeyValuesAdapter.upsertOne(state.emKeyValues, action.payload)
    },
    replaceEmKeyValues: (state, action: PayloadAction<KeyValue[]>) => {
      emKeyValuesAdapter.setAll(state.emKeyValues, action.payload)
    },
    removeEmKeyValue: (state, action: PayloadAction<string>) => {
      emKeyValuesAdapter.removeOne(state.emKeyValues, action.payload)
    },

    // EmPublic
    setEmPublicPersonsLoading: (state, action: PayloadAction<boolean>) => {
      state.emPublicPersonsLoading = action.payload
    },
    setEmPublicPerson: (state, action: PayloadAction<EmPublicPersonState>) => {
      emPublicPersonsAdapter.upsertOne(state.emPublicPersons, action.payload)
    },
    setEmPublicProfilesLoading: (state, action: PayloadAction<boolean>) => {
      state.emPublicProfilesLoading = action.payload
    },
    setEmPublicProfile: (state, action: PayloadAction<EmPublicProfileState>) => {
      emPublicProfilesAdapter.upsertOne(state.emPublicProfiles, action.payload)
    },
    setEmPublicLoading: (state, action: PayloadAction<boolean>) => {
      state.emPublicLoading = action.payload
    },
    setEmPublic: (state, action: PayloadAction<EmPublicState>) => {
      emPublicAdapter.upsertOne(state.emPublic, action.payload)
    },

    // Employee
    setEmployee: (state, action: PayloadAction<EmployeeState>) => {
      employeesAdapter.upsertOne(state.employees, action.payload)
    },
    setEmployeesLoading: (state, action: PayloadAction<boolean>) => {
      state.employeesLoading = action.payload
    },
    replaceEmployees: (state, action: PayloadAction<EmployeeState[]>) => {
      employeesAdapter.setAll(state.employees, action.payload)
    },
    removeEmployee: (state, action: PayloadAction<string>) => {
      employeesAdapter.removeOne(state.employees, action.payload)
    },
    refetchEmployees: (state, action: PayloadAction<{ employeeId: string }>) => {
      const { employeeId } = action.payload
      state.employeesRefetch[employeeId] += 1
    },

    // EmHires
    setEmHiresLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emHires[employeeId] = state.emHires[employeeId] || emHireInitialState
      state.emHiresLoading[employeeId] = loading
    },
    replaceEmHires: (state, action: PayloadAction<{ employeeId: string; data: EmHireState[] }>) => {
      const { employeeId, data } = action.payload
      emHiresAdapter.setAll(state.emHires[employeeId], data)
    },

    // EmIdentities
    setEmIdentitiesLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emIdentities[employeeId] = state.emIdentities[employeeId] || emIdentitiesInitialState
      state.emIdentitiesLoading[employeeId] = loading
    },
    setEmIdentity: (state, action: PayloadAction<{ employeeId: string; data: EmIdentityState }>) => {
      const { employeeId, data } = action.payload
      emIdentitiesAdapter.upsertOne(state.emIdentities[employeeId], data)
    },
    replaceEmIdentities: (state, action: PayloadAction<{ employeeId: string; data: EmIdentityState[] }>) => {
      const { employeeId, data } = action.payload
      emIdentitiesAdapter.setAll(state.emIdentities[employeeId], data)
    },
    removeEmIdentity: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emIdentitiesAdapter.removeOne(state.emIdentities[employeeId], id)
    },

    // EmAddresses
    setEmAddressesLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emAddresses[employeeId] = state.emAddresses[employeeId] || emAddressesInitialState
      state.emAddressesLoading[employeeId] = loading
    },
    setEmAddress: (state, action: PayloadAction<{ employeeId: string; data: EmAddressState }>) => {
      const { employeeId, data } = action.payload
      emAddressesAdapter.upsertOne(state.emAddresses[employeeId], data)
    },
    replaceEmAddresses: (state, action: PayloadAction<{ employeeId: string; data: EmAddressState[] }>) => {
      const { employeeId, data } = action.payload
      emAddressesAdapter.setAll(state.emAddresses[employeeId], data)
    },
    removeEmAddress: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emAddressesAdapter.removeOne(state.emAddresses[employeeId], id)
    },

    // EmFamilies
    setEmFamiliesLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emFamilies[employeeId] = state.emFamilies[employeeId] || emFamiliesInitialState
      state.emFamiliesLoading[employeeId] = loading
    },
    setEmFamily: (state, action: PayloadAction<{ employeeId: string; data: EmFamilyState }>) => {
      const { employeeId, data } = action.payload
      emFamiliesAdapter.upsertOne(state.emFamilies[employeeId], data)
    },
    replaceEmFamilies: (state, action: PayloadAction<{ employeeId: string; data: EmFamilyState[] }>) => {
      const { employeeId, data } = action.payload
      emFamiliesAdapter.setAll(state.emFamilies[employeeId], data)
    },
    removeEmFamily: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emFamiliesAdapter.removeOne(state.emFamilies[employeeId], id)
    },

    // EmEducations
    setEmEducationsLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emEducations[employeeId] = state.emEducations[employeeId] || emEducationsInitialState
      state.emEducationsLoading[employeeId] = loading
    },
    setEmEducation: (state, action: PayloadAction<{ employeeId: string; data: EmEducationState }>) => {
      const { employeeId, data } = action.payload
      emEducationsAdapter.upsertOne(state.emEducations[employeeId], data)
    },
    replaceEmEducations: (state, action: PayloadAction<{ employeeId: string; data: EmEducationState[] }>) => {
      const { employeeId, data } = action.payload
      emEducationsAdapter.setAll(state.emEducations[employeeId], data)
    },
    removeEmEducation: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emEducationsAdapter.removeOne(state.emEducations[employeeId], id)
    },

    // EmCerts
    setEmCertsLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emCerts[employeeId] = state.emCerts[employeeId] || emCertsInitialState
      state.emCertsLoading[employeeId] = loading
    },
    setEmCert: (state, action: PayloadAction<{ employeeId: string; data: EmCertState }>) => {
      const { employeeId, data } = action.payload
      emCertsAdapter.upsertOne(state.emCerts[employeeId], data)
    },
    replaceEmCerts: (state, action: PayloadAction<{ employeeId: string; data: EmCertState[] }>) => {
      const { employeeId, data } = action.payload
      emCertsAdapter.setAll(state.emCerts[employeeId], data)
    },
    removeEmCert: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emCertsAdapter.removeOne(state.emCerts[employeeId], id)
    },

    // EmDocuments
    setEmDocumentsLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emDocuments[employeeId] = state.emDocuments[employeeId] || emDocumentsInitialState
      state.emDocumentsLoading[employeeId] = loading
    },
    setEmDocument: (state, action: PayloadAction<{ employeeId: string; data: EmDocumentState }>) => {
      const { employeeId, data } = action.payload
      emDocumentsAdapter.upsertOne(state.emDocuments[employeeId], data)
    },
    replaceEmDocuments: (state, action: PayloadAction<{ employeeId: string; data: EmDocumentState[] }>) => {
      const { employeeId, data } = action.payload
      emDocumentsAdapter.setAll(state.emDocuments[employeeId], data)
    },
    removeEmDocument: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emDocumentsAdapter.removeOne(state.emDocuments[employeeId], id)
    },

    // EmCalendars
    setEmCalendarsLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emCalendars[employeeId] = state.emCalendars[employeeId] || emCalendarsInitialState
      state.emCalendarsLoading[employeeId] = loading
    },
    setEmCalendar: (state, action: PayloadAction<{ employeeId: string; data: EmCalendarState }>) => {
      const { employeeId, data } = action.payload
      emCalendarsAdapter.upsertOne(state.emCalendars[employeeId], data)
    },
    replaceEmCalendars: (state, action: PayloadAction<{ employeeId: string; data: EmCalendarState[] }>) => {
      const { employeeId, data } = action.payload
      emCalendarsAdapter.setAll(state.emCalendars[employeeId], data)
    },
    removeEmCalendar: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emCalendarsAdapter.removeOne(state.emCalendars[employeeId], id)
    },

    // EmSalaries
    setEmSalariesLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emSalaries[employeeId] = state.emSalaries[employeeId] || emSalariesInitialState
      state.emSalariesLoading[employeeId] = loading
    },
    setEmSalary: (state, action: PayloadAction<{ employeeId: string; data: EmSalaryState }>) => {
      const { employeeId, data } = action.payload
      emSalariesAdapter.upsertOne(state.emSalaries[employeeId], data)
    },
    replaceEmSalaries: (state, action: PayloadAction<{ employeeId: string; data: EmSalaryState[] }>) => {
      const { employeeId, data } = action.payload
      emSalariesAdapter.setAll(state.emSalaries[employeeId], data)
    },
    removeEmSalary: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emSalariesAdapter.removeOne(state.emSalaries[employeeId], id)
    },

    // EmPayGroups
    setEmPayGroupsLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emPayGroups[employeeId] = state.emPayGroups[employeeId] || emPayGroupsInitialState
      state.emPayGroupsLoading[employeeId] = loading
    },
    setEmPayGroup: (state, action: PayloadAction<{ employeeId: string; data: EmPayGroupState }>) => {
      const { employeeId, data } = action.payload
      emPayGroupsAdapter.upsertOne(state.emPayGroups[employeeId], data)
    },
    replaceEmPayGroups: (state, action: PayloadAction<{ employeeId: string; data: EmPayGroupState[] }>) => {
      const { employeeId, data } = action.payload
      emPayGroupsAdapter.setAll(state.emPayGroups[employeeId], data)
    },
    removeEmPayGroup: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emPayGroupsAdapter.removeOne(state.emPayGroups[employeeId], id)
    },

    // EmLeaveGroups
    setEmLeaveGroupsLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emLeaveGroups[employeeId] = state.emLeaveGroups[employeeId] || emLeaveGroupsInitialState
      state.emLeaveGroupsLoading[employeeId] = loading
    },
    setEmLeaveGroup: (state, action: PayloadAction<{ employeeId: string; data: EmLeaveGroupState }>) => {
      const { employeeId, data } = action.payload
      emLeaveGroupsAdapter.upsertOne(state.emLeaveGroups[employeeId], data)
    },
    replaceEmLeaveGroups: (state, action: PayloadAction<{ employeeId: string; data: EmLeaveGroupState[] }>) => {
      const { employeeId, data } = action.payload
      emLeaveGroupsAdapter.setAll(state.emLeaveGroups[employeeId], data)
    },
    removeEmLeaveGroup: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emLeaveGroupsAdapter.removeOne(state.emLeaveGroups[employeeId], id)
    },

    // EmLeaveBuddies
    replaceEmLeaveBuddies: (state, action: PayloadAction<{ employeeId: string; data: EmLeaveBuddyState[] }>) => {
      const { employeeId, data } = action.payload
      emLeaveBuddiesAdapter.setAll(state.emLeaveBuddies[employeeId], data)
    },
    setEmLeaveBuddiesLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emLeaveBuddies[employeeId] = state.emLeaveBuddies[employeeId] || emLeaveBuddiesInitialState
      state.emLeaveBuddiesLoading[employeeId] = loading
    },

    // EmProjects
    replaceEmProjects: (state, action: PayloadAction<{ employeeId: string; data: EmProjectState[] }>) => {
      const { employeeId, data } = action.payload
      emProjectsAdapter.setAll(state.emProjects[employeeId], data)
    },
    setEmProjectsLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emProjects[employeeId] = state.emProjects[employeeId] || emProjectsInitialState
      state.emProjectsLoading[employeeId] = loading
    },

    // EmLocations
    setEmLocationsLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emLocations[employeeId] = state.emLocations[employeeId] || emLocationsInitialState
      state.emLocationsLoading[employeeId] = loading
    },
    setEmLocation: (state, action: PayloadAction<{ employeeId: string; data: EmLocationState }>) => {
      const { employeeId, data } = action.payload
      emLocationsAdapter.upsertOne(state.emLocations[employeeId], data)
    },
    replaceEmLocations: (state, action: PayloadAction<{ employeeId: string; data: EmLocationState[] }>) => {
      const { employeeId, data } = action.payload
      emLocationsAdapter.setAll(state.emLocations[employeeId], data)
    },
    removeEmLocation: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emLocationsAdapter.removeOne(state.emLocations[employeeId], id)
    },

    // EmAttendanceGroups
    setEmAttendanceGroupsLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emAttendanceGroups[employeeId] = state.emAttendanceGroups[employeeId] || emAttendanceGroupsInitialState
      state.emAttendanceGroupsLoading[employeeId] = loading
    },
    setEmAttendanceGroup: (state, action: PayloadAction<{ employeeId: string; data: EmAttendanceGroupState }>) => {
      const { employeeId, data } = action.payload
      emAttendanceGroupsAdapter.upsertOne(state.emAttendanceGroups[employeeId], data)
    },
    replaceEmAttendanceGroups: (
      state,
      action: PayloadAction<{ employeeId: string; data: EmAttendanceGroupState[] }>
    ) => {
      const { employeeId, data } = action.payload
      emAttendanceGroupsAdapter.setAll(state.emAttendanceGroups[employeeId], data)
    },
    removeEmAttendanceGroup: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emAttendanceGroupsAdapter.removeOne(state.emAttendanceGroups[employeeId], id)
    },

    // EmShiftRoles
    setEmShiftRolesLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emShiftRoles[employeeId] = state.emShiftRoles[employeeId] || emShiftRolesInitialState
      state.emShiftRolesLoading[employeeId] = loading
    },
    setEmShiftRole: (state, action: PayloadAction<{ employeeId: string; data: EmShiftRoleState }>) => {
      const { employeeId, data } = action.payload
      emShiftRolesAdapter.upsertOne(state.emShiftRoles[employeeId], data)
    },
    replaceEmShiftRoles: (state, action: PayloadAction<{ employeeId: string; data: EmShiftRoleState[] }>) => {
      const { employeeId, data } = action.payload
      emShiftRolesAdapter.setAll(state.emShiftRoles[employeeId], data)
    },
    removeEmShiftRole: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emShiftRolesAdapter.removeOne(state.emShiftRoles[employeeId], id)
    },

    // EmCompanies
    setEmCompaniesLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emCompanies[employeeId] = state.emCompanies[employeeId] || emCompaniesInitialState
      state.emCompaniesLoading[employeeId] = loading
    },
    setEmCompany: (state, action: PayloadAction<{ employeeId: string; data: EmCompanyState }>) => {
      const { employeeId, data } = action.payload
      emCompaniesAdapter.upsertOne(state.emCompanies[employeeId], data)
    },
    replaceEmCompanies: (state, action: PayloadAction<{ employeeId: string; data: EmCompanyState[] }>) => {
      const { employeeId, data } = action.payload
      emCompaniesAdapter.setAll(state.emCompanies[employeeId], data)
    },
    removeEmCompany: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emCompaniesAdapter.removeOne(state.emCompanies[employeeId], id)
    },

    // EmDepartments
    setEmDepartmentsLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emDepartments[employeeId] = state.emDepartments[employeeId] || emDepartmentsInitialState
      state.emDepartmentsLoading[employeeId] = loading
    },
    setEmDepartment: (state, action: PayloadAction<{ employeeId: string; data: EmDepartmentState }>) => {
      const { employeeId, data } = action.payload
      emDepartmentsAdapter.upsertOne(state.emDepartments[employeeId], data)
    },
    replaceEmDepartments: (state, action: PayloadAction<{ employeeId: string; data: EmDepartmentState[] }>) => {
      const { employeeId, data } = action.payload
      emDepartmentsAdapter.setAll(state.emDepartments[employeeId], data)
    },
    removeEmDepartment: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emDepartmentsAdapter.removeOne(state.emDepartments[employeeId], id)
    },

    // EmDivisions
    setEmDivisionsLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emDivisions[employeeId] = state.emDivisions[employeeId] || emDivisionsInitialState
      state.emDivisionsLoading[employeeId] = loading
    },
    setEmDivision: (state, action: PayloadAction<{ employeeId: string; data: EmDivisionState }>) => {
      const { employeeId, data } = action.payload
      emDivisionsAdapter.upsertOne(state.emDivisions[employeeId], data)
    },
    replaceEmDivisions: (state, action: PayloadAction<{ employeeId: string; data: EmDivisionState[] }>) => {
      const { employeeId, data } = action.payload
      emDivisionsAdapter.setAll(state.emDivisions[employeeId], data)
    },
    removeEmDivision: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emDivisionsAdapter.removeOne(state.emDivisions[employeeId], id)
    },

    // EmSections
    setEmSectionsLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emSections[employeeId] = state.emSections[employeeId] || emSectionsInitialState
      state.emSectionsLoading[employeeId] = loading
    },
    setEmSection: (state, action: PayloadAction<{ employeeId: string; data: EmSectionState }>) => {
      const { employeeId, data } = action.payload
      emSectionsAdapter.upsertOne(state.emSections[employeeId], data)
    },
    replaceEmSections: (state, action: PayloadAction<{ employeeId: string; data: EmSectionState[] }>) => {
      const { employeeId, data } = action.payload
      emSectionsAdapter.setAll(state.emSections[employeeId], data)
    },
    removeEmSection: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emSectionsAdapter.removeOne(state.emSections[employeeId], id)
    },

    // EmGrps
    setEmGrpsLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emGrps[employeeId] = state.emGrps[employeeId] || emGrpsInitialState
      state.emGrpsLoading[employeeId] = loading
    },
    setEmGrp: (state, action: PayloadAction<{ employeeId: string; data: EmGrpState }>) => {
      const { employeeId, data } = action.payload
      emGrpsAdapter.upsertOne(state.emGrps[employeeId], data)
    },
    replaceEmGrps: (state, action: PayloadAction<{ employeeId: string; data: EmGrpState[] }>) => {
      const { employeeId, data } = action.payload
      emGrpsAdapter.setAll(state.emGrps[employeeId], data)
    },
    removeEmGrp: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emGrpsAdapter.removeOne(state.emGrps[employeeId], id)
    },

    // EmCategories
    setEmCategoriesLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emCategories[employeeId] = state.emCategories[employeeId] || emCategoriesInitialState
      state.emCategoriesLoading[employeeId] = loading
    },
    setEmCategory: (state, action: PayloadAction<{ employeeId: string; data: EmCategoryState }>) => {
      const { employeeId, data } = action.payload
      emCategoriesAdapter.upsertOne(state.emCategories[employeeId], data)
    },
    replaceEmCategories: (state, action: PayloadAction<{ employeeId: string; data: EmCategoryState[] }>) => {
      const { employeeId, data } = action.payload
      emCategoriesAdapter.setAll(state.emCategories[employeeId], data)
    },
    removeEmCategory: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emCategoriesAdapter.removeOne(state.emCategories[employeeId], id)
    },

    // EmTeams
    setEmTeamsLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emTeams[employeeId] = state.emTeams[employeeId] || emTeamsInitialState
      state.emTeamsLoading[employeeId] = loading
    },
    setEmTeam: (state, action: PayloadAction<{ employeeId: string; data: EmTeamState }>) => {
      const { employeeId, data } = action.payload
      emTeamsAdapter.upsertOne(state.emTeams[employeeId], data)
    },
    replaceEmTeams: (state, action: PayloadAction<{ employeeId: string; data: EmTeamState[] }>) => {
      const { employeeId, data } = action.payload
      emTeamsAdapter.setAll(state.emTeams[employeeId], data)
    },
    removeEmTeam: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emTeamsAdapter.removeOne(state.emTeams[employeeId], id)
    },

    // EmJobs
    setEmJobsLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emJobs[employeeId] = state.emJobs[employeeId] || emJobsInitialState
      state.emJobsLoading[employeeId] = loading
    },
    setEmJob: (state, action: PayloadAction<{ employeeId: string; data: EmJobState }>) => {
      const { employeeId, data } = action.payload
      emJobsAdapter.upsertOne(state.emJobs[employeeId], data)
    },
    replaceEmJobs: (state, action: PayloadAction<{ employeeId: string; data: EmJobState[] }>) => {
      const { employeeId, data } = action.payload
      emJobsAdapter.setAll(state.emJobs[employeeId], data)
    },
    removeEmJob: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emJobsAdapter.removeOne(state.emJobs[employeeId], id)
    },

    // EmManagers
    setEmManagersLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emManagers[employeeId] = state.emManagers[employeeId] || emManagersInitialState
      state.emManagersLoading[employeeId] = loading
    },
    setEmManager: (state, action: PayloadAction<{ employeeId: string; data: EmManagerState }>) => {
      const { employeeId, data } = action.payload
      emManagersAdapter.upsertOne(state.emManagers[employeeId], data)
    },
    replaceEmManagers: (state, action: PayloadAction<{ employeeId: string; data: EmManagerState[] }>) => {
      const { employeeId, data } = action.payload
      emManagersAdapter.setAll(state.emManagers[employeeId], data)
    },
    removeEmManager: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emManagersAdapter.removeOne(state.emManagers[employeeId], id)
    },

    // EmManagerSecondaries
    setEmManagerSecondariesLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emManagerSecondaries[employeeId] =
        state.emManagerSecondaries[employeeId] || emManagerSecondariesInitialState
      state.emManagerSecondariesLoading[employeeId] = loading
    },
    setEmManagerSecondary: (state, action: PayloadAction<{ employeeId: string; data: EmManagerSecondaryState }>) => {
      const { employeeId, data } = action.payload
      emManagerSecondariesAdapter.upsertOne(state.emManagerSecondaries[employeeId], data)
    },
    replaceEmManagerSecondaries: (
      state,
      action: PayloadAction<{ employeeId: string; data: EmManagerSecondaryState[] }>
    ) => {
      const { employeeId, data } = action.payload
      emManagerSecondariesAdapter.setAll(state.emManagerSecondaries[employeeId], data)
    },
    removeEmManagerSecondary: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emManagerSecondariesAdapter.removeOne(state.emManagerSecondaries[employeeId], id)
    },

    // EmOffices
    setEmOfficesLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emOffices[employeeId] = state.emOffices[employeeId] || emOfficesInitialState
      state.emOfficesLoading[employeeId] = loading
    },
    setEmOffice: (state, action: PayloadAction<{ employeeId: string; data: EmOfficeState }>) => {
      const { employeeId, data } = action.payload
      emOfficesAdapter.upsertOne(state.emOffices[employeeId], data)
    },
    replaceEmOffices: (state, action: PayloadAction<{ employeeId: string; data: EmOfficeState[] }>) => {
      const { employeeId, data } = action.payload
      emOfficesAdapter.setAll(state.emOffices[employeeId], data)
    },
    removeEmOffice: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emOfficesAdapter.removeOne(state.emOffices[employeeId], id)
    },

    // EmCostCentres
    setEmCostCentresLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emCostCentres[employeeId] = state.emCostCentres[employeeId] || emCostCentresInitialState
      state.emCostCentresLoading[employeeId] = loading
    },
    setEmCostCentre: (state, action: PayloadAction<{ employeeId: string; data: EmCostCentreState }>) => {
      const { employeeId, data } = action.payload
      emCostCentresAdapter.upsertOne(state.emCostCentres[employeeId], data)
    },
    replaceEmCostCentres: (state, action: PayloadAction<{ employeeId: string; data: EmCostCentreState[] }>) => {
      const { employeeId, data } = action.payload
      emCostCentresAdapter.setAll(state.emCostCentres[employeeId], data)
    },
    removeEmCostCentre: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emCostCentresAdapter.removeOne(state.emCostCentres[employeeId], id)
    },

    // EmEmploymentTypes
    setEmEmploymentTypesLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emEmploymentTypes[employeeId] = state.emEmploymentTypes[employeeId] || emEmploymentTypesInitialState
      state.emEmploymentTypesLoading[employeeId] = loading
    },
    setEmEmploymentType: (state, action: PayloadAction<{ employeeId: string; data: EmEmploymentTypeState }>) => {
      const { employeeId, data } = action.payload
      emEmploymentTypesAdapter.upsertOne(state.emEmploymentTypes[employeeId], data)
    },
    replaceEmEmploymentTypes: (state, action: PayloadAction<{ employeeId: string; data: EmEmploymentTypeState[] }>) => {
      const { employeeId, data } = action.payload
      emEmploymentTypesAdapter.setAll(state.emEmploymentTypes[employeeId], data)
    },
    removeEmEmploymentType: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emEmploymentTypesAdapter.removeOne(state.emEmploymentTypes[employeeId], id)
    },

    // EmOtClasses
    setEmOtClassesLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emOtClasses[employeeId] = state.emOtClasses[employeeId] || emOtClassesInitialState
      state.emOtClassesLoading[employeeId] = loading
    },
    setEmOtClass: (state, action: PayloadAction<{ employeeId: string; data: EmOtClassState }>) => {
      const { employeeId, data } = action.payload
      emOtClassesAdapter.upsertOne(state.emOtClasses[employeeId], data)
    },
    replaceEmOtClasses: (state, action: PayloadAction<{ employeeId: string; data: EmOtClassState[] }>) => {
      const { employeeId, data } = action.payload
      emOtClassesAdapter.setAll(state.emOtClasses[employeeId], data)
    },
    removeEmOtClass: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emOtClassesAdapter.removeOne(state.emOtClasses[employeeId], id)
    },

    // EmStatutoryExempts
    setEmStatutoryExemptsLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emStatutoryExempts[employeeId] = state.emStatutoryExempts[employeeId] || emStatutoryExemptInitialState
      state.emStatutoryExemptsLoading[employeeId] = loading
    },
    setEmStatutoryExempt: (state, action: PayloadAction<{ employeeId: string; data: EmStatutoryExemptState }>) => {
      const { employeeId, data } = action.payload
      emStatutoryExemptsAdapter.upsertOne(state.emStatutoryExempts[employeeId], data)
    },
    replaceEmStatutoryExempts: (
      state,
      action: PayloadAction<{ employeeId: string; data: EmStatutoryExemptState[] }>
    ) => {
      const { employeeId, data } = action.payload
      emStatutoryExemptsAdapter.setAll(state.emStatutoryExempts[employeeId], data)
    },
    removeEmStatutoryExempt: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emStatutoryExemptsAdapter.removeOne(state.emStatutoryExempts[employeeId], id)
    },

    // EmDirectory
    setEmDirectoryView: (state, action: PayloadAction<EmDirectoryViewState>) => {
      emDirectoryViewAdapter.upsertOne(state.emDirectoryView, action.payload)
    },
    setEmDirectoryViewLoading: (state, action: PayloadAction<boolean>) => {
      state.emDirectoryViewLoading = action.payload
    },
    setEmDirectoryViewExpanded: (state, action: PayloadAction<boolean>) => {
      state.emDirectoryViewExpanded = action.payload
    },
    refetchEmDirectoryView: state => {
      state.emDirectoryViewRefetch += 1
    },

    // EmSelection
    setEmSelection: (state, action: PayloadAction<EmSelectionViewState>) => {
      emSelectionAdapter.upsertOne(state.emSelection, action.payload)
    },
    setEmSelectionLoading: (state, action: PayloadAction<boolean>) => {
      state.emSelectionLoading = action.payload
    },

    replacePermittedEmployeePay: (state, action: PayloadAction<string[]>) => {
      state.permittedEmployeePay = action.payload
    },
    replacePermittedEmployeeLve: (state, action: PayloadAction<string[]>) => {
      state.permittedEmployeeLve = action.payload
    },
    replacePermittedEmployeeAtt: (state, action: PayloadAction<string[]>) => {
      state.permittedEmployeeAtt = action.payload
    },
    setRefreshEmployeeLoading: (state, action: PayloadAction<boolean>) => {
      state.refreshEmployeeLoading = action.payload
    },
    replaceEmployeeImport: (state, action: PayloadAction<EmployeeImportState>) => {
      state.employeeImport = action.payload
    },

    // EmFieldValues
    setEmFieldValuesLoading: (state, action: PayloadAction<{ field: string; loading: boolean }>) => {
      state.emFieldValuesLoading[action.payload.field] = action.payload.loading
    },
    replaceEmFieldValues: (state, action: PayloadAction<{ field: string; data: string[] }>) => {
      state.emFieldValues[action.payload.field] = action.payload.data
    },

    // EmPreference
    setEmPreference: (state, action: PayloadAction<EmPreferenceState>) => {
      emPreferenceAdapter.upsertOne(state.emPreference, action.payload)
    },
    setEmPreferenceLoading: (state, action: PayloadAction<boolean>) => {
      state.emPreferenceLoading = action.payload
    },

    // EmRequests
    setEmRequestsLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emRequests[employeeId] = state.emRequests[employeeId] || emRequestsInitialState
      state.emRequestsLoading[employeeId] = loading
    },
    replaceEmRequests: (state, action: PayloadAction<{ employeeId: string; data: EmRequestState[] }>) => {
      const { employeeId, data } = action.payload
      emRequestsAdapter.setAll(state.emRequests[employeeId], data)
    },
    removeEmRequest: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emRequestsAdapter.removeOne(state.emRequests[employeeId], id)
    },

    // EmIdentityRequests
    setEmIdentityRequestsLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emIdentityRequests[employeeId] = state.emIdentityRequests[employeeId] || emIdentityRequestsInitialState
      state.emIdentityRequestsLoading[employeeId] = loading
    },
    replaceEmIdentityRequests: (
      state,
      action: PayloadAction<{ employeeId: string; data: EmIdentityRequestState[] }>
    ) => {
      const { employeeId, data } = action.payload
      emIdentityRequestsAdapter.setAll(state.emIdentityRequests[employeeId], data)
    },
    removeEmIdentityRequest: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emIdentityRequestsAdapter.removeOne(state.emIdentityRequests[employeeId], id)
    },

    // EmAddressRequests
    setEmAddressRequestsLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emAddressRequests[employeeId] = state.emAddressRequests[employeeId] || emAddressRequestsInitialState
      state.emAddressRequestsLoading[employeeId] = loading
    },
    replaceEmAddressRequests: (state, action: PayloadAction<{ employeeId: string; data: EmAddressRequestState[] }>) => {
      const { employeeId, data } = action.payload
      emAddressRequestsAdapter.setAll(state.emAddressRequests[employeeId], data)
    },
    removeEmAddressRequest: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emAddressRequestsAdapter.removeOne(state.emAddressRequests[employeeId], id)
    },

    // EmEducationRequests
    setEmEducationRequestsLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emEducationRequests[employeeId] = state.emEducationRequests[employeeId] || emEducationRequestsInitialState
      state.emEducationRequestsLoading[employeeId] = loading
    },
    replaceEmEducationRequests: (
      state,
      action: PayloadAction<{ employeeId: string; data: EmEducationRequestState[] }>
    ) => {
      const { employeeId, data } = action.payload
      emEducationRequestsAdapter.setAll(state.emEducationRequests[employeeId], data)
    },
    removeEmEducationRequest: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emEducationRequestsAdapter.removeOne(state.emEducationRequests[employeeId], id)
    },

    // EmFamilyRequests
    setEmFamilyRequestsLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emFamilyRequests[employeeId] = state.emFamilyRequests[employeeId] || emFamilyRequestsInitialState
      state.emFamilyRequestsLoading[employeeId] = loading
    },
    replaceEmFamilyRequests: (state, action: PayloadAction<{ employeeId: string; data: EmFamilyRequestState[] }>) => {
      const { employeeId, data } = action.payload
      emFamilyRequestsAdapter.setAll(state.emFamilyRequests[employeeId], data)
    },
    removeEmFamilyRequest: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emFamilyRequestsAdapter.removeOne(state.emFamilyRequests[employeeId], id)
    },

    // EmCertRequests
    setEmCertRequestsLoading: (state, action: PayloadAction<{ employeeId: string; loading: boolean }>) => {
      const { employeeId, loading } = action.payload
      state.emCertRequests[employeeId] = state.emCertRequests[employeeId] || emCertRequestsInitialState
      state.emCertRequestsLoading[employeeId] = loading
    },
    replaceEmCertRequests: (state, action: PayloadAction<{ employeeId: string; data: EmCertRequestState[] }>) => {
      const { employeeId, data } = action.payload
      emCertRequestsAdapter.setAll(state.emCertRequests[employeeId], data)
    },
    removeEmCertRequest: (state, action: PayloadAction<{ employeeId: string; id: string }>) => {
      const { employeeId, id } = action.payload
      emCertRequestsAdapter.removeOne(state.emCertRequests[employeeId], id)
    },

    // EmDashboard
    setChartSummary: (state, action: PayloadAction<{ chartId: ChartId; data: ChartSummaryState }>) => {
      const { chartId, data } = action.payload
      state.chartSummaries[chartId] = data
    },
    setChartSummaryLoading: (state, action: PayloadAction<{ chartId: ChartId; loading: boolean }>) => {
      const { chartId, loading } = action.payload
      state.chartSummariesLoading[chartId] = loading
    },
    setHeadcountGroupBy: (state, action: PayloadAction<{ key: string; data: PieChartState }>) => {
      const { key, data } = action.payload
      state.headcountGroupBy[key] = data
    },
    setHeadcountGroupByLoading: (state, action: PayloadAction<{ key: string; loading: boolean }>) => {
      const { key, loading } = action.payload
      state.headcountGroupByLoading[key] = loading
    },
    setChartServiceLen: (state, action: PayloadAction<BarChartState>) => {
      state.chartServiceLen = action.payload
    },
    setChartServiceLenLoading: (state, action: PayloadAction<boolean>) => {
      state.chartServiceLenLoading = action.payload
    },

    // Apex for OED
    setOedApexState: (state, action: PayloadAction<string>) => {
      state.oedApexState = action.payload
      state.oedApexStateTimestamp = new Date().getTime()
    },
    setOedSubmissionId: (state, action: PayloadAction<string>) => {
      state.oedSubmissionId = action.payload
    }
  }
})

export const {
  // EmKeyValues
  setEmKeyValuesLoading,
  setEmKeyValue,
  replaceEmKeyValues,
  removeEmKeyValue,

  // EmPublic
  setEmPublicPersonsLoading,
  setEmPublicPerson,
  setEmPublicProfilesLoading,
  setEmPublicProfile,
  setEmPublicLoading,
  setEmPublic,

  // Employees
  replaceEmployees,
  setEmployee,
  removeEmployee,
  setEmployeesLoading,
  refetchEmployees,

  // EmHires
  replaceEmHires,
  setEmHiresLoading,

  // EmIdentities
  replaceEmIdentities,
  setEmIdentity,
  removeEmIdentity,
  setEmIdentitiesLoading,

  // EmAddresses
  replaceEmAddresses,
  setEmAddress,
  removeEmAddress,
  setEmAddressesLoading,

  // EmFamilies
  replaceEmFamilies,
  setEmFamily,
  removeEmFamily,
  setEmFamiliesLoading,

  // EmEducations
  replaceEmEducations,
  setEmEducation,
  removeEmEducation,
  setEmEducationsLoading,

  // EmCerts
  replaceEmCerts,
  setEmCert,
  removeEmCert,
  setEmCertsLoading,

  // EmDocuments
  replaceEmDocuments,
  setEmDocument,
  removeEmDocument,
  setEmDocumentsLoading,

  // EmCalendars
  replaceEmCalendars,
  setEmCalendar,
  removeEmCalendar,
  setEmCalendarsLoading,

  // EmSalaries
  replaceEmSalaries,
  setEmSalary,
  removeEmSalary,
  setEmSalariesLoading,

  // EmPayGroups
  replaceEmPayGroups,
  setEmPayGroup,
  removeEmPayGroup,
  setEmPayGroupsLoading,

  // EmLeaveGroups
  replaceEmLeaveGroups,
  setEmLeaveGroup,
  removeEmLeaveGroup,
  setEmLeaveGroupsLoading,

  // EmLeaveBuddies
  replaceEmLeaveBuddies,
  setEmLeaveBuddiesLoading,

  // EmProjects
  replaceEmProjects,
  setEmProjectsLoading,

  // EmLocations
  replaceEmLocations,
  setEmLocation,
  removeEmLocation,
  setEmLocationsLoading,

  // EmAttendanceGroups
  replaceEmAttendanceGroups,
  setEmAttendanceGroup,
  removeEmAttendanceGroup,
  setEmAttendanceGroupsLoading,

  // EmShiftRoles
  replaceEmShiftRoles,
  setEmShiftRole,
  removeEmShiftRole,
  setEmShiftRolesLoading,

  // EmCompanies
  replaceEmCompanies,
  setEmCompany,
  removeEmCompany,
  setEmCompaniesLoading,

  // EmDepartments
  replaceEmDepartments,
  setEmDepartment,
  removeEmDepartment,
  setEmDepartmentsLoading,

  // EmDivisions
  replaceEmDivisions,
  setEmDivision,
  removeEmDivision,
  setEmDivisionsLoading,

  // EmSections
  replaceEmSections,
  setEmSection,
  removeEmSection,
  setEmSectionsLoading,

  // EmGrps
  replaceEmGrps,
  setEmGrp,
  removeEmGrp,
  setEmGrpsLoading,

  // EmCategories
  replaceEmCategories,
  setEmCategory,
  removeEmCategory,
  setEmCategoriesLoading,

  // EmTeams
  replaceEmTeams,
  setEmTeam,
  removeEmTeam,
  setEmTeamsLoading,

  // EmJobs
  replaceEmJobs,
  setEmJob,
  removeEmJob,
  setEmJobsLoading,

  // EmManagers
  replaceEmManagers,
  setEmManager,
  removeEmManager,
  setEmManagersLoading,

  // EmManagerSecondaries
  replaceEmManagerSecondaries,
  setEmManagerSecondary,
  removeEmManagerSecondary,
  setEmManagerSecondariesLoading,

  // EmOffices
  replaceEmOffices,
  setEmOffice,
  removeEmOffice,
  setEmOfficesLoading,

  // EmCostCentre
  replaceEmCostCentres,
  setEmCostCentre,
  removeEmCostCentre,
  setEmCostCentresLoading,

  // EmEmploymentTypes
  replaceEmEmploymentTypes,
  setEmEmploymentType,
  removeEmEmploymentType,
  setEmEmploymentTypesLoading,

  // EmOtClasses
  replaceEmOtClasses,
  setEmOtClass,
  removeEmOtClass,
  setEmOtClassesLoading,

  // EmStatutoryExempts
  replaceEmStatutoryExempts,
  setEmStatutoryExempt,
  removeEmStatutoryExempt,
  setEmStatutoryExemptsLoading,

  // EmDirectoryView
  setEmDirectoryView,
  setEmDirectoryViewLoading,
  setEmDirectoryViewExpanded,
  refetchEmDirectoryView,

  // EmSelection
  setEmSelection,
  setEmSelectionLoading,

  replacePermittedEmployeePay,
  replacePermittedEmployeeLve,
  replacePermittedEmployeeAtt,
  setRefreshEmployeeLoading,
  replaceEmployeeImport,

  // EmFieldValues
  setEmFieldValuesLoading,
  replaceEmFieldValues,

  // EmPreference
  setEmPreference,
  setEmPreferenceLoading,

  // EmRequests
  replaceEmRequests,
  setEmRequestsLoading,
  removeEmRequest,

  // EmIdentityRequests
  replaceEmIdentityRequests,
  setEmIdentityRequestsLoading,
  removeEmIdentityRequest,

  // EmAddressRequests
  replaceEmAddressRequests,
  setEmAddressRequestsLoading,
  removeEmAddressRequest,

  // EmEducationRequests
  replaceEmEducationRequests,
  setEmEducationRequestsLoading,
  removeEmEducationRequest,

  // EmFamilyRequests
  replaceEmFamilyRequests,
  setEmFamilyRequestsLoading,
  removeEmFamilyRequest,

  // EmCertRequests
  replaceEmCertRequests,
  setEmCertRequestsLoading,
  removeEmCertRequest,

  // EmDashboard
  setChartSummary,
  setChartSummaryLoading,
  setHeadcountGroupBy,
  setHeadcountGroupByLoading,
  setChartServiceLen,
  setChartServiceLenLoading,

  // Apex for OED
  setOedApexState,
  setOedSubmissionId
} = employeeSlice.actions

export const { selectById: selectEmployeeById } = employeesAdapter.getSelectors(
  (state: StoreState) => state.employee.employees
)

export const { selectById: selectEmDirectoryViewById } = emDirectoryViewAdapter.getSelectors(
  (state: StoreState) => state.employee.emDirectoryView
)

export const { selectById: selectEmSelectionById } = emSelectionAdapter.getSelectors(
  (state: StoreState) => state.employee.emSelection
)

export const { selectById: selectEmPublicById } = emPublicAdapter.getSelectors(
  (state: StoreState) => state.employee.emPublic
)

export const employeeReducers = {
  employee: persistReducer<EmployeeRootState>(employeePersistConfig, employeeSlice.reducer)
}

import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Dictionary } from '@reduxjs/toolkit'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { useHasChange } from '~/hooks'
import { StoreState } from '~/types/store'
import { fetchSysDailyPolicyFields } from '../actions'
import { SysDailyPolicyFieldState } from '../types'

export const useSysDailyPolicyFieldsDict = (
  fetchStrategy: FetchStrategy = 'when-empty'
): [Dictionary<SysDailyPolicyFieldState>, boolean] => {
  const data = useSelector((state: StoreState) => state.attendance.sysDailyPolicyFields.entities)
  const loading = useSelector((state: StoreState) => state.attendance.sysDailyPolicyFieldsLoading)
  const isRefetch = useHasChange((state: StoreState) => state.attendance.sysDailyPolicyFieldsRefetch)
  const strategy = isRefetch ? 'always' : fetchStrategy

  useEffect(() => {
    dispatch(fetchSysDailyPolicyFields({ strategy }))
  }, [isRefetch, strategy])

  return [data, loading]
}

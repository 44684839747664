import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import confirm from 'antd/lib/modal/confirm'
import { Checkbox, CheckboxChangeEvent, Form, Input } from '~/core-components'
import { Col, DrawerForm, Row, SelectEmployeesTable } from '~/components'
import { CriteriaFieldId, emptyGuid, EmStatus } from '~/constants'
import { useFocus } from '~/hooks/use-focus'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { Pagination } from '~/types/common'
import { IProject, ProjectState } from '~/features/attendance/types'
import { deleteProject, fetchProjectEmSelections, saveProject } from '~/features/attendance/actions'
import { useProjectEm } from '~/features/attendance/hooks'
import { isInactive } from '~/utils/dateUtil'

export interface MutateProjectDrawerProps {
  visible: boolean
  data?: ProjectState
  onClose: () => void
}

interface FormData extends IProject {}

export const EMPTY_FORM_DATA: FormData = {
  id: emptyGuid,
  name: '',
  inactiveDate: '',
  employeeIds: []
}

export const MutateProjectDrawer: FC<MutateProjectDrawerProps> = ({ visible, data, onClose }) => {
  const [focusRef, setFocus] = useFocus(true)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [projectEm] = useProjectEm(data?.id || '')

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  useEffect(() => {
    if (data) {
      const { id, name, inactiveDate } = data
      const employeeIds = projectEm.map(pem => pem.employeeId)
      setFormData({ id, name, inactiveDate, employeeIds })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data, projectEm])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(saveProject(formData))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [formData, onClose])

  const handleDelete = useCallback(
    (project: ProjectState | undefined) => {
      if (project) {
        const { id, name } = project
        confirm({
          title: 'Delete project',
          content: `Do you want to delete this project "${name}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteProject(id))
            if (result?.errors) {
              setErrors(result.errors)
            }
            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [onClose]
  )

  const handleFetchEmployees = useCallback((viewId: string, pagination: Pagination, search: string) => {
    dispatch(fetchProjectEmSelections(viewId, pagination, search))
  }, [])

  const handleSelectEmployee = useCallback((employeeIds: string[]) => {
    setFormData(formData => ({ ...formData, employeeIds }))
  }, [])

  return (
    <DrawerForm
      open={visible}
      title={data ? `Edit project` : `Add project`}
      onClose={onClose}
      confirmLoading={loading}
      width={550}
      className="mutate-project-drawer"
      showDelete={data ? true : false}
      onDelete={() => handleDelete(data)}
      formId="form-project"
    >
      <Form id="form-project" onFinish={handleOk}>
        <Row gutter={15}>
          <Col flex="auto">
            <Form.Item label="Name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
              <Input
                value={formData.name}
                ref={focusRef}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
              />
            </Form.Item>
          </Col>
          <Col flex="none">
            <Form.Item
              label="Inactive"
              validateStatus={errors?.inactiveDate ? 'error' : ''}
              help={errors?.inactiveDate}
            >
              <Checkbox
                checked={isInactive(formData.inactiveDate)}
                onChange={(event: CheckboxChangeEvent) => {
                  handleFormDataChange({ inactiveDate: event.target.checked ? moment().format('YYYY-MM-DD') : '' })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <SelectEmployeesTable
              label="Members"
              viewName="project"
              employeeIds={formData.employeeIds || []}
              onFetchData={handleFetchEmployees}
              onSelect={handleSelectEmployee}
              resetOnClose={true}
              resetTo={[{ criteriaFieldId: CriteriaFieldId.EmStatus, value: EmStatus.Active }]}
            />
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}

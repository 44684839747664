import { Pagination, Search } from '~/types/common'
import { request, getBaseUrl, timeout } from '~/utils/request'
import {
  IYtdSubmissionNew,
  YtdSubmissionViewState,
  YtdSubmissionState,
  YtdSubmissionIr8aViewState,
  YtdSubmissionIr8sViewState,
  YtdSubmissionA8aViewState,
  YtdSubmissionA8bViewState,
  YtdSummaryState,
  IrasLogState
} from '../types'

const baseUrl = getBaseUrl('/payroll-sg/ytdsubmission')

export const apiGetYtdSubmissionView = (viewId: string, params: Pagination & Search) =>
  request<YtdSubmissionViewState>('get', `${baseUrl}/view/${viewId}`, params)

export const apiGetYtdSubmissionViewItem = (id: string) =>
  request<YtdSubmissionState>('get', `${baseUrl}/${id}/viewitem`)

export const apiGetYtdSummary = (id: string) => request<YtdSummaryState>('get', `${baseUrl}/${id}/summary`)

export const apiGetIrasLog = (ytdSubmissionId: string) =>
  request<IrasLogState>('get', `${baseUrl}/${ytdSubmissionId}/iraslog`)

export const apiAddYtdSubmission = (ytdSubmission: IYtdSubmissionNew) =>
  request<{ id: string; status: string }>('post', baseUrl, ytdSubmission)

export const apiDeleteYtdSubmission = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiGetYtdSubmissionIr8aView = (id: string, viewId: string, params: Pagination & Search) =>
  request<YtdSubmissionIr8aViewState>('get', `${baseUrl}/${id}/ir8a/view/${viewId}`, params)

export const apiGetYtdSubmissionIr8aExcel = (id: string) =>
  request('get', `${baseUrl}/${id}/ir8a/excel`, undefined, { responseType: 'blob', timeout })

export const apiGetYtdSubmissionIr8sView = (id: string, viewId: string, params: Pagination & Search) =>
  request<YtdSubmissionIr8sViewState>('get', `${baseUrl}/${id}/ir8s/view/${viewId}`, params)

export const apiGetYtdSubmissionA8aView = (id: string, viewId: string, params: Pagination & Search) =>
  request<YtdSubmissionA8aViewState>('get', `${baseUrl}/${id}/a8a/view/${viewId}`, params)

export const apiGetYtdSubmissionA8bView = (id: string, viewId: string, params: Pagination & Search) =>
  request<YtdSubmissionA8bViewState>('get', `${baseUrl}/${id}/a8b/view/${viewId}`, params)

import React, { FC, useState, useCallback, useEffect, ChangeEvent } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Form, Input } from '~/core-components'
import { Col, DrawerForm, Row, EmFieldValues } from '~/components'
import { dispatch } from '~/stores/store'
import { addEmCert, deleteEmCert, fetchEmCertField, updateEmCert } from '../../../actions'
import { IEmCertInfo, EmCertState } from '../../../types'
import { mapEmCertStateToEmCertInfo } from '../../../types/em-details.mapper'
import { ActionResult, Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'

export interface MutateEmCertDrawerProps {
  visible: boolean
  employeeId?: string
  data?: EmCertState
  onClose: () => void
}

const EMPTY_FORM_DATA: IEmCertInfo = {
  name: '',
  issuedBy: '',
  issueDate: '',
  expiryDate: '',
  credentialId: '',
  credentialUrl: '',
  notes: ''
}

export const MutateEmCertDrawer: FC<MutateEmCertDrawerProps> = ({
  visible,
  employeeId,
  data,
  onClose
}: MutateEmCertDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IEmCertInfo>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      const { name, issuedBy, issueDate, expiryDate, credentialId, credentialUrl, notes } = data
      setFormData({ name, issuedBy, issueDate, expiryDate, credentialId, credentialUrl, notes })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  useEffect(() => {
    if (visible) {
      dispatch(fetchEmCertField('name'))
      dispatch(fetchEmCertField('issued_by'))
    }
  }, [visible])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (data) {
        result = await dispatch(updateEmCert(data.employeeId, data.id, mapEmCertStateToEmCertInfo(data), formData))
      } else if (employeeId) {
        result = await dispatch(addEmCert(employeeId, formData))
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [employeeId, data, formData, onClose])

  const handleDelete = useCallback(
    (emCert: EmCertState | undefined) => {
      if (employeeId && emCert) {
        const { id, name } = emCert
        confirm({
          title: 'Delete license & certificate',
          content: `Do you want to delete license & certificate record of "${name}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteEmCert(employeeId, id))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [employeeId, onClose]
  )

  return (
    <DrawerForm
      open={visible}
      title={data ? 'Edit license & certificate' : 'Add license & certificate'}
      onClose={onClose}
      confirmLoading={loading}
      width={650}
      showDelete={data ? true : false}
      onDelete={() => handleDelete(data)}
      formId="form-em-cert"
    >
      <Form id="form-em-cert" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
              <EmFieldValues
                ref={focusRef}
                field="name"
                value={formData.name}
                onChange={(name?: string) => handleFormDataChange({ name })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Issuing organisation"
              validateStatus={errors?.issuedBy ? 'error' : ''}
              help={errors?.issuedBy}
            >
              <EmFieldValues
                field="issued_by"
                value={formData.issuedBy}
                onChange={(issuedBy?: string) => handleFormDataChange({ issuedBy })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="Issue date" validateStatus={errors?.issueDate ? 'error' : ''} help={errors?.issueDate}>
              <Input.Date
                value={formData.issueDate ? moment(formData.issueDate) : undefined}
                onChange={(value: moment.Moment | null) =>
                  handleFormDataChange({ issueDate: value?.format('YYYY-MM-DD') })
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Expiry date" validateStatus={errors?.expiryDate ? 'error' : ''} help={errors?.expiryDate}>
              <Input.Date
                value={formData.expiryDate ? moment(formData.expiryDate) : undefined}
                onChange={(value: moment.Moment | null) =>
                  handleFormDataChange({ expiryDate: value?.format('YYYY-MM-DD') })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Credential ID">
              <Input
                value={formData.credentialId}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ credentialId: event.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Credential URL">
              <Input
                value={formData.credentialUrl}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ credentialUrl: event.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Notes">
              <Input
                value={formData.notes}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ notes: event.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}

import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { Button, Checkbox, CheckboxChangeEvent, Collapse, Form, Input, PageHeader, Space } from '~/core-components'
import { Col, DocumentTitle, Row, SysOptions } from '~/components'
import { EmInfoType, emptyGuid, FeatureFlag, RptEmStatus } from '~/constants'
import { KeyValue } from '~/types/common'
import { GroupingButton, RptScreen } from '~/features/grouping'
import { useMyTenant } from '~/features/iam'
import { Errors } from '~/types/store'
import { REPORTS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { downloadWithDom, getFileTimestamp, showError } from '~/utils'
import { IRptEmDetail, ReportCode } from '../../types'
import { useFirstReportTemplate, useReportTemplate } from '../../hooks'
import { refetchReportTemplates } from '../../reducers'
import { RptDownloadBtn } from '../components/RptDownloadBtn'
import { ReportTemplate } from '../ReportTemplate/ReportTemplate'
import { apiDownloadEmDetailExcel } from '../../api/rpt-em-detail.api'
import { RptSortingBtn } from '../components/RptSortingBtn'
import { Screen, ViewCriteriaButton, useGetViewIdByName } from '~/features/selection'
import './RptEmDetail.less'

const routes = [
  {
    path: REPORTS_ROUTES.main,
    breadcrumbName: 'Reports'
  },
  {
    path: '',
    breadcrumbName: 'Employee details report'
  }
]

interface RptEmDetailForm extends IRptEmDetail {
  templateId: string
}

const TODAY = moment().format('YYYY-MM-DD')

const SCREEN_CODE: Screen = 'rpt_em_detail'
const RPT_SCREEN_CODE: RptScreen = 'rpt_people'
const REPORT_CODE: ReportCode = 'rpt_em_detail'

const EMPTY_FORM_DATA: RptEmDetailForm = {
  asAtDate: TODAY,
  emInfoTypes: [],
  emStatus: RptEmStatus.all,
  sorting: 'employee_name',
  groupingCodes: [],
  pageBreaks: [],
  viewId: '',
  screenCode: SCREEN_CODE,
  showHistory: false,
  maskIdentityNo: false,
  reportTitle: '',
  notes: '',
  templateId: emptyGuid
}

export const RptEmDetail: FC = () => {
  const [formData, setFormData] = useState<RptEmDetailForm>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [activeKey, setActiveKey] = useState(0)

  const [firstTemplate] = useFirstReportTemplate('employee', REPORT_CODE)
  const firstTemplateId = firstTemplate?.id || ''
  const [templateId, setTemplateId] = useState<string>()
  const [template] = useReportTemplate('employee', REPORT_CODE, templateId)

  const [viewId] = useGetViewIdByName(SCREEN_CODE, templateId ? `${SCREEN_CODE}_${templateId}` : undefined)

  const [myTenant] = useMyTenant()
  const hasAttendance = myTenant?.featureFlags.includes(FeatureFlag.Attendance) || false

  useEffect(() => {
    if (!templateId && firstTemplateId) {
      setTemplateId(firstTemplateId)
    }

    if (template) {
      const saved = JSON.parse(template.dataJsonb) as RptEmDetailForm
      saved.templateId = templateId || ''
      saved.emStatus = saved.emStatus || RptEmStatus.all
      saved.viewId = viewId || ''
      saved.screenCode = SCREEN_CODE
      setFormData(formData => ({ ...formData, ...saved }))
    }
  }, [firstTemplateId, templateId, template, viewId])

  const handleDownloadExcel = useCallback(async () => {
    const { status, result, errors, message, errorData } = await apiDownloadEmDetailExcel(formData)

    if (status) {
      const fileName = `employee_details_report_${getFileTimestamp()}.xlsx`
      downloadWithDom(result, fileName)
      dispatch(refetchReportTemplates())
    } else {
      console.error('Error while downloading', errors)
      setErrors(errors)
      showError(message, errorData)
    }
  }, [formData])

  const handleCollapse = useCallback(async () => {
    if (!activeKey) {
      setActiveKey(1)
    } else {
      setActiveKey(0)
    }
  }, [activeKey])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleTemplateChange = useCallback((templateId?: string) => {
    setTemplateId(templateId)
  }, [])

  return (
    <div id="rpt-em-detail" className="rpt-em-detail">
      <DocumentTitle title="Employee Details Report" />
      <PageHeader
        title="Employee details report"
        containerId="rpt-em-detail"
        breadcrumb={{ routes }}
        extra={
          <ReportTemplate<IRptEmDetail>
            basePath="employee"
            reportCode={REPORT_CODE}
            templateId={templateId}
            templateData={formData}
            size="small"
            onChange={handleTemplateChange}
          />
        }
      />
      <Form className="rpt-em-detail__form" layout="horizontal" labelCol={{ flex: '130px' }}>
        <Row gutter={30}>
          <Col flex="300px">
            <Form.Item
              label="As-of date"
              validateStatus={errors?.asAtDate ? 'error' : ''}
              help={errors?.asAtDate}
              tooltip={`Please note that for Address, Identity type and Education, the record indicated as Main will be displayed and might not respect the As-of date.
                For more information, select "Show history" to see the historical records.`}
            >
              <Input.Date
                value={formData.asAtDate ? moment(formData.asAtDate) : undefined}
                onChange={(value: moment.Moment | null) => {
                  handleFormDataChange({ asAtDate: value?.format('YYYY-MM-DD') })
                }}
              />
            </Form.Item>
          </Col>
          <Col flex="1"></Col>
          <Col flex="none">
            <Space>
              <RptSortingBtn
                sorting={formData.sorting}
                onSelect={(sorting: string) => handleFormDataChange({ sorting })}
              />
              <ViewCriteriaButton screenCode={SCREEN_CODE} viewId={formData.viewId} />
              <GroupingButton
                screenCode={RPT_SCREEN_CODE}
                groupingCodes={formData.groupingCodes || []}
                pageBreaks={formData.pageBreaks || []}
                onSelect={(groupingCodes: string[], pageBreaks: string[]) =>
                  handleFormDataChange({ groupingCodes, pageBreaks })
                }
              />
              <Button
                type={activeKey ? 'primary' : 'default'}
                icon={<i className="fal fa-gear" />}
                onClick={handleCollapse}
              />
              <RptDownloadBtn onDownloadExcel={handleDownloadExcel} />
            </Space>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col flex="1">
            <Form.Item
              label="Information type"
              validateStatus={errors?.emInfoTypes ? 'error' : ''}
              help={errors?.emInfoTypes}
            >
              <SysOptions
                type="em_info_type"
                mode="multiple"
                placeholder="All information types"
                value={formData.emInfoTypes || []}
                onFilter={(value: KeyValue | undefined) => {
                  if (hasAttendance) return true
                  else return EmInfoType.location !== value?.key
                }}
                onChange={(emInfoTypes: string[]) => handleFormDataChange({ emInfoTypes })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="1">
            <Collapse className="rpt-em-detail__more-settings" activeKey={activeKey} onChange={handleCollapse} noStyle>
              <Collapse.Panel key="1" header={null} showArrow={false}>
                <Row gutter={30}>
                  <Col flex="490px">
                    <Form.Item
                      label="Filter by employee"
                      validateStatus={errors?.emStatus ? 'error' : ''}
                      help={errors?.emStatus}
                    >
                      <SysOptions
                        allowClear={false}
                        type="rpt_em_status"
                        placeholder="All employees"
                        value={formData.emStatus}
                        onChange={(emStatus?: string) => handleFormDataChange({ emStatus })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="1">
                    <Form.Item label="Options">
                      <Checkbox
                        checked={formData.showHistory}
                        onChange={(event: CheckboxChangeEvent) =>
                          handleFormDataChange({ showHistory: event.target.checked })
                        }
                      >
                        Show history
                      </Checkbox>
                      <Checkbox
                        checked={formData.maskIdentityNo}
                        onChange={(event: CheckboxChangeEvent) =>
                          handleFormDataChange({ maskIdentityNo: event.target.checked })
                        }
                      >
                        Mask identity no.
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="490px">
                    <Form.Item label="Report title">
                      <Input
                        value={formData.reportTitle}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          handleFormDataChange({ reportTitle: event.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="1">
                    <Form.Item label="Custom notes">
                      <Input.TextArea
                        rows={3}
                        value={formData.notes}
                        onChange={(value?: ChangeEvent<HTMLTextAreaElement>) =>
                          handleFormDataChange({ notes: value?.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

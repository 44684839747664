import React, { FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Tooltip } from '~/core-components'
import { Screen } from '../types'
import { ViewSelectionDrawer } from './ViewSelectionDrawer'
import { selectViewSelectionCount } from '../selectors'

interface ViewSelectionButtonProps {
  screenCode: Screen
  viewId: string
  title?: string
  description?: string
  onClose?: (changed?: boolean) => void
}

interface SelectionDrawerState {
  visible: boolean
  viewId: string
}

const DEFAULT_DRAWER_STATE: SelectionDrawerState = { visible: false, viewId: '' }

export const ViewSelectionButton: FC<ViewSelectionButtonProps> = ({
  screenCode,
  viewId,
  title,
  description,
  onClose
}) => {
  const [selectionDrawerState, setSelectionDrawerState] = useState<SelectionDrawerState>(DEFAULT_DRAWER_STATE)
  const selectionCount = useSelector(selectViewSelectionCount)(screenCode, viewId)

  const handleSelectionClick = useCallback(() => {
    if (viewId) {
      setSelectionDrawerState({ visible: true, viewId })
    }
  }, [setSelectionDrawerState, viewId])

  const handleCloseSelectionDrawer = useCallback(
    (changed?: boolean) => {
      setSelectionDrawerState(DEFAULT_DRAWER_STATE)
      typeof onClose === 'function' && onClose(changed)
    },
    [onClose]
  )

  return (
    <>
      <Tooltip title="Configure columns" placement="topRight">
        <Button icon={<i className="fal fa-columns-3" />} badge={selectionCount} onClick={handleSelectionClick} />
      </Tooltip>
      <ViewSelectionDrawer
        {...selectionDrawerState}
        screenCode={screenCode}
        title={title || 'Configure columns'}
        description={description}
        onClose={handleCloseSelectionDrawer}
      />
    </>
  )
}

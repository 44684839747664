import React, { FC, useCallback } from 'react'
import moment from 'moment-timezone'
import { Col, Row } from '~/components'
import { Form, Input } from '~/core-components'
import { Errors } from '~/types/store'

interface BankFileMufgCmsPifJsonbProps {
  valueDate: string
  onChange: (key: string, value: string) => void
  canModify: boolean
  errors?: Errors
}

export const BankFileMufgCmsPifJsonb: FC<BankFileMufgCmsPifJsonbProps> = ({
  valueDate,
  onChange,
  canModify,
  errors
}) => {
  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      typeof onChange === 'function' && onChange('valueDate', updates.valueDate)
    },
    [onChange]
  )

  return (
    <>
      <Row>
        <Col span={24}>
          <Form.Item label="Value date" validateStatus={errors?.valueDate ? 'error' : ''} help={errors?.valueDate}>
            <Input.Date
              value={valueDate ? moment(valueDate) : undefined}
              inputReadOnly={!canModify}
              onChange={(value: moment.Moment | null) =>
                handleFormDataChange({ valueDate: value?.format('YYYY-MM-DD') })
              }
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

import { Operation } from 'fast-json-patch'
import { request, getBaseUrl } from '~/utils/request'
import { EmCertState, IEmCert } from '../types'

const baseUrl = getBaseUrl('/employee')

export const apiGetEmCerts = (employeeId: string) =>
  request<EmCertState[]>('get', `${baseUrl}/employee/${employeeId}/cert`)

export const apiAddEmCert = (employeeId: string, emCert: IEmCert) =>
  request<{ id: string }>('post', `${baseUrl}/employee/${employeeId}/cert`, emCert)

export const apiUpdateEmCert = (employeeId: string, id: string, emCert: Operation[]) =>
  request<void>('patch', `${baseUrl}/employee/${employeeId}/cert/${id}`, emCert)

export const apiDeleteEmCert = (employeeId: string, id: string) =>
  request<void>('delete', `${baseUrl}/employee/${employeeId}/cert/${id}`)

export const apiGetEmCertField = (field: string) => request<string[]>('get', `${baseUrl}/emcert/distinct/${field}`)

import React, { FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { ColumnsType, Drawer, Link, Table, Tag } from '~/core-components'
import { EmAddressRequestState } from '../../../../types'
import { useIsMountedRef } from '~/hooks'
import { EmAddressRequestApprovalDrawer } from './EmAddressRequestApprovalDrawer'
import { selectEmAddressRequests } from '~/features/employee/selectors'
import { IAddress, formatAddress, selectKeyvaluesById } from '~/features/master'

export interface EmAddressRequestsProps {
  employeeId?: string
  visible: boolean
  onClose: () => void
}

interface ApprovalDrawerState {
  data?: EmAddressRequestState
  visible: boolean
}

const DEFAULT_APPROVAL_DRAWER_STATE: ApprovalDrawerState = { visible: false }

export const EmAddressRequestsDrawer: FC<EmAddressRequestsProps> = ({ employeeId, visible, onClose }) => {
  const data = useSelector(selectEmAddressRequests)(employeeId)
  const countries = useSelector(selectKeyvaluesById)('country')
  const [approvalDrawerState, setApprovalDrawerState] = useState<ApprovalDrawerState>(DEFAULT_APPROVAL_DRAWER_STATE)
  const isMountedRef = useIsMountedRef()

  const handleView = useCallback(async (request: EmAddressRequestState) => {
    setApprovalDrawerState({ data: request, visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose()
  }, [onClose])

  const handleCloseApprovalDrawer = useCallback(() => {
    if (isMountedRef.current) setApprovalDrawerState(DEFAULT_APPROVAL_DRAWER_STATE)
  }, [isMountedRef])

  const columns: ColumnsType<EmAddressRequestState> = [
    {
      title: 'Effective date',
      key: 'startDateNew',
      dataIndex: 'startDateNew',
      width: 150,
      render: (value: string) => value && moment(value).format('DD MMM YYYY')
    },
    {
      title: 'Address',
      key: 'streetNew',
      dataIndex: 'streetNew',
      render: (value: string, record) => {
        const { blockNew, streetNew, levelNew, unitNew, cityNew, stateNew, postalCodeNew, countryCodeNew } = record
        const countryName = countries[countryCodeNew]?.value || ''
        const address = formatAddress(
          {
            block: blockNew,
            street: streetNew,
            level: levelNew,
            unit: unitNew,
            building: '',
            city: cityNew,
            state: stateNew,
            postalCode: postalCodeNew,
            countryCode: countryCodeNew
          } as IAddress,
          countryName
        )

        return (
          <>
            {address.addressLine1} {address.addressLine2} {record.isMainNew && <Tag type="primary">main</Tag>}
            {record.recordStatus === 'new_request' && <Tag type="secondary">new (pending)</Tag>}
            {record.recordStatus === 'has_request' && <Tag type="secondary">pending</Tag>}
          </>
        )
      }
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) => (
        <Link size="small" onClick={() => handleView(record)} noWrap>
          view
        </Link>
      )
    }
  ]

  return (
    <>
      <Drawer
        open={visible}
        title="Address request"
        onClose={() => handleCloseDrawer()}
        width={700}
        className="em-address-requests-drawer"
      >
        <Table rowKey="id" dataSource={data} columns={columns} pagination={false}></Table>
      </Drawer>
      <EmAddressRequestApprovalDrawer {...approvalDrawerState} onClose={handleCloseApprovalDrawer} />
    </>
  )
}

import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { apiUpdateEmCert } from '../api/em-cert.api'
import { EmCertState, IEmCertInfo } from '../types'
import { setEmCert } from '../reducers'

export const updateEmCert = <T extends IEmCertInfo>(
  employeeId: string,
  id: string,
  before: T,
  request: T
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!employeeId || !id) return

    const after = { ...before, ...request } as T
    const patch = generateJsonPatch<T>(before, after)
    const { status, errors, message, errorData } = await apiUpdateEmCert(employeeId, id, patch)

    if (status) {
      const emCert = getState().employee.emCerts[employeeId]?.entities[id]
      dispatch(setEmCert({ employeeId, data: { ...(emCert as EmCertState), ...after } }))

      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}

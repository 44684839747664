import { EmSelectionViewState } from '~/features/employee'
import { EmSelectState } from '~/components'
import { Pagination, Search } from '~/types/common'
import { request, getBaseUrl, timeout } from '~/utils/request'
import {
  EmTaxNoResult,
  Ir8aFormState,
  Ir8sFormState,
  A8aFormState,
  A8bFormState,
  SubmitIrasResponse,
  IYtdUpdateIr8aDraft,
  IYtdUpdateIr8sDraft,
  IYtdUpdateA8aDraft,
  IYtdUpdateA8bDraft,
  IYtdAddIr8sDraft,
  IYtdAddA8aDraft,
  IYtdAddA8bDraft,
  YtdFormDownloadDto,
  IYtdIr8aDraftLock,
  IYtdAddIr8aDraft
} from '../types'

const baseUrl = getBaseUrl('/payroll-sg/ytd')

export const apiGetCorpPassAuthUrl = () => request<string>('get', `${baseUrl}/corppass/login`)

export const apiGetCorpPassToken = (authorizationCode: string, state: string) =>
  request<boolean>('post', `${baseUrl}/corppass/token`, { authorizationCode, state })

export const apiSubmitIras = (
  ytdSubmissionId: string,
  signatureDate: string,
  validateOnly: boolean,
  bypass: boolean,
  skipApiCall: boolean
) =>
  request<SubmitIrasResponse>('post', `${baseUrl}/iras`, {
    ytdSubmissionId,
    signatureDate,
    validateOnly,
    bypass,
    skipApiCall
  })

export const apiGetIrasSubmissionReport = (irasLogId: string) =>
  request('post', `${baseUrl}/iras/summary`, { irasLogId }, { responseType: 'blob', timeout })

export const apiGetIrasReconciliationReportExcel = (ytdYear: string, ytdSubmissionId: string, employerTaxNo: string) =>
  request(
    'post',
    `${baseUrl}/iras/reconciliation`,
    { ytdYear, ytdSubmissionId, employerTaxNo },
    { responseType: 'blob', timeout }
  )

export const apiProcess = (ytdSubmissionId: string, employeeScope: 'existing' | 'all', employeeTaxNos: string[]) =>
  request('post', `${baseUrl}/process`, { ytdSubmissionId, employeeScope, employeeTaxNos })

export const apiDownloadIrasFiles = (ytdSubmissionId: string, signatureDate: string) =>
  request(
    'post',
    `${baseUrl}/iras/files`,
    { ytdSubmissionId, signatureDate, silentError: true },
    { responseType: 'blob', timeout }
  )

// IR8A
export const apiProcessIr8a = (ytdSubmissionId: string, employeeScope: 'existing' | 'all', employeeTaxNos: string[]) =>
  request('post', `${baseUrl}/ir8a/process`, { ytdSubmissionId, employeeScope, employeeTaxNos })

export const apiResetIr8a = (ytdSubmissionId: string, employeeTaxNo: string) =>
  request('post', `${baseUrl}/ir8a/reset`, { ytdSubmissionId, employeeTaxNo })

export const apiGetIr8aEmTaxNoList = (ytdYear: string, sourceType: string, employerTaxNo: string, batchNo?: number) =>
  request<EmTaxNoResult[]>('get', `${baseUrl}/ir8a/emtaxno`, {
    ytdYear,
    sourceType,
    employerTaxNo,
    batchNo
  })

export const apiGetIr8aFormData = (
  ytdYear: string,
  employerTaxNo: string,
  employeeTaxNo: string,
  ytdSubmissionId: string
) =>
  request<Ir8aFormState>('get', `${baseUrl}/ir8a`, {
    ytdYear,
    employerTaxNo,
    employeeTaxNo,
    ytdSubmissionId
  })

export const apiUpdateIr8aDraft = (formData: IYtdUpdateIr8aDraft) => request<void>('put', `${baseUrl}/ir8a`, formData)

export const apiAddIr8aDraft = (formData: IYtdAddIr8aDraft) => request<void>('post', `${baseUrl}/ir8a`, formData)

export const apiDeleteIr8aDraft = (id: string) => request('delete', `${baseUrl}/ir8a/${id}`)

export const apiGetIr8aEmSelections = (id: string, viewId: string, params: Pagination & Search) =>
  request<EmSelectionViewState>('get', `${baseUrl}/ir8a/${id}/emselections/${viewId}`, params)

export const apiGetIr8aNewEmSelections = (id: string, viewId: string, params: Pagination & Search) =>
  request<EmSelectionViewState>('get', `${baseUrl}/ir8a/${id}/emselections/new/${viewId}`, params)

export const apiLockIr8aDraft = (id: string) => request<IYtdIr8aDraftLock>('post', `${baseUrl}/${id}/lock`)

export const apiUnlockIr8aDraft = (id: string) => request<void>('post', `${baseUrl}/${id}/unlock`)

export const apiLockAllIr8aDrafts = (ytdSubmissionId: string) =>
  request<IYtdIr8aDraftLock[]>('post', `${baseUrl}/${ytdSubmissionId}/lockall`)

export const apiUnlockAllIr8aDrafts = (ytdSubmissionId: string) =>
  request<void>('post', `${baseUrl}/${ytdSubmissionId}/unlockall`)

export const apiGetYtdSubmissionEmployees = (ytdFormType: string, ytdSubmissionId: string) =>
  request<EmSelectState[]>('get', `${baseUrl}/${ytdFormType}/${ytdSubmissionId}/employees`)

export const apiDownloadYtdForm = (ytdFormType: string, params: YtdFormDownloadDto) =>
  request('post', `${baseUrl}/${ytdFormType}/download`, params, { responseType: 'blob', timeout })

// IR8S
export const apiProcessIr8s = (ytdSubmissionId: string, employeeScope: 'existing' | 'all', employeeTaxNos: string[]) =>
  request('post', `${baseUrl}/ir8s/process`, { ytdSubmissionId, employeeScope, employeeTaxNos })

export const apiResetIr8s = (ytdSubmissionId: string, employeeTaxNo: string) =>
  request('post', `${baseUrl}/ir8s/reset`, { ytdSubmissionId, employeeTaxNo })

export const apiGetIr8sEmTaxNoList = (ytdYear: string, sourceType: string, employerTaxNo: string, batchNo?: number) =>
  request<EmTaxNoResult[]>('get', `${baseUrl}/ir8s/emtaxno`, {
    ytdYear,
    sourceType,
    employerTaxNo,
    batchNo
  })

export const apiGetIr8sFormData = (
  ytdYear: string,
  employerTaxNo: string,
  employeeTaxNo: string,
  ytdSubmissionId: string
) =>
  request<Ir8sFormState>('get', `${baseUrl}/ir8s`, {
    ytdYear,
    employerTaxNo,
    employeeTaxNo,
    ytdSubmissionId
  })

export const apiUpdateIr8sDraft = (formData: IYtdUpdateIr8sDraft) => request<void>('put', `${baseUrl}/ir8s`, formData)

export const apiAddIr8sDraft = (formData: IYtdAddIr8sDraft) => request<void>('post', `${baseUrl}/ir8s`, formData)

export const apiDeleteIr8sDraft = (id: string) => request('delete', `${baseUrl}/ir8s/${id}`)

export const apiGetIr8sEmSelections = (id: string, viewId: string, params: Pagination & Search) =>
  request<EmSelectionViewState>('get', `${baseUrl}/ir8s/${id}/emselections/${viewId}`, params)

export const apiGetIr8sNewEmSelections = (id: string, viewId: string, params: Pagination & Search) =>
  request<EmSelectionViewState>('get', `${baseUrl}/ir8s/${id}/emselections/new/${viewId}`, params)

// A8a
export const apiProcessA8a = (ytdSubmissionId: string, employeeScope: 'existing' | 'all', employeeTaxNos: string[]) =>
  request('post', `${baseUrl}/a8a/process`, { ytdSubmissionId, employeeScope, employeeTaxNos })

export const apiResetA8a = (ytdSubmissionId: string, employeeTaxNo: string) =>
  request('post', `${baseUrl}/a8a/reset`, { ytdSubmissionId, employeeTaxNo })

export const apiGetA8aEmTaxNoList = (ytdYear: string, sourceType: string, employerTaxNo: string, batchNo?: number) =>
  request<EmTaxNoResult[]>('get', `${baseUrl}/a8a/emtaxno`, {
    ytdYear,
    sourceType,
    employerTaxNo,
    batchNo
  })

export const apiGetA8aForm = (ytdYear: string, employerTaxNo: string, employeeTaxNo: string, ytdSubmissionId: string) =>
  request<A8aFormState>('get', `${baseUrl}/a8a`, {
    ytdYear,
    employerTaxNo,
    employeeTaxNo,
    ytdSubmissionId
  })

export const apiUpdateA8aDraft = (formData: IYtdUpdateA8aDraft) => request<void>('put', `${baseUrl}/a8a`, formData)

export const apiAddA8aDraft = (formData: IYtdAddA8aDraft) => request<void>('post', `${baseUrl}/a8a`, formData)

export const apiDeleteA8aDraft = (id: string) => request('delete', `${baseUrl}/a8a/${id}`)

export const apiGetA8aEmSelections = (id: string, viewId: string, params: Pagination & Search) =>
  request<EmSelectionViewState>('get', `${baseUrl}/a8a/${id}/emselections/${viewId}`, params)

export const apiGetA8aNewEmSelections = (id: string, viewId: string, params: Pagination & Search) =>
  request<EmSelectionViewState>('get', `${baseUrl}/a8a/${id}/emselections/new/${viewId}`, params)

// A8b
export const apiGetA8bEmTaxNoList = (ytdYear: string, sourceType: string, employerTaxNo: string, batchNo?: number) =>
  request<EmTaxNoResult[]>('get', `${baseUrl}/a8b/emtaxno`, {
    ytdYear,
    sourceType,
    employerTaxNo,
    batchNo
  })

export const apiGetA8bForm = (ytdYear: string, employerTaxNo: string, employeeTaxNo: string, ytdSubmissionId: string) =>
  request<A8bFormState>('get', `${baseUrl}/a8b`, {
    ytdYear,
    employerTaxNo,
    employeeTaxNo,
    ytdSubmissionId
  })

export const apiUpdateA8bDraft = (formData: IYtdUpdateA8bDraft) => request<void>('put', `${baseUrl}/a8b`, formData)

export const apiAddA8bDraft = (formData: IYtdAddA8bDraft) => request<void>('post', `${baseUrl}/a8b`, formData)

export const apiDeleteA8bDraft = (id: string) => request('delete', `${baseUrl}/a8b/${id}`)

export const apiGetA8bEmSelections = (id: string, viewId: string, params: Pagination & Search) =>
  request<EmSelectionViewState>('get', `${baseUrl}/a8b/${id}/emselections/${viewId}`, params)

export const apiGetA8bNewEmSelections = (id: string, viewId: string, params: Pagination & Search) =>
  request<EmSelectionViewState>('get', `${baseUrl}/a8b/${id}/emselections/new/${viewId}`, params)

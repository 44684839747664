import React, { forwardRef } from 'react'
import { default as AntButton, ButtonProps as AntButtonProps } from 'antd/lib/button'
import 'antd/es/button/style'
import classNames from 'classnames'
import { Badge } from '../Badge/Badge'
import './Button.less'

export interface ButtonProps extends AntButtonProps {
  badge?: number
}

const ButtonInternal = ({ className, badge, children, ...props }: ButtonProps, ref: React.Ref<any>) => {
  const classes = classNames({ [`${className}`]: className }, { 'ant-btn--badge': badge !== undefined && badge > 0 })

  if (children) {
    return (
      <AntButton {...props} className={classes} ref={ref}>
        {children && children}
        {badge !== undefined && badge > 0 && <Badge count={badge} />}
      </AntButton>
    )
  } else {
    return (
      <AntButton {...props} className={classes} ref={ref}>
        {badge !== undefined && badge > 0 && <Badge count={badge} />}
      </AntButton>
    )
  }
}

export const Button = forwardRef(ButtonInternal)

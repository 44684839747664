import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { SuggestionType } from '~/components'
import { FetchStrategy } from '~/types/common'
import { useHasChange } from '~/hooks'
import { StoreState } from '~/types/store'
import { fetchSysDailyPolicyExpressions } from '../actions'
import { selectSysDailyPolicyExpressionsSuggestions } from '../selectors'

export const useSysDailyPolicyExpressionsSuggestions = (
  fetchStrategy: FetchStrategy = 'when-empty'
): [SuggestionType[], boolean] => {
  const data = useSelector(selectSysDailyPolicyExpressionsSuggestions)
  const loading = useSelector((state: StoreState) => state.attendance.sysDailyPolicyExpressionsLoading)
  const isRefetch = useHasChange((state: StoreState) => state.attendance.sysDailyPolicyExpressionsRefetch)
  const strategy = isRefetch ? 'always' : fetchStrategy

  useEffect(() => {
    dispatch(fetchSysDailyPolicyExpressions({ strategy }))
  }, [isRefetch, strategy])

  return [data, loading]
}

import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { StoreState } from '~/types/store'
import { Screen } from '../types'

export const selectViewSelectionCount = createSelector(
  (state: StoreState) => state.selection.viewSchema,
  viewSchemaState =>
    memoize(
      (screenCode: Screen, viewId?: string): number => {
        if (!viewId) return 0

        const viewSchema = viewSchemaState[screenCode]?.entities[viewId]
        const selection = [...(viewSchema?.selection || [])]
        return selection.length
      },
      (screenCode: Screen, viewId?: string) => `${screenCode}||${viewId}`
    )
)

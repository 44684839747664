import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { clearSysSelectionFields, refetchSysSelectionFields } from '~/features/selection'
import { generateJsonPatch } from '../../../utils/generateJsonPatch'
import { IOtConfig, OtConfigState } from '../types'
import { apiUpdateOtConfig } from '../api/ot-config.api'
import { refetchSysDailyPolicyExpressions, refetchSysDailyPolicyFields, setOtConfig } from '../reducers'

export const updateOtConfig = (code: string, before: IOtConfig, request: IOtConfig): ThunkResult<void> => {
  return async (dispatch, getState) => {
    if (!code) return

    const after = { ...before, ...request } as IOtConfig
    const patch = generateJsonPatch<IOtConfig>(before, after)
    const { status, errors, message, errorData } = await apiUpdateOtConfig(code, patch)

    if (status) {
      const otConfig = getState().attendance.otConfigs?.entities[code]
      const updated: OtConfigState = { ...(otConfig as OtConfigState), ...after }
      dispatch(setOtConfig(updated))
      dispatch(clearSysSelectionFields())
      dispatch(refetchSysSelectionFields())
      dispatch(refetchSysDailyPolicyFields())
      dispatch(refetchSysDailyPolicyExpressions())
      showSuccess('Saved')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}

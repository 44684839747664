import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Checkbox, CheckboxChangeEvent, Form, Space } from '~/core-components'
import { Col, Row } from '~/components'
import { useHasFeatureFlagAttendance } from '~/features/iam'
import { StoreState } from '~/types/store'
import { ICopyEmployee } from '../../../../../types'
import { CopyEmInfo } from './CopyEmInfo'
import { CopyEmPersonal } from './CopyEmPersonal'
import { CopyEmIdentities } from './CopyEmIdentities'
import { CopyEmAddress } from './CopyEmAddress'
import { CopyEmCareer } from './CopyEmCareer'
import { CopyEmCalendar } from './CopyEmCalendar'
import { CopyEmLocation } from './CopyEmLocation'
import { CopyEmAttendanceGroup } from './CopyEmAttendanceGroup'
import { CopyEmShiftRole } from './CopyEmShiftRole'
import { CopyEmPayment } from './CopyEmPayment'
import { CopyEmFund } from './CopyEmFund'
import { CopyEmFamilies } from './CopyEmFamilies'
import { CopyEmEmergency } from './CopyEmEmergency'
import { CopyEmEducations } from './CopyEmEducations'
import { CopyEmCerts } from './CopyEmCerts'
import './CopyEm.less'

interface CopyEmProps {
  visible: boolean
  data: Partial<ICopyEmployee>
  onChange: (data: Partial<ICopyEmployee>) => void
  Display?: React.ReactNode
}

export const CopyEm: FC<CopyEmProps> = ({ visible, data, onChange, Display }) => {
  const employee = useSelector((state: StoreState) => state.employee.employees.entities[data.employeeId || ''])
  const hasAttendance = useHasFeatureFlagAttendance()

  return (
    <div className="copy-em" hidden={!visible}>
      <Form>
        {Display}
        <Row>
          <Col span={24}>
            <div className="copy-em__title">Copy details from previous employment</div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item>
              <Space direction="vertical" size="small">
                <Checkbox
                  checked={data.copyInfo}
                  onChange={(event: CheckboxChangeEvent) => onChange({ copyInfo: event.target.checked })}
                >
                  Basic information
                </Checkbox>
                <CopyEmInfo employee={employee} />
                <Checkbox
                  checked={data.copyPersonal}
                  onChange={(event: CheckboxChangeEvent) => onChange({ copyPersonal: event.target.checked })}
                >
                  Personal information
                </Checkbox>
                <CopyEmPersonal employee={employee} />
                <Checkbox
                  checked={data.copyIdentity}
                  onChange={(event: CheckboxChangeEvent) => onChange({ copyIdentity: event.target.checked })}
                >
                  Personal identification
                </Checkbox>
                <CopyEmIdentities employeeId={employee?.id} emIdentityId={employee?.emIdentityId} />
                <Checkbox
                  checked={data.copyAddress}
                  onChange={(event: CheckboxChangeEvent) => onChange({ copyAddress: event.target.checked })}
                >
                  Address
                </Checkbox>
                <CopyEmAddress employeeId={employee?.id} emAddressId={employee?.emAddressId} />
                <Checkbox
                  checked={data.copyCareer}
                  onChange={(event: CheckboxChangeEvent) => onChange({ copyCareer: event.target.checked })}
                >
                  Employment details
                </Checkbox>
                <CopyEmCareer
                  employeeId={employee?.id}
                  emDepartmentId={employee?.emDepartmentId}
                  emDivisionId={employee?.emDivisionId}
                  emSectionId={employee?.emTeamId}
                  emGrpId={employee?.emGrpId}
                  emCategoryId={employee?.emCategoryId}
                  emTeamId={employee?.emTeamId}
                  emJobId={employee?.emJobId}
                  emManagerId={employee?.emManagerId}
                  emManagerSecondaryId={employee?.emManagerSecondaryId}
                  emOfficeId={employee?.emOfficeId}
                  emCostCentreId={employee?.emCostCentreId}
                  emEmploymentTypeId={employee?.emEmploymentTypeId}
                  emOtClassId={employee?.emOtClassId}
                />
                <Checkbox
                  checked={data.copyCalendar}
                  onChange={(event: CheckboxChangeEvent) => onChange({ copyCalendar: event.target.checked })}
                >
                  Work calendar
                </Checkbox>
                <CopyEmCalendar employeeId={employee?.id} emCalendarId={employee?.emCalendarId} />
                {hasAttendance && (
                  <>
                    <Checkbox
                      checked={data.copyLocation}
                      onChange={(event: CheckboxChangeEvent) => onChange({ copyLocation: event.target.checked })}
                    >
                      Attendance location
                    </Checkbox>
                    <CopyEmLocation employeeId={employee?.id} emLocationId={employee?.emLocationId} />
                    <Checkbox
                      checked={data.copyAttendanceGroup}
                      onChange={(event: CheckboxChangeEvent) => onChange({ copyAttendanceGroup: event.target.checked })}
                    >
                      Attendance group
                    </Checkbox>
                    <CopyEmAttendanceGroup
                      employeeId={employee?.id}
                      emAttendanceGroupId={employee?.emAttendanceGroupId}
                    />
                    <Checkbox
                      checked={data.copyShiftRole}
                      onChange={(event: CheckboxChangeEvent) => onChange({ copyShiftRole: event.target.checked })}
                    >
                      Shift role
                    </Checkbox>
                    <CopyEmShiftRole employeeId={employee?.id} emShiftRoleId={employee?.emShiftRoleId} />
                  </>
                )}
                <Checkbox
                  checked={data.copyPayment}
                  onChange={(event: CheckboxChangeEvent) => onChange({ copyPayment: event.target.checked })}
                >
                  Payment information (bank details)
                </Checkbox>
                <CopyEmPayment employee={employee} />
                <Checkbox
                  checked={data.copyFund}
                  onChange={(event: CheckboxChangeEvent) => onChange({ copyFund: event.target.checked })}
                >
                  Self-help group (SHG) fund
                </Checkbox>
                <CopyEmFund employee={employee} />
                <Checkbox
                  checked={data.copyFamily}
                  onChange={(event: CheckboxChangeEvent) => onChange({ copyFamily: event.target.checked })}
                >
                  Family members
                </Checkbox>
                <CopyEmFamilies employeeId={employee?.id} />
                <Checkbox
                  checked={data.copyEmergency}
                  onChange={(event: CheckboxChangeEvent) => onChange({ copyEmergency: event.target.checked })}
                >
                  Emergency contacts
                </Checkbox>
                <CopyEmEmergency employee={employee} />
                <Checkbox
                  checked={data.copyEducation}
                  onChange={(event: CheckboxChangeEvent) => onChange({ copyEducation: event.target.checked })}
                >
                  Education
                </Checkbox>
                <CopyEmEducations employeeId={employee?.id} />
                <Checkbox
                  checked={data.copyCert}
                  onChange={(event: CheckboxChangeEvent) => onChange({ copyCert: event.target.checked })}
                >
                  Licenses & certificates
                </Checkbox>
                <CopyEmCerts employeeId={employee?.id} />
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

import React, { FC, ChangeEvent, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { LoadingOutlined } from '@ant-design/icons'
import { Checkbox, CheckboxChangeEvent, Form, Input, Link, Spin } from '~/core-components'
import { Col, DrawerForm, Row } from '~/components'
import { useBreakTimes } from '~/features/attendance'
import { dispatch } from '~/stores/store'
import { useFocus } from '~/hooks/use-focus'
import { ActionResult, Errors } from '~/types/store'
import { isInactive } from '~/utils'
import { saveBreak, deleteBreak } from '../../../actions'
import { BreakState, IBreakTime, ISaveBreak } from '../../../types'
import './MutateBreakDrawer.less'

export interface MutateBreakDrawerProps {
  visible: boolean
  data?: BreakState
  onClose: () => void
}

type FormData = ISaveBreak

const EMPTY_FORM_DATA: FormData = {
  name: '',
  inactiveDate: '',
  times: []
}

const NEW_BREAK_TIME: IBreakTime = { id: '', startTime: '00:00', endTime: '00:00' }

export const MutateBreakDrawer: FC<MutateBreakDrawerProps> = ({ visible, data, onClose }: MutateBreakDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()
  const [breakTimes, breakTimesLoading] = useBreakTimes(data?.id || '')

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      const { id, name, inactiveDate } = data
      setFormData({ id, name, inactiveDate, times: breakTimes || [NEW_BREAK_TIME] })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data, breakTimes])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      const excludeEmpty = (t: IBreakTime) => t.startTime !== '00:00' || t.endTime !== '00:00'
      const filtered = { ...formData, times: formData.times.filter(excludeEmpty) }
      result = await dispatch(saveBreak(filtered))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [formData, onClose])

  const handleDelete = useCallback(
    (_break: BreakState | undefined) => {
      if (_break) {
        const { id, name } = _break
        confirm({
          title: 'Delete break',
          content: `Do you want to delete break record of "${name}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteBreak(id))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [onClose]
  )

  return (
    <DrawerForm
      open={visible}
      title={data ? 'Edit break' : 'Add break'}
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      showDelete={data ? true : false}
      onDelete={() => handleDelete(data)}
      className="mutate-break-drawer"
      formId="form-break"
    >
      <Form id="form-break" onFinish={handleOk}>
        <Row gutter={30}>
          <Col flex="auto">
            <Form.Item label="Name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
              <Input
                ref={focusRef}
                value={formData.name}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
              />
            </Form.Item>
          </Col>
          <Col flex="none">
            <Form.Item
              label="Inactive"
              validateStatus={errors?.inactiveDate ? 'error' : ''}
              help={errors?.inactiveDate}
            >
              <Checkbox
                checked={isInactive(formData.inactiveDate)}
                onChange={(event: CheckboxChangeEvent) =>
                  handleFormDataChange({ inactiveDate: event.target.checked ? moment().format('YYYY-MM-DD') : '' })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="1">
            {breakTimesLoading && <Spin indicator={<LoadingOutlined spin />} />}
            <Row gutter={15} className="section-row">
              <Col flex="95px">
                <div className="section-row__title">Start</div>
              </Col>
              <Col flex="95px">
                <div className="section-row__title">End</div>
              </Col>
            </Row>
            {formData.times.map((t, index) => (
              <Row key={index} gutter={15} className="section-row">
                <Col flex="95px">
                  <Input.Time
                    allowClear={false}
                    value={moment(t.startTime, 'HH:mm')}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({
                        times: [
                          ...formData.times.slice(0, index),
                          { ...formData.times[index], startTime: value?.format('HH:mm') },
                          ...formData.times.slice(index + 1, formData.times.length)
                        ]
                      })
                    }
                  />
                </Col>
                <Col flex="95px">
                  <Input.Time
                    allowClear={false}
                    value={moment(t.endTime, 'HH:mm')}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({
                        times: [
                          ...formData.times.slice(0, index),
                          { ...formData.times[index], endTime: value?.format('HH:mm') },
                          ...formData.times.slice(index + 1, formData.times.length)
                        ]
                      })
                    }
                  />
                </Col>
                <Col>
                  <Link
                    onClick={() =>
                      handleFormDataChange({
                        times: [
                          ...formData.times.slice(0, index),
                          ...formData.times.slice(index + 1, formData.times.length)
                        ]
                      })
                    }
                  >
                    remove
                  </Link>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
        <Form.Item>
          <Link onClick={() => handleFormDataChange({ times: [...formData.times, NEW_BREAK_TIME] })}>
            add break time
          </Link>
        </Form.Item>
      </Form>
    </DrawerForm>
  )
}

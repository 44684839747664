import { replaceEmFieldValues, setEmFieldValuesLoading } from '../reducers'
import { ThunkResult } from '~/types/store'
import { DEFAULT_FETCH_OPTIONS, FetchOptions } from '~/types/common'
import { apiGetEmCertField } from '../api/em-cert.api'

export const fetchEmCertField = (field: string, options: FetchOptions = DEFAULT_FETCH_OPTIONS): ThunkResult<void> => {
  return async (dispatch, getState) => {
    const loading = getState().employee.emFieldValuesLoading[field]
    if (loading) return

    if (options.strategy === 'when-empty') {
      const hasData = getState().employee.emFieldValues[field]?.length > 0
      if (hasData) return
    }

    try {
      dispatch(setEmFieldValuesLoading({ field, loading: true }))
      const { result, status } = await apiGetEmCertField(field)
      if (status) {
        dispatch(replaceEmFieldValues({ field, data: result }))
      }
    } finally {
      dispatch(setEmFieldValuesLoading({ field, loading: false }))
    }
  }
}

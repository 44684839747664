import { FC } from 'react'
import { NewTabLink } from '~/components'
import { usePayRun } from '~/features/payroll/hooks'
import { PAY_ROUTES } from '~/routes/routes'

interface PayRunNameProps {
  id?: string
}

export const PayRunName: FC<PayRunNameProps> = ({ id }) => {
  const [payRun] = usePayRun(id || '')
  const payRunName = payRun?.description || ''

  if (!id || !payRunName) return null

  return (
    <NewTabLink path={`${PAY_ROUTES.payRun.replace(':id', id).replace(':tab?', '')}`} hidden>
      {payRunName}
    </NewTabLink>
  )
}

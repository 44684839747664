import React, { FC } from 'react'
import { Card } from '~/core-components'
import { EmPublicProfile } from '~/features/employee'
import { SSEmPublicSelfState } from '../../../../types'
import { MyBasicInfo } from './components/MyBasicInfo'
import { MyPersonalInfo } from './components/MyPersonalInfo'
import { MyIdentities } from './components/MyIdentities'
import { MyAddresses } from './components/MyAddresses'
import { MyEmployment } from './components/MyEmployment'
import { MyFamilies } from './components/MyFamilies'
import { MyEmergencyContacts } from './components/MyEmergencyContacts'
import { MyEducations } from './components/MyEducations'
import { useHiddenSelfUpdateConfigs } from '~/features/master'
import { SelfUpdateConfig } from '~/constants'
import { MyCerts } from './components/MyCerts'
import './MyProfile.less'

interface MyProfileProps {
  data?: SSEmPublicSelfState
}

export const MyProfile: FC<MyProfileProps> = ({ data }) => {
  const [selfUpdateConfigs] = useHiddenSelfUpdateConfigs()
  const hiddenSelfUpdateConfigs = selfUpdateConfigs.map(ec => ec.code)
  const hidePersonal = hiddenSelfUpdateConfigs.includes(SelfUpdateConfig.Personal)
  const hideIdentity = hiddenSelfUpdateConfigs.includes(SelfUpdateConfig.Identity)
  const hideAddress = hiddenSelfUpdateConfigs.includes(SelfUpdateConfig.Address)
  const hideFamily = hiddenSelfUpdateConfigs.includes(SelfUpdateConfig.Family)
  const hideEmergency = hiddenSelfUpdateConfigs.includes(SelfUpdateConfig.Emergency)
  const hideEducation = hiddenSelfUpdateConfigs.includes(SelfUpdateConfig.Education)
  const hideCert = hiddenSelfUpdateConfigs.includes(SelfUpdateConfig.Cert)

  if (!data) return null

  return (
    <div className="my-profile">
      <EmPublicProfile id={data.id} defaultName={data.fullName} defaultPhotoId={data.photoId} />
      <div className="my-profile__cards">
        {data.bio && <Card title="About">{data.bio}</Card>}
        <MyBasicInfo data={data} />
        <MyPersonalInfo data={data} hideSelfUpdate={hidePersonal} />
        <MyIdentities employeeId={data.id} data={data.identity} hideSelfUpdate={hideIdentity} />
        <MyAddresses employeeId={data.id} data={data.address} hideSelfUpdate={hideAddress} />
        <MyEmployment data={data} />
        <MyFamilies employeeId={data.id} data={data.family} hideSelfUpdate={hideFamily} />
        <MyEmergencyContacts data={data} hideSelfUpdate={hideEmergency} />
        <MyEducations employeeId={data.id} data={data.education} hideSelfUpdate={hideEducation} />
        <MyCerts employeeId={data.id} data={data.cert} hideSelfUpdate={hideCert} />
      </div>
    </div>
  )
}

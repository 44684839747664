import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Table, ColumnsType, Tooltip } from '~/core-components'
import { EmCertState } from '../../../../../types'
import { selectEmCerts } from '../../../../../selectors'

interface CopyEmCertsProps {
  employeeId?: string
}

type EmCertTable = EmCertState

export const CopyEmCerts: FC<CopyEmCertsProps> = ({ employeeId }) => {
  const emCerts = useSelector(selectEmCerts)(employeeId)

  const columns: ColumnsType<EmCertTable> = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: 200
    },
    {
      title: 'Issuing organisation',
      key: 'issuedBy',
      dataIndex: 'issuedBy',
      width: 150
    },
    {
      title: 'Issue date',
      key: 'issueDate',
      dataIndex: 'issueDate',
      render: (value: string) => value && moment(value).format('DD MMM YYYY')
    },
    {
      title: 'Expiry date',
      key: 'expiryDate',
      dataIndex: 'expiryDate',
      render: (value: string) => value && moment(value).format('DD MMM YYYY')
    },
    {
      title: 'Notes',
      key: 'notes',
      dataIndex: 'notes',
      render: (value: string) =>
        value && (
          <Tooltip title={value}>
            <i className="fal fa-note" />
          </Tooltip>
        )
    }
  ]

  return <Table rowKey="id" dataSource={emCerts} pagination={false} columns={columns} className="copy-em__content" />
}

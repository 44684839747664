import { showError, showSuccess, showWarning } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiSubmitIras } from '../api/ytd.api'
import {
  replaceA8aErrors,
  replaceA8aWarnings,
  replaceA8bErrors,
  replaceA8bWarnings,
  replaceIr8aErrors,
  replaceIr8aWarnings,
  replaceIr8sErrors,
  replaceIr8sWarnings
} from '../reducers'

export const submitIras = (
  ytdSubmissionId: string,
  signatureDate: string,
  validateOnly: boolean,
  bypass: boolean,
  skipApiCall: boolean
): ThunkResult<void> => {
  return async (dispatch): Promise<ActionResult | undefined> => {
    const { status, result, errors, message, errorData } = await apiSubmitIras(
      ytdSubmissionId,
      signatureDate,
      validateOnly,
      bypass,
      skipApiCall
    )

    if (status) {
      dispatch(replaceIr8aErrors({ ytdSubmissionId, data: result.ir8aErrors }))
      dispatch(replaceIr8aWarnings({ ytdSubmissionId, data: result.ir8aWarnings }))
      dispatch(replaceIr8sErrors({ ytdSubmissionId, data: result.ir8sErrors }))
      dispatch(replaceIr8sWarnings({ ytdSubmissionId, data: result.ir8sWarnings }))
      dispatch(replaceA8aErrors({ ytdSubmissionId, data: result.a8aErrors }))
      dispatch(replaceA8aWarnings({ ytdSubmissionId, data: result.a8aWarnings }))
      dispatch(replaceA8bErrors({ ytdSubmissionId, data: result.a8bErrors }))
      dispatch(replaceA8bWarnings({ ytdSubmissionId, data: result.a8bWarnings }))

      if (result.statusCode === '200') {
        let successMsg = 'Submission is successful!'
        if (validateOnly) successMsg = 'Validation is successful!'

        showSuccess(successMsg)
      } else {
        if (
          result.ir8aErrors.length > 0 ||
          result.ir8aWarnings.length > 0 ||
          result.ir8sErrors.length > 0 ||
          result.ir8sWarnings.length > 0 ||
          result.a8aErrors.length > 0 ||
          result.a8aWarnings.length > 0 ||
          result.a8bErrors.length > 0 ||
          result.a8bWarnings.length > 0
        ) {
          if (validateOnly) {
            showWarning('Validation failed. Please check the errors and/or warnings.')
          } else {
            showWarning('Submission failed. Please check the errors and/or warnings.')
          }
        } else {
          if (validateOnly) {
            showWarning(`Validation failed${result.errorMessage ? `: ${result.errorMessage}` : ''}`)
          } else {
            showWarning(`Submission failed${result.errorMessage ? `: ${result.errorMessage}` : ''}`)
          }
        }
      }
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}

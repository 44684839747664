import React, { FC, useState, useCallback, useEffect, memo, CSSProperties, ChangeEvent } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Button, Form, Input, Space, Tag, UploadFile } from '~/core-components'
import { Col, DrawerForm, EmFieldValues, Row, UploadFileSelectorAuth } from '~/components'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors } from '~/types/store'
import { useFocus } from '~/hooks/use-focus'
import { IEmCertRequest, SSEmCertPublicSelfState } from '~/features/my/types'
import { emptyGuid } from '~/constants'
import { applyEmCertRequest, deleteEmCertRequest } from '~/features/my/actions'
import { getBaseUrl } from '~/utils'
import { fetchEmCertField } from '~/features/employee'

export interface MutateMyCertDrawerProps {
  visible: boolean
  employeeId?: string
  data?: SSEmCertPublicSelfState
  onClose: (action: 'saved' | 'cancelled') => void
}

const PendingTag = memo(() => <Tag type="secondary">pending</Tag>)

const EMPTY_FORM_DATA: IEmCertRequest = {
  id: emptyGuid,
  employeeId: '',
  emCertId: emptyGuid,
  name: '',
  issuedBy: '',
  issueDate: '',
  expiryDate: '',
  credentialId: '',
  credentialUrl: ''
}

const currenTextStyle: CSSProperties = { fontSize: 13, color: '#797979' }
const baseUrl = getBaseUrl('/employee')

export const MutateMyCertDrawer: FC<MutateMyCertDrawerProps> = ({
  visible,
  employeeId,
  data,
  onClose
}: MutateMyCertDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IEmCertRequest>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()

  const isNew = data?.recordStatus === 'new_request'
  const isDiffName = !isNew && data?.name !== data?.nameNew
  const isDiffIssuedBy = !isNew && data?.issuedBy !== data?.issuedByNew
  const isDiffIssueDate = !isNew && data?.issueDate !== data?.issueDateNew
  const isDiffExpiryDate = !isNew && data?.expiryDate !== data?.expiryDateNew
  const isDiffCredentialUrl = !isNew && data?.credentialUrl !== data?.credentialUrlNew
  const isDiffCredentialId = !isNew && data?.credentialId !== data?.credentialIdNew

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      let attachment: UploadFile | undefined
      if (data.attachmentId) {
        attachment = {
          uid: data.attachmentId,
          name: data.attachmentName || '',
          size: data.attachmentSize || 0,
          type: data.attachmentFormat || '',
          url: `${baseUrl}/ssemployeerequest/cert/${data.requestId}/downloadfile`
        }
      }

      setFormData({
        id: data.requestId || emptyGuid,
        employeeId: employeeId || emptyGuid,
        emCertId: data.id,
        name: data.nameNew,
        issuedBy: data.issuedByNew,
        issueDate: data.issueDateNew,
        expiryDate: data.expiryDateNew,
        credentialId: data.credentialIdNew,
        credentialUrl: data.credentialUrlNew,
        attachment
      })
    } else {
      setFormData({ ...EMPTY_FORM_DATA, employeeId: employeeId || emptyGuid })
    }
  }, [data, employeeId])

  useEffect(() => {
    if (visible) {
      dispatch(fetchEmCertField('name'))
      dispatch(fetchEmCertField('issued_by'))
    }
  }, [visible])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      result = await dispatch(applyEmCertRequest(formData))
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose('saved')
      setFormData(EMPTY_FORM_DATA)
    }
  }, [formData, onClose])

  const handleDelete = useCallback(
    (myCert: SSEmCertPublicSelfState | undefined) => {
      if (employeeId && myCert) {
        const { requestId, nameNew } = myCert
        confirm({
          title: 'Delete license & certificate',
          content: `Do you want to delete license & certificate record of "${nameNew}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteEmCertRequest(requestId))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose('saved')
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [employeeId, onClose]
  )

  const handleFileChange = useCallback(
    (attachments?: UploadFile[]) => {
      if (attachments && attachments.length > 0) {
        setFormData({ ...formData, attachment: attachments[0] })
      }
    },
    [formData]
  )

  const handleFileRemove = useCallback(
    (file?: UploadFile) => {
      if (file) {
        setFormData({ ...formData, attachment: undefined, deletedAttachmentId: file.uid })
      }
    },
    [formData]
  )

  return (
    <DrawerForm
      open={visible}
      title={
        data ? (
          <Space>Edit license & certificate {isNew && <Tag type="secondary">new (pending)</Tag>}</Space>
        ) : (
          'Add license & certificate'
        )
      }
      okText={data?.recordStatus === 'has_request' ? 'Resubmit' : 'Submit'}
      onClose={() => onClose('cancelled')}
      confirmLoading={loading}
      width={600}
      showDelete={data ? (data?.recordStatus !== 'no_request' ? true : false) : false}
      onDelete={() => handleDelete(data)}
      formId="form-em-cert"
    >
      <Form id="form-em-cert" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item
              label={<Space>Name {isDiffName && <PendingTag />}</Space>}
              validateStatus={errors?.name ? 'error' : ''}
              help={errors?.name}
            >
              <EmFieldValues
                ref={focusRef}
                field="name"
                value={formData.name}
                onChange={(name?: string) => handleFormDataChange({ name })}
              />
              {isDiffName && <Space style={currenTextStyle}>Current: {data?.name || '-'}</Space>}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={<Space>Issuing organisation {isDiffIssuedBy && <PendingTag />}</Space>}
              validateStatus={errors?.issuedBy ? 'error' : ''}
              help={errors?.issuedBy}
            >
              <EmFieldValues
                field="issued_by"
                value={formData.issuedBy}
                onChange={(issuedBy?: string) => handleFormDataChange({ issuedBy })}
              />
              {isDiffIssuedBy && <Space style={currenTextStyle}>Current: {data?.issuedBy || '-'}</Space>}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={12}>
            <Form.Item
              label={<Space>Issue date {isDiffIssueDate && <PendingTag />}</Space>}
              validateStatus={errors?.issueDate ? 'error' : ''}
              help={errors?.issueDate}
            >
              <Input.Date
                value={formData.issueDate ? moment(formData.issueDate) : undefined}
                onChange={(value: moment.Moment | null) =>
                  handleFormDataChange({ issueDate: value?.format('YYYY-MM-DD') })
                }
              />
              {isDiffIssueDate && (
                <div style={currenTextStyle}>
                  Current: {data?.issueDate ? moment(data?.issueDate).format('DD MMM YYYY') : '-'}
                </div>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={<Space>Expiry date {isDiffExpiryDate && <PendingTag />}</Space>}
              validateStatus={errors?.expiryDate ? 'error' : ''}
              help={errors?.expiryDate}
            >
              <Input.Date
                value={formData.expiryDate ? moment(formData.expiryDate) : undefined}
                onChange={(value: moment.Moment | null) =>
                  handleFormDataChange({ expiryDate: value?.format('YYYY-MM-DD') })
                }
              />
              {isDiffExpiryDate && (
                <div style={currenTextStyle}>
                  Current: {data?.expiryDate ? moment(data?.expiryDate).format('DD MMM YYYY') : '-'}
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label={<Space>Credential ID {isDiffCredentialId && <PendingTag />}</Space>}>
              <Input
                value={formData.credentialId}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ credentialId: event.target.value })
                }
              />
              {isDiffCredentialId && <Space style={currenTextStyle}>Current: {data?.credentialId || '-'}</Space>}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label={<Space>Credential URL {isDiffCredentialUrl && <PendingTag />}</Space>}>
              <Input
                value={formData.credentialUrl}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ credentialUrl: event.target.value })
                }
              />
              {isDiffCredentialUrl && <Space style={currenTextStyle}>Current: {data?.credentialUrl || '-'}</Space>}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Attachment" validateStatus={errors?.attachment ? 'error' : ''} help={errors?.attachment}>
              <UploadFileSelectorAuth
                multiple={false}
                accept=".gif,.png,.jpeg,.jpg"
                fileList={formData.attachment ? [formData.attachment] : []}
                listType="picture-card"
                onChange={handleFileChange}
                onRemove={handleFileRemove}
                withCache={false}
              >
                <Button size="small">choose file</Button>
              </UploadFileSelectorAuth>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}

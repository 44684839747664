import React, { FC, useState, useCallback, useEffect, HTMLAttributes, CSSProperties } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import { Alert, Form, Input, Radio, RadioChangeEvent } from '~/core-components'
import { Col, DrawerForm, EmpKeyValues, NewTabLinkIcon, Row } from '~/components'
import { selectGrpKeyvalues } from '~/features/master'
import { selectEmployeeById } from '~/features/employee/reducers'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { useFocus } from '~/hooks/use-focus'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { addEmGrp, deleteEmGrp, updateEmGrp } from '../../../actions'
import { IEmGrpInfo, EmGrpState } from '../../../types'
import { mapEmGrpStateToEmGrpInfo } from '../../../types/em-details.mapper'
import { SETTINGS_ROUTES } from '~/routes/routes'

export interface MutateEmGrpDrawerProps {
  visible: boolean
  employeeId?: string
  data?: EmGrpState
  isCurrent: boolean
  onClose: () => void
}

const TODAY = moment().format('YYYY-MM-DD')
const EMPTY_FORM_DATA: IEmGrpInfo = {
  startDate: TODAY,
  grpId: ''
}

const radioStyle: CSSProperties = { width: '100%' }
const radioItemStyle: CSSProperties = { marginTop: 20 }
const formStyle: CSSProperties = { marginLeft: 24, marginTop: 10 }
const alertStyle: CSSProperties = { marginBottom: 15 }

export const MutateEmGrpDrawer: FC<MutateEmGrpDrawerProps> = ({
  visible,
  employeeId,
  data,
  isCurrent,
  onClose
}: MutateEmGrpDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<IEmGrpInfo>(EMPTY_FORM_DATA)
  const [newFormData, setNewFormData] = useState<IEmGrpInfo>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [isNew, setIsNew] = useState(isCurrent ? true : false)
  const grps = useSelector(selectGrpKeyvalues)
  const employee = useSelector((state: StoreState) => selectEmployeeById(state, employeeId || ''))

  useEffect(() => {
    setIsNew(isCurrent ? true : false)
    setErrors(undefined)
  }, [visible, isCurrent])

  useEffect(() => {
    if (visible && data) setFormData(mapEmGrpStateToEmGrpInfo(data))
  }, [visible, data])

  const handleRadioChange = useCallback((event: RadioChangeEvent) => {
    setIsNew(event.target.value)
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (isNew || data == null) {
        if (employeeId) {
          const submit = isNew ? newFormData : formData
          result = await dispatch(addEmGrp(employeeId, submit))
        }
      } else {
        if (data) {
          result = await dispatch(updateEmGrp(data.employeeId, data.id, mapEmGrpStateToEmGrpInfo(data), formData))
        }
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [isNew, employeeId, data, newFormData, formData, onClose])

  const handleDelete = useCallback(
    (emGrp: EmGrpState | undefined) => {
      if (employeeId && emGrp) {
        const { id, grpId } = emGrp
        const grp = grps[grpId]?.value
        confirm({
          title: 'Delete group',
          content: `Do you want to delete group record of "${grp}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteEmGrp(employeeId, id))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [employeeId, grps, onClose]
  )

  return (
    <DrawerForm
      open={visible}
      title="Group"
      onClose={onClose}
      confirmLoading={loading}
      width={500}
      showDelete={isNew ? false : true}
      onDelete={() => handleDelete(data)}
      formId="form-em-grp"
    >
      <Form id="form-em-grp" onFinish={handleOk}>
        {isCurrent ? (
          <Radio.Group value={isNew} onChange={handleRadioChange} style={radioStyle}>
            <Radio value={true}>Transfer to new group</Radio>
            <EmGrpForm
              errors={isNew ? errors : undefined}
              labelPrefix="New"
              style={formStyle}
              disabled={!isNew}
              onFormChange={data => setNewFormData({ ...newFormData, ...data })}
            />
            <Radio value={false} style={radioItemStyle}>
              Or make amendment to current group
            </Radio>
            <EmGrpForm
              data={data}
              errors={isNew ? undefined : errors}
              labelPrefix="Current"
              style={formStyle}
              disabled={isNew}
              onFormChange={data => setFormData({ ...formData, ...data })}
            />
          </Radio.Group>
        ) : (
          <>
            {data != null && (
              <Alert
                message="Any changes to this historical record will not impact past transactions."
                banner
                style={alertStyle}
              />
            )}
            <EmGrpForm
              data={data}
              hireDate={employee?.hireDate}
              errors={errors}
              onFormChange={data => setFormData({ ...formData, ...data })}
            />
          </>
        )}
      </Form>
    </DrawerForm>
  )
}

interface EmGrpFormProps extends HTMLAttributes<HTMLDivElement> {
  data?: EmGrpState
  hireDate?: string
  errors?: Errors
  labelPrefix?: string
  disabled?: boolean
  onFormChange: (data: IEmGrpInfo) => void
}

const EmGrpForm: FC<EmGrpFormProps> = ({
  data,
  hireDate,
  errors,
  labelPrefix,
  onFormChange,
  style,
  disabled = false
}) => {
  const canModify = usePermissionGate(Permission.master, PermissionAction.Modify)
  const [formData, setFormData] = useState<IEmGrpInfo>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const effectiveDateLabel = 'Effective date'
  const grpLabel = 'Group'

  useEffect(() => {
    setTimeout(() => !disabled && setFocus(), 100)
  }, [disabled, setFocus])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => {
      const updated = { ...formData, ...updates }
      setFormData(updated)
      typeof onFormChange === 'function' && onFormChange(updated)
    },
    [formData, onFormChange]
  )

  useEffect(() => {
    if (data) {
      const { startDate, grpId } = data
      setFormData({ startDate, grpId })
    } else {
      setFormData({ ...EMPTY_FORM_DATA, startDate: hireDate || TODAY })
    }
  }, [data, hireDate])

  const getLabel = useCallback(
    (prefix: string | undefined, label: string) => (prefix ? `${prefix} ${label.toLowerCase()}` : label),
    []
  )

  return (
    <div style={style}>
      <Row>
        <Col span={24}>
          <Form.Item
            label={getLabel(labelPrefix, effectiveDateLabel)}
            validateStatus={errors?.startDate ? 'error' : ''}
            help={errors?.startDate}
          >
            <Input.Date
              ref={focusRef}
              value={formData.startDate ? moment(formData.startDate) : undefined}
              onChange={(value: moment.Moment | null) =>
                handleFormDataChange({ startDate: value?.format('YYYY-MM-DD') })
              }
              disabled={disabled}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label={getLabel(labelPrefix, grpLabel)}
            validateStatus={errors?.grpId ? 'error' : ''}
            help={errors?.grpId}
          >
            <NewTabLinkIcon
              path={SETTINGS_ROUTES.grps}
              tooltipText="Open group in a new tab"
              tooltipPlacement="topRight"
              hidden={!canModify}
            >
              <EmpKeyValues
                id="grp"
                value={formData.grpId}
                onChange={(grpId?: string) => handleFormDataChange({ grpId })}
                disabled={disabled}
              />
            </NewTabLinkIcon>
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}

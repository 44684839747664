import React, { CSSProperties, FC, useCallback, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import { Card, ColumnsType, Link, Space, Table, Tag } from '~/core-components'
import { SSEmCertPublicSelfState } from '../../../../../types'
import { dispatch } from '~/stores/store'
import { fetchEmPublic } from '~/features/employee'
import { MutateMyCertDrawer } from './MutateMyCertDrawer'
import './MyCerts.less'

interface MyCertsProps {
  employeeId: string
  data: SSEmCertPublicSelfState[]
  hideSelfUpdate: boolean
}

interface DrawerState {
  visible: boolean
  employeeId?: string
  data?: SSEmCertPublicSelfState
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

const labelNameStyle: CSSProperties = { lineHeight: 1.1 }

export const MyCerts: FC<MyCertsProps> = ({ employeeId, data, hideSelfUpdate }) => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)

  const handleAddCert = useCallback(() => {
    setDrawerState({ visible: true, employeeId, data: undefined })
  }, [employeeId])

  const handleEditCert = useCallback(
    (myCert: SSEmCertPublicSelfState) => {
      setDrawerState({ visible: true, employeeId, data: myCert })
    },
    [employeeId]
  )

  const handleCloseDrawer = useCallback(
    (action: 'saved' | 'cancelled') => {
      setDrawerState(DEFAULT_DRAWER_STATE)
      if (action === 'saved') {
        dispatch(fetchEmPublic(employeeId, { strategy: 'always' }))
      }
      setDrawerState(DEFAULT_DRAWER_STATE)
    },
    [employeeId]
  )

  const columns = useMemo(() => {
    let columns: ColumnsType<SSEmCertPublicSelfState> = []

    columns.push(
      {
        title: 'Name',
        key: 'nameNew',
        dataIndex: 'nameNew',
        render: (value: string, record) => (
          <>
            <div style={labelNameStyle}>
              <Space>
                {value}
                {record.recordStatus === 'new_request' && <Tag type="secondary">new (pending)</Tag>}
                {record.recordStatus === 'has_request' && <Tag type="secondary">pending</Tag>}
              </Space>
            </div>
          </>
        )
      },
      {
        title: 'Issuing organisation',
        key: 'issuedByNew',
        dataIndex: 'issuedByNew',
        width: 150
      },
      {
        title: 'Issue date',
        key: 'issueDateNew',
        dataIndex: 'issueDateNew',
        render: (value: string) => value && moment(value).format('DD MMM YYYY')
      },
      {
        title: 'Expiry date',
        key: 'expiryDateNew',
        dataIndex: 'expiryDateNew',
        render: (value: string) => value && moment(value).format('DD MMM YYYY')
      },
      {
        key: 'action',
        align: 'right',
        render: (value: string, record) =>
          !hideSelfUpdate && (
            <Link size="small" onClick={() => handleEditCert(record)}>
              edit
            </Link>
          )
      }
    )

    return columns
  }, [hideSelfUpdate, handleEditCert])

  return (
    <Card
      title="Licenses & certificates"
      className="my-certs"
      extra={employeeId && !hideSelfUpdate && <Link onClick={handleAddCert}>add</Link>}
    >
      <Table rowKey="id" dataSource={data} pagination={false} columns={columns} />
      {!hideSelfUpdate && <MutateMyCertDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </Card>
  )
}

import { request, getBaseUrl } from '~/utils/request'
import { EmCertRequestState } from '../types'

const baseUrl = getBaseUrl('/employee/employee/request/cert')

export const apiGetEmCertRequests = (employeeId: string) =>
  request<EmCertRequestState[]>('get', `${baseUrl}/${employeeId}`)

export const apiApproveEmCertRequest = (id: string) => request('post', `${baseUrl}/${id}/approve`)

export const apiRejectEmCertRequest = (id: string) => request('post', `${baseUrl}/${id}/reject`)

import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Col, DrawerForm, Row } from '~/components'
import { Button, Form, Input, Switch } from '~/core-components'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { downloadWithDom, showError } from '~/utils'
import { getFileTimestamp } from '~/utils/dateUtil'
import { useFocus } from '~/hooks/use-focus'
import { submitIras } from '../../actions/submit-iras'
import { apiDownloadIrasFiles, apiGetCorpPassAuthUrl, apiGetIrasSubmissionReport } from '../../api/ytd.api'
import { SubmitIrasResponse, YtdSubmissionState } from '../../types'
import { setIrasSubmissionId } from '../../reducers'
import { fetchYtdSubmission, fetchYtdSummary } from '../../actions'
import { IrasSubmitType } from './IrasActions'
import { useMyLogin } from '~/features/iam'

interface IrasSubmissionDrawerProps {
  ytdSubmission?: YtdSubmissionState
  type: IrasSubmitType
  visible: boolean
  onClose: () => void
}

interface FormData {
  signatureDate: string
  bypass: boolean
}

const EMPTY_FORM_DATA: FormData = {
  signatureDate: moment().format('YYYY-MM-DD'),
  bypass: false
}

const map = {
  file: {
    title: 'Download submission file',
    buttonText: 'Download'
  },
  validate: {
    title: 'IRAS validation',
    buttonText: 'Validate with IRAS'
  },
  submission: {
    title: 'IRAS submission',
    buttonText: 'Submit to IRAS'
  }
}

export const IrasSubmissionDrawer: FC<IrasSubmissionDrawerProps> = ({ ytdSubmission, type, visible, onClose }) => {
  const ytdSubmissionId = ytdSubmission?.id || ''
  const [myLogin] = useMyLogin()
  const [focusRef, setFocus] = useFocus(true)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Errors>()

  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [redirecting, setRedirecting] = useState(false)
  const irasState = useSelector((state: StoreState) =>
    (new Date().getTime() - state.tax.irasStateTimestamp) / 60000 >= 30 ? null : state.tax.irasState
  )

  const ir8aErrors = useSelector(
    (state: StoreState) => Object.values(state.tax.ir8aErrors[ytdSubmissionId]?.entities || {}) || []
  )
  const ir8aWarnings = useSelector(
    (state: StoreState) => Object.values(state.tax.ir8aWarnings[ytdSubmissionId]?.entities || {}) || []
  )
  const ir8sErrors = useSelector(
    (state: StoreState) => Object.values(state.tax.ir8sErrors[ytdSubmissionId]?.entities || {}) || []
  )
  const ir8sWarnings = useSelector(
    (state: StoreState) => Object.values(state.tax.ir8sWarnings[ytdSubmissionId]?.entities || {}) || []
  )
  const a8aErrors = useSelector(
    (state: StoreState) => Object.values(state.tax.a8aErrors[ytdSubmissionId]?.entities || {}) || []
  )
  const a8aWarnings = useSelector(
    (state: StoreState) => Object.values(state.tax.a8aWarnings[ytdSubmissionId]?.entities || {}) || []
  )
  const a8bErrors = useSelector(
    (state: StoreState) => Object.values(state.tax.a8bErrors[ytdSubmissionId]?.entities || {}) || []
  )
  const a8bWarnings = useSelector(
    (state: StoreState) => Object.values(state.tax.a8bWarnings[ytdSubmissionId]?.entities || {}) || []
  )

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  const handleLogin = useCallback(async () => {
    if (!ytdSubmissionId) return

    try {
      setRedirecting(true)
      dispatch(setIrasSubmissionId(ytdSubmissionId))

      const url = await apiGetCorpPassAuthUrl()
      if (url) window.location.href = url.result
    } finally {
      setRedirecting(false)
    }
  }, [ytdSubmissionId])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleDownloadFile = useCallback(async () => {
    let result: ActionResult<SubmitIrasResponse> | undefined
    try {
      setLoading(true)

      const { signatureDate } = formData
      const { status, result, errors, message, errorData } = await apiDownloadIrasFiles(ytdSubmissionId, signatureDate)
      if (status) {
        const fileName = `iras_files_${getFileTimestamp()}.zip`
        downloadWithDom(result, fileName)
      } else {
        console.error('Error while downloading', errors)
        showError(message, errorData)
      }
    } finally {
      setLoading(false)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
    }
  }, [ytdSubmissionId, formData, onClose])

  const handleOk = useCallback(async () => {
    if (!ytdSubmissionId) return

    if (type === 'file') {
      handleDownloadFile()
      return
    }

    let result: ActionResult<SubmitIrasResponse> | undefined
    try {
      setLoading(true)

      const { signatureDate, bypass } = formData
      result = await dispatch(submitIras(ytdSubmissionId, signatureDate, type === 'validate', bypass, false))
      const irasLogId = result?.result?.irasLogId
      if (irasLogId) {
        const { status, result, errors, message, errorData } = await apiGetIrasSubmissionReport(irasLogId)
        if (status) {
          const fileName = `iras_submission_summary_${getFileTimestamp()}.pdf`
          downloadWithDom(result, fileName)
        } else {
          console.error('Error while downloading', errors)
          showError(message, errorData)
        }
      }
    } finally {
      setLoading(false)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      dispatch(fetchYtdSubmission(ytdSubmissionId))
      dispatch(fetchYtdSummary(ytdSubmissionId))
    }
  }, [handleDownloadFile, ytdSubmissionId, formData, type, onClose])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose()
  }, [onClose])

  const handleSkipAPiCall = useCallback(async () => {
    if (!ytdSubmissionId) return

    let result: ActionResult<SubmitIrasResponse> | undefined
    try {
      setLoading(true)

      const { signatureDate, bypass } = formData
      result = await dispatch(submitIras(ytdSubmissionId, signatureDate, false, bypass, true))
    } finally {
      setLoading(false)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      dispatch(fetchYtdSubmission(ytdSubmissionId))
      dispatch(fetchYtdSummary(ytdSubmissionId))
    }
  }, [ytdSubmissionId, formData, onClose])

  return (
    <DrawerForm
      open={visible}
      title={map[type].title}
      okText={map[type].buttonText}
      onClose={handleCloseDrawer}
      confirmLoading={loading}
      width={500}
      className="iras-submission-drawer"
      showDelete={type === 'submission' && myLogin?.email?.endsWith('@zealys.com') ? true : false}
      deleteText="Skip the IRAS submission"
      onDelete={() => handleSkipAPiCall()}
      deleteLoading={loading}
      formId="form-iras-submission"
    >
      <Form id="form-iras-submission" onFinish={handleOk}>
        {type === 'submission' && (
          <>
            <Row hidden={!!irasState}>
              <Col span={24}>
                <Form.Item label="You've not login to CorpPass, login is required">
                  <Button onClick={handleLogin} loading={redirecting}>
                    Login to CorpPass
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            <Row hidden={!irasState}>
              <Col span={24} style={{ marginBottom: 7 }}>
                You have successfully login into CorpPass.
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col span={12}>
            <Form.Item
              label="Signature date"
              validateStatus={errors?.signatureDate ? 'error' : ''}
              help={errors?.signatureDate}
            >
              <Input.Date
                ref={focusRef}
                value={formData.signatureDate ? moment(formData.signatureDate) : undefined}
                onChange={(value: moment.Moment | null) =>
                  handleFormDataChange({ signatureDate: value?.format('YYYY-MM-DD') })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            hidden={
              ir8aErrors.length > 0 ||
              ir8sErrors.length > 0 ||
              a8aErrors.length > 0 ||
              a8bErrors.length > 0 ||
              (ir8aWarnings.length === 0 &&
                ir8sWarnings.length === 0 &&
                a8aWarnings.length === 0 &&
                a8bWarnings.length === 0)
            }
          >
            <Form.Item>
              <label>Bypass warnings</label>
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                checked={formData.bypass}
                onChange={(checked: boolean) => {
                  handleFormDataChange({ bypass: checked })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}

import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { Col, DocumentTitle, EmpKeyValues, Row, SysOptions, YearKeyValues } from '~/components'
import { Button, Collapse, Form, Input, PageHeader, Select, Space } from '~/core-components'
import { GroupingButton, RptScreen } from '~/features/grouping'
import { emptyGuid, RptEmStatus } from '~/constants'
import { REPORTS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { Errors } from '~/types/store'
import { downloadWithDom, getFileTimestamp, showError } from '~/utils'
import { IRptLveEntitlement, ReportCode } from '../../types'
import { useFirstReportTemplate, useReportTemplate } from '../../hooks'
import { refetchReportTemplates } from '../../reducers'
import { useLeaveTypesDict } from '~/features/leave'
import { apiDownloadLveEntitlementExcel } from '../../api/rpt-lve-entitlement.api'
import { RptDownloadBtn } from '../components/RptDownloadBtn'
import { ReportTemplate } from '../ReportTemplate/ReportTemplate'
import { RptSortingBtn } from '../components/RptSortingBtn'
import { Screen, useGetViewIdByName, ViewCriteriaButton } from '~/features/selection'
import './RptLveEntitlement.less'

const routes = [
  {
    path: REPORTS_ROUTES.main,
    breadcrumbName: 'Reports'
  },
  {
    path: '',
    breadcrumbName: 'Leave entitlement report'
  }
]

interface RptLveEntitlementForm extends IRptLveEntitlement {
  templateId: string
}

const currentYear = moment().year()

const SCREEN_CODE: Screen = 'rpt_lve_entitlement'
const RPT_SCREEN_CODE: RptScreen = 'rpt_leave'
const REPORT_CODE: ReportCode = 'rpt_lve_entitlement'

const EMPTY_FORM_DATA: RptLveEntitlementForm = {
  periodYear: currentYear,
  leaveTypeIds: [],
  leaveGroupIds: [],
  sorting: 'employee_name',
  groupingCodes: [],
  pageBreaks: [],
  emStatus: RptEmStatus.all,
  viewId: '',
  reportTitle: '',
  notes: '',
  templateId: emptyGuid
}

export const RptLveEntitlement: FC = () => {
  const [formData, setFormData] = useState<RptLveEntitlementForm>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [activeKey, setActiveKey] = useState(0)
  const [leaveTypes] = useLeaveTypesDict()

  const [firstTemplate] = useFirstReportTemplate('leave', REPORT_CODE)
  const firstTemplateId = firstTemplate?.id || ''
  const [templateId, setTemplateId] = useState<string>()
  const [template] = useReportTemplate('leave', REPORT_CODE, templateId)

  const [viewId] = useGetViewIdByName(SCREEN_CODE, templateId ? `${SCREEN_CODE}_${templateId}` : undefined)

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  useEffect(() => {
    if (!templateId && firstTemplateId) {
      setTemplateId(firstTemplateId)
    }

    if (template) {
      const saved = JSON.parse(template.dataJsonb) as RptLveEntitlementForm
      saved.templateId = templateId || ''
      saved.emStatus = saved.emStatus || RptEmStatus.all
      saved.viewId = viewId || ''
      setFormData(formData => ({ ...formData, ...saved }))
    }
  }, [firstTemplateId, templateId, template, viewId])

  const handleDownloadExcel = useCallback(async () => {
    const { status, result, errors, message, errorData } = await apiDownloadLveEntitlementExcel(formData)

    if (status) {
      const fileName = `leave_entitlement_report_${formData.periodYear}_${getFileTimestamp()}.xlsx`
      downloadWithDom(result, fileName)
      dispatch(refetchReportTemplates())
    } else {
      console.error('Error while downloading', errors)
      setErrors(errors)
      showError(message, errorData)
    }
  }, [formData])

  const handleCollapse = useCallback(async () => {
    if (!activeKey) {
      setActiveKey(1)
    } else {
      setActiveKey(0)
    }
  }, [activeKey])

  const handleTemplateChange = useCallback((templateId?: string) => {
    setTemplateId(templateId)
  }, [])

  return (
    <div id="rpt-lve-entitlement" className="rpt-lve-entitlement">
      <DocumentTitle title="Leave Entitlement Report" />
      <PageHeader
        title="Leave entitlement report"
        containerId="rpt-lve-entitlement"
        breadcrumb={{ routes }}
        extra={
          <ReportTemplate<IRptLveEntitlement>
            basePath="leave"
            reportCode={REPORT_CODE}
            templateId={templateId}
            templateData={formData}
            size="small"
            onChange={handleTemplateChange}
          />
        }
      />
      <Form className="rpt-lve-entitlement__form" layout="horizontal" labelCol={{ flex: '130px' }}>
        <Row gutter={30}>
          <Col flex="250px">
            <Form.Item label="Period" validateStatus={errors?.periodYear ? 'error' : ''} help={errors?.periodYear}>
              <YearKeyValues
                value={formData.periodYear}
                startYear={currentYear}
                noOfYearBefore={-5}
                noOfYearAfter={1}
                allowClear={false}
                onChange={(value: string) => {
                  handleFormDataChange({ periodYear: value })
                }}
              />
            </Form.Item>
          </Col>
          <Col flex="1"></Col>
          <Col flex="none">
            <Space>
              <RptSortingBtn
                sorting={formData.sorting}
                onSelect={(sorting: string) => handleFormDataChange({ sorting })}
              />
              <ViewCriteriaButton screenCode={SCREEN_CODE} viewId={formData.viewId} />
              <GroupingButton
                screenCode={RPT_SCREEN_CODE}
                groupingCodes={formData.groupingCodes || []}
                pageBreaks={formData.pageBreaks || []}
                onSelect={(groupingCodes: string[], pageBreaks: string[]) =>
                  handleFormDataChange({ groupingCodes, pageBreaks })
                }
              />
              <Button
                icon={<i className="fal fa-gear" />}
                type={activeKey ? 'primary' : 'default'}
                onClick={handleCollapse}
              />
              <RptDownloadBtn onDownloadExcel={handleDownloadExcel} />
            </Space>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col flex="1">
            <Form.Item
              label="Leave types"
              validateStatus={errors?.leaveTypeIds ? 'error' : ''}
              help={errors?.leaveTypeIds}
            >
              <Select
                mode="multiple"
                maxTagCount={3}
                value={formData.leaveTypeIds || []}
                placeholder="All leave types"
                optionFilterProp="title"
                onChange={(value: string[]) => handleFormDataChange({ leaveTypeIds: value })}
              >
                {Object.values(leaveTypes)
                  .sort((a, b) => a!.name.localeCompare(b!.name || '') || 0)
                  .map(lt => (
                    <Select.Option key={lt?.id} value={lt?.id || ''} title={lt?.name}>
                      {lt?.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col flex="1">
            <Form.Item
              label="Leave group"
              validateStatus={errors?.leaveGroupIds ? 'error' : ''}
              help={errors?.leaveGroupIds}
            >
              <EmpKeyValues
                id="leaveGroup"
                mode="multiple"
                maxTagCount={3}
                value={formData.leaveGroupIds || []}
                placeholder="All leave groups"
                onChange={(leaveGroupIds?: string) => handleFormDataChange({ leaveGroupIds })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="1">
            <Collapse
              className="rpt-lve-entitlement__more-settings"
              activeKey={activeKey}
              onChange={handleCollapse}
              noStyle
            >
              <Collapse.Panel key="1" header={null} showArrow={false}>
                <Row gutter={30}>
                  <Col flex="490px">
                    <Form.Item
                      label="Filter by employee"
                      validateStatus={errors?.emStatus ? 'error' : ''}
                      help={errors?.emStatus}
                    >
                      <SysOptions
                        allowClear={false}
                        type="rpt_em_status"
                        placeholder="All employees"
                        value={formData.emStatus}
                        onChange={(emStatus?: string) => handleFormDataChange({ emStatus })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="490px">
                    <Form.Item label="Report title">
                      <Input
                        value={formData.reportTitle}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          handleFormDataChange({ reportTitle: event.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={30}>
                  <Col flex="1">
                    <Form.Item label="Custom notes">
                      <Input.TextArea
                        rows={3}
                        value={formData.notes}
                        onChange={(value?: ChangeEvent<HTMLTextAreaElement>) =>
                          handleFormDataChange({ notes: value?.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import { GroupChildren } from '~/types/common'
import { StoreState } from '~/types/store'
import { SysSelectionFieldState, Screen } from '../types'

export const selectSysSelectionFieldsWGroup = createSelector(
  (state: StoreState) => state.selection.sysSelectionFields,
  (state: StoreState) => state.selection.sysSelectionScreens,
  (state: StoreState) => state.master.keyvalues['sysselection'],
  (selectionFieldsState, sysSelectionScreensState, sysSelectionState) =>
    memoize((screenCode: Screen): GroupChildren<SysSelectionFieldState>[] => {
      const screenEntities = Object.values(sysSelectionScreensState[screenCode]?.entities || {}).sort((a, b) =>
        a!.sequence > b!.sequence ? 1 : -1
      )

      const selectionCodes = screenEntities.map(s => s?.selectionCode)

      const map = new Map<string, GroupChildren<SysSelectionFieldState>>()
      const selectionFields = Object.values(selectionFieldsState[screenCode]?.entities || {})
        .filter(f => selectionCodes.includes(f?.selectionCode))
        .sort((a, b) => (b!.sequence > a!.sequence ? -1 : 1))

      selectionFields.forEach(item => {
        if (item) {
          const { id, fieldName, description, width, format, sequence, selectionCode } = item
          map.has(selectionCode) ||
            map.set(selectionCode, {
              id: selectionCode,
              name: sysSelectionState.entities[selectionCode]?.value || '',
              children: []
            })
          map.get(selectionCode)?.children.push({
            id,
            fieldName,
            description,
            width,
            format,
            sequence,
            selectionCode
          })
        }
      })

      const result = selectionCodes
        .map(code => map.get(code!))
        .filter(Boolean)
        .sort((a, b) => {
          const seqA = screenEntities.find(s => s!.selectionCode === a?.id)?.sequence || 0
          const seqB = screenEntities.find(s => s!.selectionCode === b?.id)?.sequence || 0
          return seqA - seqB
        })

      return result as GroupChildren<SysSelectionFieldState>[]
    })
)

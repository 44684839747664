import React, { FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { ColumnsType, Drawer, Link, Table, Tag, Tooltip } from '~/core-components'
import { EmIdentityRequestState } from '../../../../types'
import { useIsMountedRef } from '~/hooks'
import { useSysOptions } from '~/features/employee/hooks'
import { maskString } from '~/features/employee/util'
import { EmIdentityRequestApprovalDrawer } from './EmIdentityRequestApprovalDrawer'
import { selectEmIdentityRequests } from '~/features/employee/selectors'

export interface EmIdentityRequestsProps {
  employeeId?: string
  visible: boolean
  onClose: () => void
}

interface ApprovalDrawerState {
  data?: EmIdentityRequestState
  visible: boolean
}

const DEFAULT_APPROVAL_DRAWER_STATE: ApprovalDrawerState = { visible: false }

export const EmIdentityRequestsDrawer: FC<EmIdentityRequestsProps> = ({ employeeId, visible, onClose }) => {
  const data = useSelector(selectEmIdentityRequests)(employeeId)
  const [identityTypes] = useSysOptions('identity_type')
  const [approvalDrawerState, setApprovalDrawerState] = useState<ApprovalDrawerState>(DEFAULT_APPROVAL_DRAWER_STATE)
  const isMountedRef = useIsMountedRef()

  const handleView = useCallback(async (request: EmIdentityRequestState) => {
    setApprovalDrawerState({ data: request, visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose()
  }, [onClose])

  const handleCloseApprovalDrawer = useCallback(() => {
    if (isMountedRef.current) setApprovalDrawerState(DEFAULT_APPROVAL_DRAWER_STATE)
  }, [isMountedRef])

  const columns: ColumnsType<EmIdentityRequestState> = [
    {
      title: 'Type',
      key: 'identityTypeNew',
      dataIndex: 'identityTypeNew',
      render: (value: string, record) => (
        <>
          {identityTypes[value]?.value}{' '}
          {record.recordStatus === 'new_request' && <Tag type="secondary">new (pending)</Tag>}
          {record.recordStatus === 'has_request' && <Tag type="secondary">pending</Tag>}
        </>
      )
    },
    {
      title: 'Identity no.',
      key: 'identityNoNew',
      dataIndex: 'identityNoNew',
      render: (value: string) => <Tooltip title={value}>{maskString(value)}</Tooltip>
    },
    {
      title: 'Issue date',
      key: 'issueDateNew',
      dataIndex: 'issueDateNew',
      width: 100,
      render: (value: string) => value && moment(value).format('DD MMM YYYY')
    },
    {
      title: 'Cancelled / expiry date',
      key: 'expiryDateNew',
      dataIndex: 'expiryDateNew',
      width: 100,
      render: (value: string, record) => {
        const valueDate = record.cancelledDateNew ? record.cancelledDateNew : value
        return valueDate && moment(valueDate).format('DD MMM YYYY')
      }
    },
    {
      key: 'action',
      align: 'right',
      render: (value: string, record) => (
        <Link size="small" onClick={() => handleView(record)} noWrap>
          view
        </Link>
      )
    }
  ]

  return (
    <>
      <Drawer
        open={visible}
        title="Identity request"
        onClose={() => handleCloseDrawer()}
        width={720}
        className="em-identity-requests-drawer"
      >
        <Table rowKey="id" dataSource={data} columns={columns} pagination={false}></Table>
      </Drawer>
      <EmIdentityRequestApprovalDrawer {...approvalDrawerState} onClose={handleCloseApprovalDrawer} />
    </>
  )
}
